.article-info {
  font-family: $font__sans-serif;

  &__read-title {
    @extend .rte__call-out;
  }

  &__disclaimer {
    font-size: 10px;
    line-height: 11px;
  }
}