.page-nav {
  display: none;
  position: absolute;
  left: 20px;
  top: 0;
  max-width: 250px;
  padding: 20px 25px;
  font-family: $font__sans-serif;

  .social-icons__block + &:not(&--nav-locked) {
    top: 48px;
  }

  &__scroll-link {
    margin-bottom: 22px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__link {
    display: block;
    color: $color__black;
    line-height: 26px;
    text-align: left;

    &--active {
      font-family: $font__sans-serif--semibold;
      margin-right: -5px;
      color: $color__blue--dark;

      &:before {
        content: "";
        position: absolute;
        display: block;
        height: 35px;
        width: 3px;
        left: 13px;
        background-color: $color__blue--dark;
      }
    }

    &:hover {
      text-decoration: none;
      font-family: $font__sans-serif--semibold;
      margin-right: -5px;
    }
  }

  &__bio-section {
    display: none;
  }
  
  @include respond-to('between') {
    display: block;
    transition: box-shadow 0.6s ease-in-out;

    &__bio-section {
      display: block;
      visibility: hidden;
      max-height: 0;
      border-bottom: 1px solid $color__gray02;
      margin-bottom: 15px;
      text-align: center;
      transition: max-height 0.6s ease-in-out,
                  visibility 0.6s ease-in-out;
      overflow: hidden;
    }

    &__bio-image {
      margin-bottom: 15px;
    }

    &__bio-name {
      font-size: $font__size--h5;
      font-family: $font__sans-serif--bold;
      color: $color__blue--darker;
      margin-bottom: 15px;
    }

    &__bio-phone {
      color: $color__blue--dark;
      font-family: $font__sans-serif--semibold;
      margin-bottom: 15px;
    }

    &--nav-locked {
      position: fixed;
      background-color: $color__white;
      top: 110px;
      left: 20px;
    }

    &--drawer-open {
      box-shadow: 1px 1px 24px 3px rgba(0,0,0,0.06);

      .page-nav__bio-section {
        max-height: 315px;
        visibility: visible;
      }
    }

    &--bottom-locked {
      position: absolute;
      top: initial;
      bottom: 30px;
      left: 20px;
    }

    @media (max-height: 780px) {
      &__bio-image {
        max-height: 160px;
      }

      &--drawer-open { 
        .page-nav__bio-section {
          max-height: 250px;
        }
      }
    }

    @media (max-height: 680px) {
      &--drawer-open { 
        .page-nav__bio-section {
          max-height: 0;
          visibility: hidden;
        }
      }
    }
  }

  @include respond-to('container') {
    &--nav-locked {
      left: calc( ( (100% - 1336px) / 2 ) + 20px );

      .container--small & { 
        left: calc( ( (100% - 1184px) / 2 ) + 20px );
      }
    }

    &--bottom-locked {
      left: 20px;
    }
  }
}