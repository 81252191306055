.navbar {
  width: 100%;
  height: 60px;
  z-index: 100;
  font-family: $font__sans-serif;
  position: relative;
  background-color: $color__white;

  &--nav-locked {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 0px 0px 8px 2px rgba( 0, 0, 0, 0.25 );
  }

  &__filler {
    height: 60px;
    width: 60px;
    display: none;

    &--show {
      display: block;
    }
  }

  &__wrapper {
    width: 100%;
  }

  &__logo {
    display: none;
  }

  &__logo-mobile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-40%);
    width: 140px;
  }

  &__primary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0;
  }

  &__links {
    display: none;
    position: fixed;
    overflow-y: auto;
    top: 50px;
    left: 0;
    z-index: 100;
    width: 100%;
    height: calc(100% - 50px);
    background-color: $color__white;
    padding: 15px 0;
    box-shadow: 0 10px 12px 3px rgba(0,0,0,0.06);

    &--visible {
      display: block;
    }
  }

  &__menu {
    visibility: hidden;
    opacity: 0;
    background-color: $color__white;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &--visible {
      opacity: 1;
      visibility: visible;
    }
  }

  @mixin green-line {
    &:after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background-color: $color__green;
      margin-top: -4px;
      transition: width 0.2s ease-in-out;
    }
  }

  @mixin green-line--active {
    &:after {
      width: 100%;
    }
  }


  //wrapper div for link item
  &__item {
    font-family: $font__sans-serif--semibold;
    color: $color__text;
    margin-left: 25px;
    margin-bottom: 10px;
    text-align: left;

    &--primary {
      margin-right: 25px;
      line-height: 32px;
    }

    &--secondary {

    }
  }   

  //actual 'a' tag
  &__link {
    @include green-line;
    color: $color__text;
    text-decoration: none !important;
    position: relative;
    z-index: 1;
    display: inline-block; //for green line sizing

    &--primary {

    }

    &--secondary {
      letter-spacing: 0.54px;
      font-size: $font__size--small;
    }

    &--active, &:hover {
      @include green-line--active;
    }
  }

  &__secondary-links {
      &::before {
        content: "";
        margin: 15px 25px;
        display: block;
        width: calc(100% - 50px);
        border-top: 1px solid $color__gray02;
      }
  }

  &__dropdown-arrow {
    position: absolute;
    top: 10px;
    right: 10px;
    line-height: 0; //spacing is weird, but was not weird on template site
    overflow: visible;

    &::before {
      @include icon;
      content: $icon__nav-arrow;  
      display: inline-block;
      transform: rotate(90deg);
      font-size: 10px;
    }
  }

  &__dropdown {
    display: none;
  }

  &__hamburger {
    font-size: 12px;
    text-transform: uppercase;
    color: $color__blue--dark;
    font-family: $font__sans-serif;
    padding-left: 20px;
    position: relative;

    &:after,
    &:before {
      content: "";
      display: block;
      width: 16px;
      height: 2px;
      background-color: $color__green;
      position: absolute;
      left: 0;
    }
    
    &:before {
      top: 4px;
    }

    &:after {
      top: 10px;
    }

    &--hidden {
      display: none;
    }
  }

  &__close {
    display: none;
    background: url('#{$img-path}/menu-close.svg') no-repeat center center;
    height: 26px;
    width: 26px;
    margin-top: -6px;
    margin-right: 8px;

    &--visible {
      display: block;
    }
  }
  
  &__mobile-search {
    position: absolute;
    font-size: 18px;
    color: $color__blue--dark;
    left: 20px;
  }

  &__search {
    display: none;
    color: $color__text;
    margin-left: 40px;
  }

  &__close-search {
    display: none;
    background: url('#{$img-path}/close-button--white.svg') no-repeat center 15px $color__blue--dark;
    background-size: 18px;
    height: 72px;
    width: 52px;
    position: absolute;
    right: 0;
    z-index: 200; //IE

    &--visible {
      display: block;
    }
  }

  &__mobile-close {
    position: absolute;
    font-size: 18px;
    left: 0;
    top: 0;
    background-position: center 20px;
  }

  &__search-wrapper {
    min-height: 267px;
    padding: 50px 0 40px;
    background-color: $color__blue--dark;
    visibility: hidden;
    
    &--visible {
      visibility: visible;
    }
  }

  &__search-filters {
    color: $color__white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__search-filter-wrapper {
    width: 50%;
    position: relative;
    display: inline-block;
    padding-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }

  &__search-title {
    color: $color__white;
    font-size: $font__size--small;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }

  &__search-filter-button {
    display: block;
    position: absolute;
    top: 3px;
    left: 0;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    border: 2px solid $color__white;
  }

  &__search-filter-option {   
    &:checked {
      & + .navbar__search-filter-button:after {
        content: "";
        display: block;
        position: absolute;
        top: 2px;
        left: 2px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: $color__white;
      }
    }
  }

  &__search-filter-label {
    vertical-align: text-top;
  }

  &__search-input-wrapper{
    position: relative;
    margin-top: 20px;
  }

  &__search-input {
    background-color: $color__white;
    width: 100%;
    box-sizing: border-box;
    padding-right: 30px;
  }

  &__search-input-icon {
    // -webkit-appearance: none;
    background: transparent;
    border: none;
    position: absolute;
    right: 10px;
    top: 12px;

    &::before {
      @include icon;
      content: $icon__search;
      color: $color__gray04;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__search-filter-option {

  }

  &__mobile-hidden {
    display: none;
  }

  &__home-h1 {
    margin: 0;
    padding: 0;
    font-size: inherit;
    line-height:  1; 
  }

  @include respond-to('small') {
    &__logo-mobile {
      width: 200px;
    }
  }

  @include respond-to('between') {
    height: 100px;

    &__mobile-hidden {
      display: block;
    }

    &__mobile-only {
      display: none;
    }

    &__filler {
      height: 92px;
    }

    &__logo {
      display: block;
    }

    &__logo-link {
      display: block;
      width: 250px;
    }

    &__logo-mobile {
      display: none;
    }

    &__search {
      display: block;
    }

    &__close-search {
      height: 52px;
    }

    &__links {
      display: block;
      position: static;
      padding: 0;
      box-shadow: none;
      overflow-y: visible; //reset scrolling menu
      width: auto;
    }

    &__primary {
      padding: 42px 0 6px;
    }

    &__primary-links {
      display: flex;
      flex-direction: row;
    }

    &__item {

      &--primary {
        flex-shrink: 0;
        margin-left: 25px;
        margin-right: 0;
        margin-bottom: 0;
      }

      &--secondary {
        font-size: 13px;
        line-height: 15px;
        margin-bottom: 0;
      }

      &--has-dropdown {
        position: relative;
        cursor: pointer;

        //help out the hover state with the arrow, in between you could not hover
        &:before {
          display: block;
          content: " ";
          position: absolute;
          left: 100%;
          width: 1em;
          height: 120%;
          top: 0;
        }
      }

      &--active {
        @include green-line;
        @include green-line--active;
      }

      &--dropdown-item {
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 8px;

        &:last-of-type {
          margin-bottom: 5px;
        }
      }

      &--dropdown-open, &--has-dropdown:hover {
        .navbar__dropdown {
          display: block;
        }
      }

      &--dropdown-open {
        .navbar__dropdown-arrow {
          transform: rotate(180deg);
        }
      }
    }

    &__link {
      display: block;
      &--dropdown-item {
        display: inline-block;
      }

      &--secondary {
        font-size: 13px;
      }
    }


    &__secondary-links {
      display: flex;
      flex-direction: row;
      position: absolute;
      right: 20px;
      top: 8px;

      &:before {
        display: none;
      }
    }

    &__dropdown {
      display: none;
      padding-top: 20px;
      position: absolute;
      cursor: auto;
      right: -40px;
      top: 100%;
    }
  
    &__dropdown-inner {
      min-width: 260px;
      background-color: $color__white;
      padding: 15px;
      box-shadow: 0 2px 4px 2px rgba(189,189,189,0.5);
      z-index: 1000;
      position: relative;
      
      &:before {
        content: "";
        height: 0;
        width: 0;
        border-bottom: 14px solid rgba(189,189,189,0.1);
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        position: absolute;
        bottom: 100%;
        right: 44px;
      }
  
      &:after {
        content: "";
        height: 0;
        width: 0;
        border-bottom: 10px solid $color__white;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        position: absolute;
        bottom: 100%;
        right: 48px;
        margin-top: 2px;
      }
    }



    &__icons {
      display: none;
    }

    &__dropdown-arrow {
      right: -14px;
      transition: transform .1s ease;
      transition-delay: .1s;
    }

    &__search-filter-wrapper {
      width: auto;
      padding: 0;
      margin-right: 30px;
      // margin-bottom: 0;
    }

    &__search-input-wrapper {
      margin-top: 25px;
    }

    &__search-input {
    }
  }

  @include respond-to('large') {
    &__item--primary {
      margin-left: 40px;
    }
  }

  @include respond-to('container') {
    &__item--primary {
      margin-left: 50px;
    }
  }

  @media print {
    display: none;
  }
  
}