.blog-cards {
  &__feature-card {
    display: block;
    background-color: $color__white;
    padding: 20px;
    box-shadow: 1px 1px 24px 3px rgba(0,0,0,0.06);
    position: relative;
    @include hover-tab;
  }

  &__feature-card-inner {
    padding-bottom: 25px;
  }

  &__image {
    margin-bottom: 15px;
  }

  &__title {
    font-size: $font__size--tiny;
    text-transform: uppercase;
    color: $color__green--dark;
    letter-spacing: 1.47px;
    margin-bottom: 8px;
  }

  &__cards {
    padding-top: 20px;
  }

  &__card {
    background-color: $color__gray01;
    margin-bottom: 30px;
    display: block;
    box-sizing: border-box;
    padding: 18px;

    @include hover-tab;
  }

  &__container {
    padding-bottom: 25px;
  }

  &__feature-headline {
    font-family: $font__sans-serif--semibold;
    font-size: $font__size--h5;
    line-height: $line-height__h5;
    color: $color__blue--dark;
    margin-bottom: 12px;
  }

  &__headline {
    font-family: $font__sans-serif--semibold;
    font-size: $font__size--h5;
    line-height: $line-height__h5;
    color: $color__blue--dark;
    margin-bottom: 12px;
  }

  &__summary {
    font-family: $font__sans-serif;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $color__text;
    margin-bottom: 15px;
  }

  &__time-elapsed {
    display: block;
    position: absolute;
    bottom: 15px;
    font-family: $font__sans-serif--med;
    font-size: $font__size--small;
    color: $color__gray03;
  }

  @include respond-to('small') {
    &__feature-card-inner {
      display: flex;
      flex-direction: row;
      padding-bottom: 0;
    }

    &__image {
      margin-bottom: 0;
    }

    &__feature-column--right {
      width: 40%;
      padding-left: 5%;
      order: 1;
    }

    &__feature-column--left {
      width: 60%;
    }

    &__title {
      margin-bottom: 30px;
    }

    &__feature-headline {
      font-size: $font__size--h3;
      margin-bottom: 20px;
    }
    
    &__cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
      margin-top: 0;
    }

    &__card {
      width: calc(50% - 20px);
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  @include respond-to('between') {
    &__card {
      width: calc(33.3333% - 20px);
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}