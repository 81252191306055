@font-face {
  font-family: 'Montserrat';
  src: url('#{$font-path}/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Italic';
  src: url('#{$font-path}/Montserrat/Montserrat-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('#{$font-path}/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('#{$font-path}/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('#{$font-path}/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('#{$font-path}/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Garamond-Regular';
  src: url('#{$font-path}/EB_Garamond/EBGaramond-Regular.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Garamond-Italic';
  src: url('#{$font-path}/EB_Garamond/EBGaramond-MediumItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Garamond-Bold';
  src: url('#{$font-path}/EB_Garamond/EBGaramond-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

//------------------------------------------
//				Variables
//------------------------------------------
$font-size-base: 16px;
$line-height-base: 24px;
$font-family-fontawesome: 'Font Awesome';
$font-family-ionicons: 'Ionicons';
$font-family-montserrat: "Montserrat";
$font-family-montserrat-italic: "Montserrat-Italic";
$font-family-montserrat-med: "Montserrat-Medium";
$font-family-montserrat-semibold: "Montserrat-SemiBold";
$font-family-montserrat-bold: "Montserrat-Bold";
$font-family-roboto-bold: "Roboto-Bold";
$font-family-garamond: "Garamond-Regular";
$font-family-garamond-italic: "Garamond-Italic";
$font-family-garamond-bold: "Garamond-Bold";

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

//------------------------------------------
//				Font Assignments
//------------------------------------------

$font__sans-serif: $font-family-montserrat;
$font__sans-serif--italic: $font-family-montserrat-italic;
$font__sans-serif--med: $font-family-montserrat-med;
$font__sans-serif--semibold: $font-family-montserrat-semibold;
$font__sans-serif--bold: $font-family-montserrat-bold;
$font__blockquote: $font-family-garamond-italic;

//------------------------------------------
//				Font Sizes
//------------------------------------------

$font__size--h1: 56px;
$font__size--h2: 28px;
$font__size--h3: 1.15rem;
$font__size--h4: 20px;
$font__size--h5: 18px;
$font__size--h6: 14px;

$line-height__h1: 68px;
$line-height__h2: 33px;
$line-height__h3: 26px;
$line-height__h4: 30px;
$line-height__h5: 26px;
$line-height__h6: 20px;

$font__size--small: 14px;
$font__size--tiny: 12px;
// $font__size--blockquote: $font__size--h3;
$font__size--large-header: 48px;

//------------------------------------------
//				Line Height Calc
//------------------------------------------

@function lineHeight($fontSize) {
  @return $fontSize + 10px;
}