.contact-list {
  font-family: $font__sans-serif;
  font-size: $font__size--small;
  margin-bottom: 30px;

  &__title {
    font-family: $font__sans-serif--semibold;
    line-height: 18px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
  }

  &__contact {
    padding-top: 12px;
  }

  &__contact-name,
  &__contact-email,
  &__contact-phone {
    display: block;
    line-height: 22px;
    word-wrap: break-word;
  }
}