.office-card {
    font-family: $font__sans-serif;

    &__card {
        display: flex;
        flex-direction: column;
        background-color: $color__white;
        padding: 15px;
        box-shadow: 1px 1px 24px 3px rgba(0,0,0,0.06);
    }

    &__info {
        &--left {
            margin-bottom: 20px;
        }
    }

    &__name {
        font-family: $font__sans-serif--med;
    }

    &__addresses {
        display: flex;
        flex-direction: column;
    }

    &__address {
        flex: 1;
    }

    &__address:first-child {
        margin-right: 0px;
        margin-bottom: 20px;
    }

    &__address-link {
        line-height: $line-height-base;
    }

    &__phone-block {
        margin-top: 20px;
    }

    &__phone {
        display: block;
    }

    &__contact-block {
        @extend .body-copy;
        margin-bottom: 20px;
    }

    &__title {
        font-family: $font__sans-serif--semibold;
    }

    &__professionals-link {
        margin: 0;
    }

    &__rte {
        margin-top: 20px;
    }

    @include respond-to('small') {
        &__addresses {
            display: flex;
            flex-direction: row;
        }

        &__address:first-child {
            margin-right: 20px;
            margin-bottom: 0px;
        }

        &__card {
            flex-direction: row;
            min-height: 150px;
            padding: 50px 20px;
        }

        &__info {
            &--left {
                width: 60%;
                flex-grow: 1;
                margin-bottom: 0;
            }

            &--right {
                width: 40%;
                padding-left: 25px;
                border-left: 1px solid $color__gray03;
            }
        }
    }

    @include respond-to('between') {
        &__card {
            padding: 50px 100px;
        }
    }
}
