@mixin button {
  display: inline-block;
  line-height: 1.1;
  text-align: center;
  color: $color__blue--dark;
  font-family: $font__sans-serif--semibold;
  border: 2px solid $color__blue--dark;
  padding: 10px 30px;
  transition: $link-transition;

  &:hover, &--loading {
    text-decoration: none;
    background-color: $color__green;
    border-color: $color__green;
    color: $color__white;
  }

  &--loading {
    cursor: wait;
  }
}

.button {
  @include button;

  &--green {
    @include button;
    
    display: block;
    text-align: center;
    margin: 30px 0;
    background-color: $color__green--dark;
    border: none;
    color: $color__white;
  }

  @include respond-to('small') {
    display: inline-block;
    padding: 15px 50px;
  }
}

.outgoing {
  position: relative;
  padding-right: 44px;
  margin-top: 18px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 32px;
    width: 18px;
    background: url('#{$img-path}/outgoing.svg') no-repeat center center;
  }
}