$top-padding: 25px;

.resource-list {
  &__resources {
    border-top: 1px solid $color__gray02;
  }

  &__resource {
    display: block;
    border-bottom: 1px solid $color__gray02;
    padding: $top-padding 0 $top-padding 80px;
    
    @include hover-tab;
    
    &--no-icon {
      padding-left: 5px;
    }
  }

  &__icon-wrapper {
    position: absolute;
    width: 48px;
    top: $top-padding;
    bottom: $top-padding;
    left: 0;

    display: flex;
    justify-content: center;
  }

  &__icon {
    // position: absolute;
    // left: 0;
    // top: $top-padding + 8px;
    max-width: 48px;
    align-self: center

  }

  &__name {
    font-family: $font__sans-serif--med;
    margin-bottom: 0;
  }

  &__subhead {
    font-size: $font__size--tiny;
    color: $color__gray04;
    font-family: $font__sans-serif--semibold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 1.8;
  }
}