
.practice-hero {
  font-family: $font__sans-serif;
  font-size: $font__size--small;
  line-height: 16px;
  padding-top: 50px;
  // padding: 50px 0;

  &__image-col {
    margin-top: 20px;
    display: none;
  }

  @include respond-to('between') {
    padding-bottom: 50px;
    
    &__wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__info-col {
      padding-right: 60px;
      width: 60%;
    }

    &__image-col {
      display: block;
    }

    &--no-image {
      // background-color: $color__teal;
      margin-bottom: 50px;
  
      .practice-hero {
        &__info-col {
          width: 100%;
          padding: 0;
        }

      }
    }
  
  
    &__image-col {
      width: 40%;
      margin-top: 0;
    }

    &__title {
      font-size: 40px;
      line-height: 46px;
    }
  }

}