.page-hero {
  margin-top: 32px;
  border-top: 5px solid $color__blue--dark;
  padding: 30px 0;
  background-size: cover;
  min-height: 180px;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.25);
  }
  .container {
    z-index: 2;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255,255,255, .2);
    height: 100%;
    z-index: 1;
  }

  //no grey stuff when theres no text on top of it
  &--no-filter:before {
    display: none;
  }

  &--overlap {
    padding-bottom: 80px;
  }

  &--small-overlap {
    padding-bottom: 40px;
  }

  &--tall {
    min-height: 350px; 
  }

  //any place where there is no text in front of the actual image (not a dynamic height) 
  //it should be 400px tall and not cut off the sides of the image
  &--full-width-image {
    background-size: 100% auto;
    background-repeat: no-repeat;

    height: 34vw;
    min-height: 34vw;
    padding: 0;
  }

  &__title {
    margin-bottom: 12px;
    font-family: $font__sans-serif--bold;
  }

  &--has-navigation {
    display: flex;
    flex-direction: column-reverse;

    .tertiary-navigation {
      margin-bottom: 0;
    }

    .page-hero__title {
      margin-bottom: 30px;
    }
  }

  @include respond-to('small') {
    padding: 100px 0;
    min-height: 400px;

    &--full-width-image {
      height: 34vw;
      min-height: 34vw;
      padding: 0;
    }

    &--overlap,
    &--tall {
      padding: 100px 0 250px;
    }

    &--small-overlap {
      padding-bottom: 160px;
    }

    &__title {
      margin-bottom: 30px;
    }
    
    &__subtitle {
      max-width: 70%;
      a {
        text-decoration: underline;
      }

      .page-hero--has-navigation & {
        max-width: none;
      }
    }
  }

  @include respond-to('between') {
    flex-direction: row;

    &__navigation-container {
      width: 33%;
      padding-top: 10px;
      margin-right: 30px;
    }
  }

  @include respond-to('container') {
    &--full-width-image {
      height: 400px;
      min-height: 400px;
    }
  }

}

.abstract-hero {
  height: 375px;
  width: 100%;
  background-size: cover;
  background-color: $color__blue--dark;
}