@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

[v-cloak] {
  display: none !important;
}

html,
body {
  height: 100%;
  font-family: "Georgia";
}

a {
  text-decoration: none;
  font-family: "TradeGothicLT";
  font-weight: 400;
}

b, strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

div,
section,
input,
textarea {
  box-sizing: border-box;
}

button {
  -webkit-appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  overflow: visible;
}

select,
input {
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

img {
  max-width: 100%;
}

.hidden {
  display: none;
}

.hidden-content {
  padding: 8px;
  position: absolute;
  background: white;
  left: 50%;
  height: 30px;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 1000;
}
.hidden-content:focus {
  transform: translateY(0%);
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/public/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Italic";
  src: url("/assets/public/fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("/assets/public/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("/assets/public/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url("/assets/public/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("/assets/public/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Garamond-Regular";
  src: url("/assets/public/fonts/EB_Garamond/EBGaramond-Regular.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Garamond-Italic";
  src: url("/assets/public/fonts/EB_Garamond/EBGaramond-MediumItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Garamond-Bold";
  src: url("/assets/public/fonts/EB_Garamond/EBGaramond-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
.pipe-divider {
  margin: 0 12px;
}

.button, .modal-alert__button, .footer__button {
  display: inline-block;
  line-height: 1.1;
  text-align: center;
  color: #012169;
  font-family: "Montserrat-SemiBold";
  border: 2px solid #012169;
  padding: 10px 30px;
  transition: all 0.2s ease-in-out;
}
.button:hover, .modal-alert__button:hover, .footer__button:hover, .button--loading {
  text-decoration: none;
  background-color: #3FBB94;
  border-color: #3FBB94;
  color: #ffffff;
}
.button--loading {
  cursor: wait;
}
.button--green {
  display: inline-block;
  line-height: 1.1;
  text-align: center;
  color: #012169;
  font-family: "Montserrat-SemiBold";
  border: 2px solid #012169;
  padding: 10px 30px;
  transition: all 0.2s ease-in-out;
  display: block;
  text-align: center;
  margin: 30px 0;
  background-color: #2D8469;
  border: none;
  color: #ffffff;
}
.button--green:hover, .button--green--loading {
  text-decoration: none;
  background-color: #3FBB94;
  border-color: #3FBB94;
  color: #ffffff;
}
.button--green--loading {
  cursor: wait;
}
@media (min-width: 40em) {
  .button, .modal-alert__button, .footer__button {
    display: inline-block;
    padding: 15px 50px;
  }
}

.outgoing {
  position: relative;
  padding-right: 44px;
  margin-top: 18px;
}
.outgoing:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  height: 32px;
  width: 18px;
  background: url("/assets/public/images/outgoing.svg") no-repeat center center;
}

.divider {
  border: none;
  border-bottom: 1px solid #CBCBCB;
  width: 100%;
}

@media (min-width: 40em) {
  .mobile-only {
    display: none;
  }
}

.mobile-hidden {
  display: none;
}
@media (min-width: 40em) {
  .mobile-hidden {
    display: block;
  }
}

.container {
  width: 100%;
  max-width: 1376px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  position: relative;
}
.container--top {
  margin-top: 36px;
}
.container--small {
  max-width: 1184px;
}
.container--inner {
  max-width: 908px;
}
.container--no-padding-mobile {
  padding-left: 0;
  padding-right: 0;
}
.container--right-column {
  position: relative;
}
@media (min-width: 40em) {
  .container--no-padding-mobile {
    padding-left: 20px;
    padding-right: 20px;
  }
  .container--top {
    margin-top: 72px;
  }
}
@media (min-width: 56em) {
  .container--right-column {
    width: 60%;
    margin-left: 294px;
    margin-right: 10%;
  }
  .container--page-nav-aligned {
    padding-left: 45px;
  }
}
@media (min-width: 74em) {
  .container--right-column {
    width: 60%;
    margin-left: 30%;
    margin-right: 10%;
  }
}

.section {
  margin-bottom: 36px;
  position: relative;
}
.section--small-overlap {
  margin-top: -70px;
  z-index: 2;
}
.section--overlap {
  margin-top: -30px;
  z-index: 2;
}
.section--tall-overlap {
  margin-top: -300px;
  z-index: 2;
}
.section--full-width-background-overlap {
  margin-top: -50px;
  z-index: 2;
}
.section--mobile-overlap {
  margin-top: -300px;
  z-index: 2;
}
.section--reduced-section {
  margin-bottom: 1.5rem !important;
}
@media (min-width: 40em) {
  .section {
    margin-bottom: 4.5rem;
  }
  .section--small-overlap {
    margin-top: -80px;
  }
  .section--overlap, .section--mobile-overlap {
    margin-top: -200px;
  }
}
@media (min-width: 56em) {
  .section--tall-overlap {
    margin-top: -300px;
  }
}
@media (min-width: 74em) {
  .section--full-width-background-overlap {
    margin-top: -100px;
  }
}

.body-copy, .secondary-navigation__select select, .practice-list, .profile-focus__select select, .people-search__select select, .people-search__letter-toggle, .page-nav-mobile__dropdown-list select, .office-card__contact-block, .modal-alert__body, .media-slider__headline, .language-selector__select select, .industry-hero__news-card-summary, .industry-hero, .image-promo__summary, .image-promo__date, .home-hero, .featured-sections__summary, .featured-news, .bio-carousel__summary, li, sub, p {
  font-size: 16px;
  font-family: "Montserrat";
  line-height: 24px;
  color: #333333;
}
.body-copy a, .secondary-navigation__select select a, .practice-list a, .profile-focus__select select a, .people-search__select select a, .people-search__letter-toggle a, .page-nav-mobile__dropdown-list select a, .office-card__contact-block a, .modal-alert__body a, .media-slider__headline a, .language-selector__select select a, .industry-hero__news-card-summary a, .industry-hero a, .image-promo__summary a, .image-promo__date a, .home-hero a, .featured-sections__summary a, .featured-news a, .bio-carousel__summary a, li a, sub a, p a {
  line-height: inherit;
}

.rte {
  font-family: "Montserrat";
}
.rte a {
  text-decoration: underline;
}

h1 {
  font-size: 28px;
  line-height: 33px;
  font-family: "Montserrat-Medium";
  margin-bottom: 20px;
}

h2 {
  font-size: 1.15rem;
  line-height: 26px;
  font-family: "Montserrat-Medium";
  margin-bottom: 26px;
}

h3 {
  font-size: 20px;
  line-height: 30px;
  font-family: "Montserrat-Medium";
  margin-bottom: 12px;
}

h4 {
  font-size: 18px;
  line-height: 26px;
  font-family: "Montserrat";
  margin-bottom: 12px;
}

h5 {
  font-size: 14px;
  line-height: 20px;
  font-family: "Montserrat";
  margin-bottom: 12px;
}

img,
video {
  max-width: 100%;
}

a {
  font-family: "Montserrat-SemiBold";
  color: #012169;
  line-height: 32px;
}
a:hover {
  text-decoration: underline;
}

p {
  margin-top: 22px;
}
p:first-of-type {
  margin-top: 0;
}

sub {
  font-size: 12px;
  line-height: 18px;
}

.rte__pull-quote--with-quotes:before {
  content: "“ ";
  margin-left: -18px;
}
.rte__pull-quote--with-quotes:after {
  content: " ”";
}

.rte__call-out, .article-info__read-title {
  display: inline;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  margin-right: 6px;
}

blockquote, .rte__pull-quote {
  margin: 50px 0;
  font-family: "Garamond-Italic";
  font-style: italic;
  font-size: 1.15rem;
  line-height: 42px;
  padding-left: 8px;
}
blockquote span, .rte__pull-quote span {
  background-color: #B1E4E3;
}

ul,
ol {
  padding-left: 18px;
}

li {
  padding-bottom: 18px;
}

ul li {
  list-style: disc;
}

ol li {
  list-style: decimal;
}

hr {
  margin: 30px 0;
  border: none;
  border-top: 1px solid #CBCBCB;
}

.rte h2 {
  margin: 24px 0;
  color: #000000;
}
.rte h2.jump-link {
  margin: -144px 0;
  padding: 144px 0;
}
.rte h3 {
  margin: 18px 0;
  color: #000000;
  font-weight: bold;
}
.rte h4,
.rte h5 {
  margin: 12px 0;
  color: #000000;
}
.rte h5 {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
}
.rte ul {
  margin-top: 20px;
}
.rte ul li ul {
  margin-top: 10px;
}
.rte ol {
  margin-top: 20px;
}

@media (min-width: 40em) {
  h1 {
    font-size: 56px;
    line-height: 68px;
    font-family: "Montserrat-Medium";
  }
  h2 {
    font-size: 28px;
    line-height: 33px;
    font-family: "Montserrat-Medium";
  }
  h3 {
    font-size: 1.15rem;
    line-height: 26px;
    font-family: "Montserrat-Medium";
  }
  h4 {
    font-size: 20px;
    line-height: 30px;
    font-family: "Montserrat";
  }
  h5 {
    font-size: 18px;
    line-height: 26px;
    font-family: "Montserrat";
  }
  blockquote, .rte__pull-quote {
    margin: 50px 0;
  }
}
input {
  border: 1px solid #E6E6E6;
  padding: 12px;
  color: #4A4A4A;
  background-color: #FCFCFC;
  -webkit-appearance: none;
  font-family: "Montserrat";
  font-size: 16px;
  line-height: 24px;
}
input::placeholder {
  color: #767676;
  -webkit-appearance: none;
  line-height: 24px;
}
input[type=radio] {
  opacity: 0;
  padding: 0;
  margin: 0;
  border: none;
  height: 20px;
  width: 17px;
  position: relative;
  z-index: 10;
}

textarea {
  border: 1px solid #E6E6E6;
  padding: 12px;
  background-color: #FCFCFC;
}

.accordion {
  margin-top: -10.8px;
}
.page__section-heading + .section__content .accordion {
  margin-top: auto;
}
.accordion__section {
  border-top: 1px solid #CBCBCB;
}
.accordion__before-content + .accordion__section {
  margin-top: 30px;
}
.accordion__node {
  border-bottom: 1px solid #CBCBCB;
}
.accordion__button {
  display: flex;
  align-items: center;
  width: 100%;
  line-height: 26px;
  padding: 20px 30px 20px 0;
  text-align: left;
  position: relative;
}
.accordion__button--open .accordion__label {
  color: #333333;
}
.accordion__button--open .accordion__label:after {
  display: none;
}
.accordion__button .expand-toggle__icon {
  right: 0;
}
.accordion__button:hover .accordion__label:after {
  width: 100%;
}
.accordion__button img {
  display: none;
}
@media (min-width: 40em) {
  .accordion__button img {
    display: block;
    width: 25%;
    padding-right: 2rem;
    max-width: fit-content;
  }
}
.accordion__label {
  font-family: "Montserrat-SemiBold";
  color: #012169;
  position: relative;
  display: inline-block;
}
.accordion__label:after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background-color: #3FBB94;
  margin-top: -4px;
  transition: width 0.2s ease-in-out;
}
.accordion__inner {
  padding-bottom: 30px;
}
.accordion__description {
  font-family: "Montserrat";
  line-height: 24px;
  padding-right: 30px;
}
@media (min-width: 40em) {
  .accordion {
    margin-top: -36px;
  }
}

.article-columns__right {
  margin-bottom: 50px;
}
@media (min-width: 40em) {
  .article-columns {
    display: flex;
    flex-direction: row;
  }
  .article-columns__left {
    width: 70%;
    padding-left: 10%;
    position: relative;
  }
  .article-columns__left--full {
    padding-left: 0;
  }
  .article-columns__right {
    width: 30%;
    padding-left: 8%;
    margin-bottom: 0;
  }
  .article-columns__right .language-selector__desktop {
    margin-left: 0;
  }
}

/*
.article-list {
  &__item {
    padding: 25px 0;
    display: block;
    @include hover-tab;
  }

  &__title {
    font-size: $font__size--h3;
    font-family: $font__sans-serif;
    line-height: $line-height__h3;
    color: $color__text;
    margin-bottom: 14px;
  }

  &__secondary-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__item-secondary {
    font-size: $font__size--tiny;
    line-height: $font__size--small;
    color: $color__gray05;
    text-transform: uppercase;
  }

  &__divider {
    font-size: $font__size--tiny;
    line-height: $font__size--small;
    color: $color__gray05;
    padding: 0 5px;
  }
}
*/
.article-list-item {
  display: block;
  border-bottom: 1px solid #CBCBCB;
  padding: 25px 0;
  overflow: hidden;
  position: relative;
}
.article-list-item .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.article-list-item .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.article-list-item:hover {
  text-decoration: none;
}
.article-list-item:hover .hover-tab {
  right: 0;
}
.article-list-item__description {
  color: #333333;
  font-size: 18px;
  line-height: 26px;
  font-family: "Montserrat";
}
.article-list-item--no-link .article-list-item__description {
  color: #9B9B9B;
}
.article-list-item__meta {
  color: #656565;
  font-family: "Montserrat-Medium";
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 1.47px;
}
.article-list-item__divider {
  font-size: 12px;
  line-height: 14px;
  color: #656565;
  padding: 0 5px;
}

.article-info {
  font-family: "Montserrat";
}
.article-info__disclaimer {
  font-size: 10px;
  line-height: 11px;
}

.article-hero {
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 16px;
  padding: 50px 0;
}
.article-hero__date {
  text-transform: uppercase;
  margin-bottom: 30px;
}
.article-hero__title-section {
  margin-bottom: 60px;
}
.article-hero__source {
  margin-bottom: 6px;
}
.article-hero__emphasized-source {
  margin-top: 25px;
  font-style: italic;
  font-size: 16px;
  line-height: 20px;
}
.article-hero__authors {
  margin-bottom: 6px;
}
.article-hero__news-types {
  font-family: "Montserrat-SemiBold";
  line-height: 18px;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.article-hero__abstract {
  font-size: 16px;
  line-height: 24px;
}
.article-hero__abstract a {
  line-height: inherit;
}
.article-hero--no-image {
  margin-bottom: 50px;
}
.article-hero--no-image .article-hero__info-col {
  width: 100%;
  padding: 0;
}
.article-hero--no-image .article-hero__image-col {
  display: none;
}
@media (min-width: 40em) {
  .article-hero__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .article-hero__wrapper--case-study {
    padding-left: 10%;
    align-items: flex-start;
  }
  .article-hero__info-col {
    padding-right: 60px;
    width: 60%;
  }
  .article-hero__image-col {
    width: 40%;
  }
  .article-hero__title {
    font-size: 40px;
    line-height: 46px;
  }
  .article-hero--no-image .article-hero__title {
    font-size: 56px;
    line-height: 1.125;
  }
}

.bio-card {
  font-family: "Montserrat";
  color: #333333;
}
.bio-card__wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 30px 20px;
  box-shadow: 1px 1px 24px 3px rgba(0, 0, 0, 0.06);
}
.bio-card__image {
  max-width: 220px;
  margin: 20px auto;
}
.bio-card__mobile-info {
  text-align: center;
}
.bio-card__info-wrapper {
  position: relative;
  text-align: center;
}
.bio-card__info {
  margin-bottom: 20px;
}
.bio-card__resources {
  position: relative;
}
@media print {
  .bio-card__resources {
    display: none;
  }
}
.bio-card__social-links {
  position: relative;
  width: 200px;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bio-card__social-icon {
  margin-left: 20px;
  display: flex;
}
.bio-card__social-icon:first-of-type {
  margin-left: 0;
}
.bio-card__title {
  color: #4A4A4A;
  text-transform: uppercase;
  font-family: "Montserrat-Medium";
  letter-spacing: 1.7px;
}
.bio-card__subtitle {
  font-family: "Montserrat-Medium";
  text-transform: uppercase;
  margin: 15px 0;
  letter-spacing: 1.7px;
}
.bio-card__link--email {
  display: block;
  margin-bottom: 12px;
}
.bio-card__office-link {
  display: block;
}
.bio-card__qualifications {
  text-align: left;
}
.bio-card__service-link {
  display: block;
}
.bio-card__divider {
  display: none;
}
.bio-card__pronouns {
  color: #8d8d8d;
  margin-top: -1em;
  margin-bottom: 1em;
}
@media (min-width: 40em) {
  .bio-card__wrapper {
    flex-direction: row;
    padding: 50px 30px;
  }
  .bio-card__image {
    width: 33.333%;
    padding-left: 20px;
    padding-right: 40px;
    max-width: none;
    margin: 0;
  }
  .bio-card__info-wrapper {
    width: 66.666%;
    text-align: left;
  }
  .bio-card__info-wrapper--full-width {
    width: 100%;
  }
  .bio-card__title {
    font-family: "Montserrat-Bold";
  }
  .bio-card__name {
    margin-bottom: 16px;
  }
  .bio-card__offices {
    display: flex;
    flex-direction: row;
  }
  .bio-card__office {
    margin-right: 60px;
  }
  .bio-card__office + .bio-card__office .bio-card__office-link--mobile-phone:only-child {
    margin-top: 2rem;
  }
  .bio-card__qualifications {
    border-top: 1px solid #CBCBCB;
    padding-top: 20px;
    margin-top: 20px;
  }
  .bio-card__industry {
    padding-top: 20px;
  }
  .bio-card__service-link {
    display: inline;
  }
  .bio-card__divider {
    display: inline-block;
    font-family: "Montserrat";
  }
}
@media (min-width: 56em) {
  .bio-card__name {
    padding-right: 200px;
  }
  .bio-card__resources {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
  .bio-card__social-links {
    justify-content: flex-end;
  }
}

.bio-carousel {
  font-family: "Montserrat";
  background-color: #B1E4E3;
  padding: 30px 16px;
}
.bio-carousel__slide {
  display: flex;
  flex-direction: column;
  position: relative;
}
.bio-carousel__left-panel {
  background-color: #F2F2F2;
  border-top: 4px solid #3FBB94;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
}
.bio-carousel__right-panel {
  background-color: #B1E4E3;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
}
.bio-carousel__panel-inner {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3D(-100%, 0, 0);
  min-width: 100%;
  transition: 0.5s;
}
.bio-carousel__panel-inner--left {
  padding: 56px 20px 26px;
}
.bio-carousel__panel-inner--right {
  padding-top: 45px;
}
.bio-carousel__panel-inner--active {
  visibility: visible;
  position: static;
  transform: translate3D(0, 0, 0);
}
.bio-carousel__panel-inner--next-slide {
  transform: translate3D(100%, 0, 0);
}
.bio-carousel__intro {
  position: absolute;
  top: 25px;
  left: 20px;
  font-family: "Montserrat-SemiBold";
  letter-spacing: 1.03px;
  text-transform: uppercase;
}
.bio-carousel__bio-details {
  text-align: center;
  color: #333333;
  display: block;
  box-sizing: border-box;
}
.bio-carousel__image {
  max-width: 166px;
  margin-bottom: 20px;
}
.bio-carousel__name {
  font-size: 1.15rem;
  font-family: "Montserrat-Medium";
  margin-bottom: 12px;
}
.bio-carousel__title {
  font-family: "Montserrat-Medium";
  text-transform: uppercase;
  color: #9B9B9B;
}
.bio-carousel__summary-title {
  margin-bottom: 15px;
  line-height: 36px;
}
.bio-carousel__summary {
  color: #000000;
  margin-bottom: 16px;
}
.bio-carousel__link {
  font-family: "Montserrat-SemiBold";
  display: inline-block;
  line-height: 26px;
}
.bio-carousel__link:after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background-color: #3FBB94;
  margin-top: -4px;
  transition: width 0.2s ease-in-out;
}
.bio-carousel__link:hover {
  text-decoration: none;
}
.bio-carousel__link:hover:after {
  width: 100%;
}
.bio-carousel__controls {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 54px;
  right: 32px;
}
.bio-carousel__arrow {
  width: 14px;
  height: 22px;
  color: #3FBB94;
  font-size: 22px;
}
.bio-carousel__arrow::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e917";
  font-weight: bold;
}
.bio-carousel__left-arrow {
  transform: rotate(180deg);
}
.bio-carousel__count {
  padding: 3px 10px;
}
@media (min-width: 40em) {
  .bio-carousel {
    background-color: transparent;
    padding: 0;
  }
  .bio-carousel__slide {
    flex-direction: row;
  }
  .bio-carousel__left-panel {
    width: 30%;
    border-top: 4px solid #CBCBCB;
  }
  .bio-carousel__right-panel {
    width: 70%;
    border-top: 4px solid #3FBB94;
  }
  .bio-carousel__panel-inner--left {
    padding: 85px 30px 35px;
  }
  .bio-carousel__panel-inner--right {
    padding: 80px;
  }
  .bio-carousel__intro {
    top: 35px;
    left: 30px;
  }
  .bio-carousel__bio-details {
    text-align: left;
  }
  .bio-carousel__image {
    max-width: 204px;
  }
  .bio-carousel__controls {
    right: 40px;
    bottom: 40px;
    top: initial;
  }
}

.blog-cards__feature-card {
  display: block;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 1px 1px 24px 3px rgba(0, 0, 0, 0.06);
  position: relative;
  overflow: hidden;
  position: relative;
}
.blog-cards__feature-card .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.blog-cards__feature-card .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.blog-cards__feature-card:hover {
  text-decoration: none;
}
.blog-cards__feature-card:hover .hover-tab {
  right: 0;
}
.blog-cards__feature-card-inner {
  padding-bottom: 25px;
}
.blog-cards__image {
  margin-bottom: 15px;
}
.blog-cards__title {
  font-size: 12px;
  text-transform: uppercase;
  color: #2D8469;
  letter-spacing: 1.47px;
  margin-bottom: 8px;
}
.blog-cards__cards {
  padding-top: 20px;
}
.blog-cards__card {
  background-color: #F2F2F2;
  margin-bottom: 30px;
  display: block;
  box-sizing: border-box;
  padding: 18px;
  overflow: hidden;
  position: relative;
}
.blog-cards__card .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.blog-cards__card .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.blog-cards__card:hover {
  text-decoration: none;
}
.blog-cards__card:hover .hover-tab {
  right: 0;
}
.blog-cards__container {
  padding-bottom: 25px;
}
.blog-cards__feature-headline {
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  line-height: 26px;
  color: #012169;
  margin-bottom: 12px;
}
.blog-cards__headline {
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  line-height: 26px;
  color: #012169;
  margin-bottom: 12px;
}
.blog-cards__summary {
  font-family: "Montserrat";
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 15px;
}
.blog-cards__time-elapsed {
  display: block;
  position: absolute;
  bottom: 15px;
  font-family: "Montserrat-Medium";
  font-size: 14px;
  color: #9B9B9B;
}
@media (min-width: 40em) {
  .blog-cards__feature-card-inner {
    display: flex;
    flex-direction: row;
    padding-bottom: 0;
  }
  .blog-cards__image {
    margin-bottom: 0;
  }
  .blog-cards__feature-column--right {
    width: 40%;
    padding-left: 5%;
    order: 1;
  }
  .blog-cards__feature-column--left {
    width: 60%;
  }
  .blog-cards__title {
    margin-bottom: 30px;
  }
  .blog-cards__feature-headline {
    font-size: 1.15rem;
    margin-bottom: 20px;
  }
  .blog-cards__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 0;
  }
  .blog-cards__card {
    width: calc(50% - 20px);
    margin-right: 10px;
    margin-left: 10px;
  }
}
@media (min-width: 56em) {
  .blog-cards__card {
    width: calc(33.3333% - 20px);
    margin-right: 10px;
    margin-left: 10px;
  }
}

.box-list {
  background-color: #F2F2F2;
  padding: 20px;
}
.box-list__title {
  text-transform: uppercase;
  font-family: "Montserrat-Medium";
  letter-spacing: 1.7px;
  margin-bottom: 20px;
}
.box-list__item {
  break-inside: avoid;
}
@media (min-width: 40em) {
  .box-list {
    padding: 40px;
  }
  .box-list__title {
    margin-bottom: 30px;
  }
  .box-list__list {
    column-count: 3;
  }
  .box-list__item {
    margin-bottom: 0.5em;
  }
}

.category-list {
  font-family: "Montserrat";
  line-height: 24px;
}
.category-list__category {
  border-top: 1px solid #CBCBCB;
  padding: 22px 0;
}
.category-list__title {
  font-size: 18px;
  font-family: "Montserrat-SemiBold";
  text-transform: uppercase;
  margin-bottom: 18px;
  color: #1290CE;
  letter-spacing: 1.7px;
}
.category-list__content li {
  padding-bottom: 0.6em;
}
.category-list__content li:last-of-type {
  padding-bottom: 0;
}
.category-list__additional {
  padding-top: 15px;
}

.case-study-list-item {
  display: block;
  border-bottom: 1px solid #CBCBCB;
  padding: 25px 0;
  overflow: hidden;
  position: relative;
}
.case-study-list-item .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.case-study-list-item .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.case-study-list-item:hover {
  text-decoration: none;
}
.case-study-list-item:hover .hover-tab {
  right: 0;
}
.case-study-list-item__title {
  color: #333333;
  margin: 0;
}
.case-study-list-item__summary {
  margin-top: 10px;
  font-family: "Montserrat-Medium";
  font-size: 14px;
  line-height: 1.3;
  color: #9B9B9B;
  font-family: "Montserrat-Medium";
}

.columns__container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.columns__group {
  margin-bottom: 30px;
}
.columns .news-cards__card {
  margin-bottom: 0;
}
@media (min-width: 40em) {
  .columns__three-col {
    width: calc(33% - 30px);
    margin: 0 15px;
  }
  .columns__four-col {
    width: calc(25% - 30px);
    margin: 0 15px;
  }
  .columns__title {
    margin-top: 0;
  }
  .columns__container {
    margin-top: 0;
    flex-direction: row;
    margin-left: -15px;
    margin-right: -15px;
  }
  .columns__group:last-of-type {
    margin-bottom: 0;
  }
}

.contact-list {
  font-family: "Montserrat";
  font-size: 14px;
  margin-bottom: 30px;
}
.contact-list__title {
  font-family: "Montserrat-SemiBold";
  line-height: 18px;
  letter-spacing: 1.7px;
  text-transform: uppercase;
}
.contact-list__contact {
  padding-top: 12px;
}
.contact-list__contact-name, .contact-list__contact-email, .contact-list__contact-phone {
  display: block;
  line-height: 22px;
  word-wrap: break-word;
}

.css-columns__item {
  margin-bottom: 30px;
  break-inside: avoid;
}
@media (min-width: 40em) {
  .css-columns--three {
    column-count: 3;
    column-gap: 30px;
  }
  .css-columns__title {
    margin-top: 0;
  }
}

.cross-links {
  font-family: "Montserrat";
}
.cross-links__cards {
  padding-top: 10px;
}
.cross-links__list {
  border-top: 1px solid #CBCBCB;
  padding-bottom: 30px;
}
.cross-links__card {
  display: block;
  background-color: #F2F2F2;
  margin-bottom: 30px;
  padding: 18px;
  box-sizing: border-box;
  line-height: 24px;
  overflow: hidden;
  position: relative;
}
.cross-links__card .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cross-links__card .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.cross-links__card:hover {
  text-decoration: none;
}
.cross-links__card:hover .hover-tab {
  right: 0;
}
.cross-links__primary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cross-links__info {
  width: 55%;
  padding-right: 10px;
}
.cross-links__image {
  width: 45%;
  max-width: 140px;
}
.cross-links__type {
  font-size: 12px;
  color: #9B9B9B;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.cross-links__title {
  font-family: "Montserrat-SemiBold";
}
.cross-links__summary {
  font-size: 14px;
  color: #000000;
  font-family: "Montserrat-Medium";
  margin-top: 12px;
}
@media (min-width: 40em) {
  .cross-links__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .cross-links__card {
    width: calc(50% - 20px);
    margin-right: 10px;
    margin-left: 10px;
  }
}

.event-block {
  padding: 25px 0 20px;
  border-top: 4px solid #012169;
  border-bottom: 1px solid #CBCBCB;
  font-family: "Montserrat";
}
.event-block__time-block {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  font-size: 14px;
}
.event-block__date {
  font-family: "Montserrat-SemiBold";
}
.event-block__divider {
  padding: 0 6px;
}
.event-block__location, .event-block__address {
  line-height: 1.5;
}
.event-block__register {
  margin-top: 18px;
}
@media (min-width: 48em) {
  .event-block {
    display: flex;
    flex-direction: row;
  }
  .event-block__data {
    width: 80%;
  }
  .event-block__register {
    margin-top: 0;
  }
}

.expand-toggle__toggle {
  position: relative;
  display: block;
  padding-left: 36px;
  font-family: "Montserrat-SemiBold";
  color: #333333;
  line-height: 26px;
  margin-top: 20px;
  cursor: pointer;
}
.expand-toggle__toggle--loading {
  cursor: wait;
  color: #9B9B9B;
}
.expand-toggle__toggle .expand-toggle__icon {
  left: 0;
  top: 0;
}
.expand-toggle__toggle:after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background-color: #3FBB94;
  margin-top: -4px;
  transition: width 0.2s ease-in-out;
}
.expand-toggle__toggle:hover:after {
  width: 100%;
}
.expand-toggle__icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
  font-size: 24px;
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  line-height: 1rem;
  color: #012169;
  border: 1px solid #012169;
  border-radius: 50%;
  transition: all 0.6s ease-out;
}
.expand-toggle__icon--open {
  color: #9B9B9B;
  border: 1px solid #9B9B9B;
  border-radius: 50%;
  transform: rotate(225deg);
}
.expand-toggle__icon--close-icon {
  transform: rotate(225deg);
  color: #9B9B9B;
  border: 0.0625rem solid #9B9B9B;
}

.expanding-list__articles {
  border-top: 1px solid #CBCBCB;
}
.expanding-list__controls {
  position: relative;
  overflow: hidden;
}
.expanding-list__controls::after {
  content: "";
  clear: both;
  display: table;
}
.expanding-list__view-more-padding {
  padding-top: 30px;
}
.expanding-list__view-more-controls {
  position: relative;
  text-align: center;
  padding: 10px 0;
}
.expanding-list__count {
  font-family: "Montserrat";
  line-height: 26px;
  margin-top: 20px;
  float: right;
}

.featured-video-embed-or-image__image {
  width: 100%;
  height: auto;
}
.featured-video-embed-or-image__video {
  width: 100%;
  height: auto;
}

.featured-news {
  background-position: center;
  background-repeat: no-repeat;
  background-color: #012169;
  background-size: cover;
  color: #ffffff;
  padding: 30px 0;
}
.featured-news__featured-article {
  margin-bottom: 30px;
}
.featured-news__featured-summary {
  margin-bottom: 35px;
}
.featured-news__featured-button {
  color: #ffffff;
  border-color: #ffffff;
}
.featured-news__articles {
  border-top: 1px solid #ffffff;
}
.featured-news__article {
  display: block;
  border-bottom: 1px solid #ffffff;
  padding: 25px 15px;
  overflow: hidden;
  position: relative;
}
.featured-news__article .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.featured-news__article .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.featured-news__article:hover {
  text-decoration: none;
}
.featured-news__article:hover .hover-tab {
  right: 0;
}
.featured-news__description {
  color: #ffffff;
  line-height: 24px;
  margin-bottom: 8px;
}
.featured-news__meta {
  color: #ffffff;
  font-family: "Montserrat-Medium";
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 1.47px;
}
@media (min-width: 40em) {
  .featured-news {
    padding: 80px 0;
  }
  .featured-news__wrapper {
    display: flex;
    flex-direction: row;
  }
  .featured-news__featured-article {
    width: 40%;
    margin-bottom: 0;
  }
  .featured-news__featured-title {
    font-size: 36px;
    line-height: 44px;
    margin-top: 12px;
  }
  .featured-news__articles {
    margin-left: 10%;
    width: 50%;
  }
}

.featured-sections--no-border .featured-sections__wrapper {
  border-top: none;
}
.featured-sections__wrapper {
  border-top: 5px solid #012169;
}
.featured-sections__block {
  padding: 30px 15px 60px;
  text-align: center;
}
.featured-sections__title {
  font-family: "Montserrat-Medium";
  font-size: 1.15rem;
  line-height: 26px;
}
.featured-sections__summary {
  margin-bottom: 45px;
}
.featured-sections__link {
  display: inline-block;
  line-height: 1.1;
  text-align: center;
  color: #012169;
  font-family: "Montserrat-SemiBold";
  border: 2px solid #012169;
  padding: 10px 30px;
  transition: all 0.2s ease-in-out;
  background-color: #2D8469;
  border-color: #2D8469;
  color: #ffffff;
}
.featured-sections__link:hover, .featured-sections__link--loading {
  text-decoration: none;
  background-color: #3FBB94;
  border-color: #3FBB94;
  color: #ffffff;
}
.featured-sections__link--loading {
  cursor: wait;
}
@media (min-width: 40em) {
  .featured-sections__wrapper {
    display: flex;
    flex-direction: row;
  }
  .featured-sections__block {
    width: 50%;
    padding: 90px 20px 100px;
  }
  .featured-sections__title {
    font-size: 36px;
  }
  .featured-sections__summary {
    font-size: 18px;
    line-height: 26px;
    font-family: "Montserrat-Medium";
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 70px;
  }
}

.feature-video {
  font-family: "Montserrat";
}
.feature-video__description {
  margin-bottom: 30px;
}
.feature-video__video {
  margin-bottom: 20px;
}
.feature-video__headline {
  font-weight: bold;
  margin-bottom: 1.5rem;
}
@media (min-width: 56em) {
  .feature-video__inner {
    display: flex;
    flex-direction: row;
    align-content: center;
  }
  .feature-video__video {
    margin-bottom: 0;
    width: 40%;
  }
  .feature-video__content {
    width: 60%;
    margin-left: 5%;
  }
}

.footer {
  width: 100%;
  font-family: "Montserrat";
  background-color: #012169;
  color: #ffffff;
}
.footer__top {
  background: url("/assets/public/images/footer_bg.png") no-repeat center center;
  background-size: cover;
  padding: 50px 0;
}
.footer__logo {
  width: 254px;
  margin-bottom: 24px;
}
.footer__slogan {
  max-width: 550px;
  font-size: 14px;
  line-height: 22px;
}
.footer__links {
  margin-top: 50px;
}
.footer__button {
  display: block;
  color: #ffffff;
  border-color: #ffffff;
  text-align: center;
  padding: 5px;
}
.footer__link {
  display: block;
  color: #ffffff;
}
.footer__bottom {
  background-color: #001546;
  padding: 15px 0;
}
.footer__legal {
  font-size: 12px;
  line-height: 1.15rem;
}
.footer__legal-link {
  font-size: 12px;
  line-height: 1.15rem;
  color: #ffffff;
  display: block;
  margin-top: 15px;
}
.footer__social {
  display: flex;
  justify-content: center;
}
.footer__social li {
  margin-left: 20px;
  list-style: none;
  padding: 0;
}
.footer__social-label {
  font-size: 12px;
  font-family: "Montserrat-SemiBold";
  letter-spacing: 1.5px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 14px;
  line-height: 25px;
}
.footer__icon {
  color: #ffffff;
  font-size: 1.15rem;
  margin-left: 20px;
  opacity: 0.5;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.footer__icon--linkedin::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e908";
}
.footer__icon--twitter::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e91e";
}
.footer__icon--facebook::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e909";
}
.footer__icon--youtube-1::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90b";
}
.footer__icon--youtube-2::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea9d";
}
.footer__icon--instagram::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\ea92";
}
.footer__icon--rss::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e91b";
}
.footer__icon:hover {
  opacity: 1;
}
.footer__icon:first-of-type {
  margin-left: 0;
}
@media (min-width: 40em) {
  .footer__cols {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .footer__links {
    text-align: right;
    margin-top: 0;
  }
  .footer__button {
    padding: 10px 30px;
  }
  .footer__legal {
    display: flex;
    flex-direction: row;
  }
  .footer__legal-link {
    margin-left: 50px;
    margin-top: 0;
  }
}
@media print {
  .footer {
    display: none;
  }
}

.highlights {
  padding: 30px 0 10px;
  border-top: 4px solid #012169;
  border-bottom: 1px solid #CBCBCB;
  margin-bottom: 5px;
}
.highlights a {
  font-family: "Garamond-Bold";
  font-weight: bold;
}
.highlights li {
  font-size: 18px;
  font-family: "Garamond-Regular";
}
.highlights p {
  font-size: 18px;
  font-family: "Garamond-Regular";
}
.highlights strong {
  font-family: "Garamond-Bold";
}

.home-hero__pause {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
  opacity: 1;
  font-size: 16px;
  padding: 10px;
  color: #012169;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solod #012169;
  display: none;
}
.home-hero__wrapper {
  padding-top: 30px;
  padding-bottom: 40px;
  min-height: 70vh;
  position: relative;
}
.home-hero__top-padding {
  padding-top: 90px;
}
.home-hero__slide-inner {
  display: none;
}
.home-hero__title {
  font-family: "Montserrat-Bold";
  color: #012169;
}
.home-hero__slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  height: 100%;
  background-size: cover;
  transition: opacity 500ms ease-in-out;
  visibility: hidden;
}
.home-hero__slide--active {
  opacity: 1;
  visibility: visible;
}
.home-hero__slide-gradient {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #F2F2F2;
}
.home-hero__link {
  margin-top: 40px;
}
.home-hero__navigation {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}
.home-hero__nav-inner {
  display: flex;
  padding: 0 5px;
  transition: left 500ms ease-in-out;
}
.home-hero__tab {
  position: relative;
  margin: 0 6px 6px;
  width: calc(100vw - 52px);
  background-color: #ffffff;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.apply-transitions .home-hero__tab {
  transition: all 0.2s ease-in-out, transform 0.3s ease-in-out;
}
.home-hero__tab--inactive {
  transform: scaleY(0.9);
}
.home-hero__tab--inactive .home-hero__tab-image {
  filter: blur(2px);
}
.home-hero__tab-inner {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.home-hero__tab-image {
  width: 100%;
  height: auto;
}
.apply-transitions .home-hero__tab-image {
  transition: all 0.2s ease-in-out;
}
.home-hero__tab-title {
  color: #012169;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 16px;
  font-family: "Montserrat-SemiBold";
  text-align: left;
}
.home-hero__tab-link {
  margin-bottom: 0;
}
.home-hero__indicator-container {
  width: calc(100% - 12px);
  height: 5px;
  position: absolute;
  left: 6px;
  bottom: 6px;
  display: none;
}
.home-hero__indicator {
  background-color: #CBCBCB;
  width: 100%;
  height: 5px;
  position: absolute;
}
.home-hero__mobile-indicators {
  position: absolute;
  bottom: 5px;
  width: 100%;
  display: flex;
}
.home-hero__mobile-indicator {
  margin: 0 2px;
  flex-grow: 1;
  height: 7px;
  background-color: #D9D9D9;
}
.apply-transitions .home-hero__mobile-indicator {
  transition: all 0.2s ease-in-out;
}
.home-hero__mobile-indicator--active {
  background-color: #103483;
}
.home-hero__indicator-progress {
  background-color: #012169;
  width: 0;
  height: 5px;
  position: absolute;
  visibility: hidden;
}
.home-hero__indicator-progress--active {
  visibility: visible;
  animation-timing-function: linear;
  animation-name: indicator;
  width: 100%;
}
.home-hero__indicator-progress--stopped {
  animation-duration: 0.2s !important;
}
@media (min-width: 400px) {
  .home-hero__tab-title {
    font-size: 1.15rem;
    line-height: 26px;
  }
}
@media (min-width: 40em) {
  .home-hero__tab-link {
    display: block;
    text-align: center;
  }
}
@media (min-width: 56em) {
  .home-hero__pause {
    display: block;
  }
  .home-hero__wrapper {
    height: 825px;
    min-height: 0;
    max-height: calc(100vh - 130px);
    padding: 0;
  }
  .home-hero__slide-gradient {
    background-color: transparent;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 40%, rgba(255, 255, 255, 0) 100%);
  }
  .home-hero__slide-inner {
    display: block;
    max-width: 600px;
  }
  .home-hero__title {
    font-size: 28px;
    line-height: 33px;
  }
  .home-hero__summary {
    color: #333333;
    font-size: 18px;
    line-height: 32px;
    max-width: 560px;
    margin-bottom: 0;
  }
  .home-hero__navigation {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    padding-top: 32px;
  }
  .home-hero__nav-inner {
    min-width: 0;
  }
  .home-hero__tab {
    width: calc(20% - 12px);
    min-height: 0;
    position: relative;
  }
  .home-hero__tab:hover {
    margin-top: -16px;
  }
  .home-hero__tab--active {
    margin-top: -32px;
  }
  .home-hero__tab--active:hover {
    margin-top: -32px;
  }
  .home-hero__tab--active .home-hero__tab-title {
    font-size: 16px;
    font-family: "Montserrat-Bold";
    color: #012169;
  }
  .home-hero__tab-inner {
    padding: 20px 12px;
  }
  .home-hero__tab-image {
    display: none;
  }
  .home-hero__tab-title {
    font-family: "Montserrat-SemiBold";
    color: #767676;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .home-hero__tab-summary {
    display: none;
  }
  .home-hero__tab-link {
    display: none;
  }
  .home-hero__indicator-container {
    display: block;
  }
  .home-hero__mobile-indicators {
    display: none;
  }
}
@media (min-width: 56em) {
  .home-hero__tab--active {
    font-size: 19px;
  }
  .home-hero__tab-title {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 64em) {
  .home-hero__title {
    font-size: 36px;
    line-height: 46px;
  }
}

@keyframes indicator {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@font-face {
  font-family: "icomoon";
  src: url("/assets/public/fonts/icomoon/icomoon.eot?lqm7v6");
  src: url("/assets/public/fonts/icomoon/icomoon.eot?lqm7v6#iefix") format("embedded-opentype"), url("/assets/public/fonts/icomoon/icomoon.ttf?lqm7v6") format("truetype"), url("/assets/public/fonts/icomoon/icomoon.woff?lqm7v6") format("woff"), url("/assets/public/fonts/icomoon/icomoon.svg?lqm7v6#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon:hover {
  text-decoration: none;
}

.icon__vcard:before {
  content: "VCard";
  font-family: "Roboto-Bold";
  font-size: 19px;
}

.icon__hamburger:before {
  content: "\e90d";
}

.icon__close:before {
  content: "\e90c";
}

.icon__download:before {
  content: "\e900";
}

.icon__mail:before {
  content: "\e901";
}

.icon__search:before {
  content: "\e902";
}

.icon__twitter:before {
  content: "\e91e";
  background-color: #012169;
  color: #ffffff;
  padding: 3px;
  border-radius: 2px;
}

.icon__linkedin:before {
  content: "\e904";
  background-color: #012169;
  color: #ffffff;
  padding: 3px;
  border-radius: 2px;
}

.icon__google:before {
  content: "\e90f";
}

.icon__facebook:before {
  content: "\e910";
}

.icon__pdf:before {
  content: "PDF";
  font-family: "Roboto-Bold";
  font-size: 13px;
  background-color: #012169;
  color: #ffffff;
  padding: 4px;
  border-radius: 2px;
}

.icon__share:before {
  content: "\e906";
}

.icon__footer--twitter:before {
  content: "\e91e";
}

.icon__footer--linkedin:before {
  content: "\e908";
}

.icon__footer--facebook:before {
  content: "\e909";
}

.icon__footer--google:before {
  content: "\e90a";
}

.icon__footer--youtube-1:before {
  content: "\e90b";
}

.icon__footer--youtube-2:before {
  content: "\ea9d";
}

.icon__footer--instagram:before {
  content: "\ea92";
}

.icon__dbl-chevron:before {
  content: "\e916";
}

.icon__glasses:before {
  content: "\e91c";
}

.icon__play:before {
  content: "\e918";
}

.icon__pause:before {
  content: "\e919";
}

.image-links__title {
  font-family: "Montserrat-SemiBold";
  color: #333333;
  text-transform: uppercase;
  margin-bottom: 32px;
  letter-spacing: 1.7px;
}
.image-links__locations {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.image-links__location {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 4px 10px 6px rgba(0, 0, 0, 0.06);
  display: block;
  overflow: hidden;
  position: relative;
}
.image-links__location .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.image-links__location .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.image-links__location:hover {
  text-decoration: none;
}
.image-links__location:hover .hover-tab {
  right: 0;
}
.image-links__location:hover {
  text-decoration: none;
}
.image-links__location:hover .image-links__background {
  transform: scale(1.2);
}
.image-links__location:hover .image-links__background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.image-links__container {
  width: 100%;
  overflow: hidden;
}
.image-links__background {
  display: block;
  background-size: cover;
  width: 100%;
  height: 0;
  padding-top: 50%;
  transition: all 0.6s ease-out;
}
.image-links__name {
  display: block;
  padding: 20px;
  font-family: "Montserrat-Bold";
  color: #000000;
  transition: all 0.2s ease-in-out;
  margin: 0;
}
.image-links__name--has-text {
  padding-bottom: 0 !important;
}
.image-links__text {
  padding: 0 20px 5px 20px;
}
.image-links__text .body-copy, .image-links__text .secondary-navigation__select select, .secondary-navigation__select .image-links__text select, .image-links__text .practice-list, .image-links__text .profile-focus__select select, .profile-focus__select .image-links__text select, .image-links__text .people-search__select select, .people-search__select .image-links__text select, .image-links__text .people-search__letter-toggle, .image-links__text .page-nav-mobile__dropdown-list select, .page-nav-mobile__dropdown-list .image-links__text select, .image-links__text .office-card__contact-block, .image-links__text .modal-alert__body, .image-links__text .media-slider__headline, .image-links__text .language-selector__select select, .language-selector__select .image-links__text select, .image-links__text .industry-hero__news-card-summary, .image-links__text .industry-hero, .image-links__text .image-promo__summary, .image-links__text .image-promo__date, .image-links__text p, .image-links__text sub, .image-links__text li, .image-links__text .bio-carousel__summary, .image-links__text .featured-news, .image-links__text .featured-sections__summary, .image-links__text .home-hero {
  font-size: 12px;
}
@media (min-width: 40em) {
  .image-links__locations {
    flex-direction: row;
  }
  .image-links__location {
    width: calc(50% - 20px);
    margin: 0 10px 20px;
  }
  .image-links__name {
    padding: 30px 20px;
  }
  .image-links__text {
    padding-bottom: 12px;
  }
  .image-links__background {
    padding-top: 72%;
  }
}
@media (min-width: 56em) {
  .image-links__location {
    width: calc(25% - 20px);
  }
}

.image-promo {
  overflow: hidden;
  position: relative;
  padding: 20px 15px;
  background-color: #F2F2F2;
  margin-bottom: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.image-promo .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.image-promo .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.image-promo:hover {
  text-decoration: none;
}
.image-promo:hover .hover-tab {
  right: 0;
}
.image-promo__image {
  margin-bottom: 15px;
  display: flex;
  margin: 0 1rem 1rem 0;
  flex: 0 1 auto;
  min-width: 25%;
  margin-right: 50px;
}
.image-promo__image img {
  max-width: 70rem;
}
.image-promo__container {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}
.image-promo__title {
  display: inline;
}
.image-promo__date {
  margin-top: 15px;
}

@media (min-width: 64em) {
  .image-promo {
    flex-direction: row;
    align-items: flex-start;
  }
  .image-promo__image {
    margin-bottom: 30px;
  }
  .image-promo__container--image {
    width: 75%;
  }
  .image-promos {
    display: flex;
    flex-direction: row;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    flex-wrap: wrap;
  }
  .image-promos .image-promo {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    flex-basis: calc(33% - 1rem);
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
}
.industry-cards {
  font-family: "Montserrat";
}
.industry-cards__card {
  background-color: #F2F2F2;
  margin-bottom: 30px;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.industry-cards__card .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.industry-cards__card .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.industry-cards__card:hover {
  text-decoration: none;
}
.industry-cards__card:hover .hover-tab {
  right: 0;
}
.industry-cards__icon {
  margin-bottom: 15px;
}
.industry-cards__image {
  width: 100%;
}
.industry-cards__container {
  padding: 25px 30px 28px;
}
.industry-cards__headline {
  font-family: "Montserrat-SemiBold";
  font-size: 1.15rem;
  line-height: 30px;
  color: #012169;
}
.industry-cards__summary {
  line-height: 22px;
  color: #333333;
  font-family: "Montserrat-Medium";
  margin-top: 10px;
}
.industry-cards__summary * {
  font-family: "Montserrat-Medium";
}
@media (min-width: 40em) {
  .industry-cards__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
}

.industry-hero {
  font-family: "Montserrat";
}
.industry-hero__card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 15px;
  box-shadow: 1px 1px 24px 3px rgba(0, 0, 0, 0.06);
  min-height: 300px;
}
.industry-hero__title {
  font-family: "Montserrat-Bold";
}
.industry-hero__links {
  margin: 20px 0;
}
.industry-hero__links-title {
  font-size: 14px;
  font-family: "Montserrat-SemiBold";
  text-transform: uppercase;
  margin-bottom: 12px;
}
.industry-hero__link {
  display: block;
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 12px;
}
.industry-hero__news-card {
  background-color: #F2F2F2;
  margin-bottom: 30px;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.industry-hero__news-card .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.industry-hero__news-card .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.industry-hero__news-card:hover {
  text-decoration: none;
}
.industry-hero__news-card:hover .hover-tab {
  right: 0;
}
.industry-hero__news-card-image {
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: center;
  background-size: cover;
  height: 0;
  padding-top: 72%;
}
.industry-hero__news-card-container {
  padding: 18px;
}
.industry-hero__news-card-category {
  font-family: "Montserrat-SemiBold";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.7px;
  color: #333333;
}
.industry-hero__news-card-headline {
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  line-height: 26px;
  color: #012169;
  margin-bottom: 18px;
}
@media (min-width: 40em) {
  .industry-hero__card {
    padding: 40px 80px;
  }
  .industry-hero__social-links {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .industry-hero__links {
    margin: 30px 0;
  }
  .industry-hero__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .industry-hero__news-card {
    width: calc(33.3334% - 20px);
    margin-right: 10px;
    margin-left: 10px;
  }
}

.language-selector {
  margin-bottom: 32px;
}
.language-selector__desktop {
  display: none;
}
.language-selector__select {
  background-color: transparent;
  position: relative;
}
.language-selector__select select {
  width: 100%;
  position: relative;
  border: none;
  border-bottom: 2px solid #012169;
  border-radius: 0;
  padding: 6px 30px 6px 0;
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  color: #012169;
  background-color: transparent;
  -webkit-appearance: none;
  z-index: 2;
}
.language-selector__select select:hover {
  cursor: pointer;
}
.language-selector__select::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e917";
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  font-weight: bold;
  color: #012169;
  transform: rotate(90deg);
}
.language-selector__select option:disabled {
  background-color: transparent;
}
.language-selector__select select {
  border: 2px solid #012169;
  padding-left: 24px;
  padding-right: 28px;
}
.language-selector__select::after {
  right: 16px;
  top: 12px;
}
@media (min-width: 56em) {
  .language-selector {
    margin-bottom: 20px;
  }
  .language-selector__mobile {
    display: none;
  }
  .language-selector__desktop {
    display: block;
  }
  .article-columns__right .language-selector__desktop, .practice-columns__right .language-selector__desktop {
    margin-left: -15px;
    font-size: 12px;
  }
  .article-columns__right .language-selector__desktop select, .practice-columns__right .language-selector__desktop select {
    padding-left: 15px;
  }
  .gc-columns_right .language-selector__desktop {
    margin-left: 0;
  }
}
@media print {
  .language-selector {
    display: none;
  }
}

.link-box {
  font-family: "Montserrat";
  font-size: 14px;
  border: 1px solid #CBCBCB;
  border-top: 3px solid #012169;
  padding: 20px;
  margin-bottom: 30px;
}
.link-box__category {
  margin-bottom: 24px;
}
.link-box__category:last-of-type {
  margin-bottom: 0;
}
.link-box__title {
  font-family: "Montserrat-SemiBold";
  line-height: 18px;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 14px;
}
.link-box__link {
  display: block;
  line-height: 22px;
  margin-bottom: 10px;
}

.load-button {
  padding: 12px 24px 12px 48px;
  position: relative;
}
.load-button::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  content: "\e917";
  display: inline-block;
  transform: rotate(90deg);
  left: 24px;
  top: 14px;
}

.view-more-button {
  padding: 12px 36px 12px 24px;
  position: relative;
}
.view-more-button::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 14px;
  right: 18px;
  content: "\e917";
}

.media-slider {
  background-color: #F2F2F2;
  padding: 30px 0 0;
  overflow: hidden;
  font-family: "Montserrat";
}
@media (min-width: 48em) {
  .media-slider--smallgray {
    background-color: transparent;
  }
}
.media-slider__inner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 25px;
  transition: transform 500ms ease-in-out;
}
@media (min-width: 48em) {
  .media-slider__inner--smallgray-1 {
    background-color: #F2F2F2;
    width: 33.3333333333%;
    padding: 2rem 0;
  }
  .media-slider__inner--smallgray-2 {
    background-color: #F2F2F2;
    width: 66.6666666667%;
    padding: 2rem 0;
  }
}
.media-slider__card {
  width: calc(100% - 80px);
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 15px;
  flex: 0 0 auto;
  margin-left: 25px;
  overflow: hidden;
  position: relative;
}
.media-slider__card .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.media-slider__card .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.media-slider__card:hover {
  text-decoration: none;
}
.media-slider__card:hover .hover-tab {
  right: 0;
}
@media (min-width: 40em) {
  .media-slider__card {
    width: calc(50% - 60px);
  }
}
@media (min-width: 64em) {
  .media-slider__card {
    width: calc(33% - 50px);
  }
}
@media (min-width: 48em) {
  .media-slider__card--smallgray-1 {
    width: calc(100% - 3.125rem);
  }
  .media-slider__card--smallgray-2 {
    width: calc(50% - 3.125rem);
  }
}
.media-slider__image {
  margin-bottom: 20px;
}
.media-slider__headline {
  color: #012169;
}
.media-slider__control-row {
  margin: 30px 25px 0;
  display: flex;
  justify-content: space-between;
}
.media-slider__title {
  font-family: "Montserrat-Medium";
}
.media-slider__controls {
  display: flex;
  position: absolute;
  right: 25px;
  bottom: 0;
}
.media-slider__arrow {
  width: 14px;
  height: 50px;
  width: 50px;
  background-color: #ffffff;
  color: #3FBB94;
  font-size: 22.5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 1px;
}
.media-slider__arrow::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e917";
  font-weight: bold;
}
.media-slider__left-arrow {
  transform: scaleX(-1);
}

.modal-alert__mask {
  display: table;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}
.modal-alert__wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-alert__container {
  width: 75%;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.modal-alert__header {
  font-family: "Montserrat-SemiBold";
  font-size: 1.15rem;
  margin-bottom: 18px;
}
.modal-alert__buttons {
  margin-top: 24px;
  text-align: right;
}
.modal-alert__button {
  margin-left: 10px;
}
.modal-alert__decline {
  background-color: #2D8469;
  color: #ffffff;
  border-color: #2D8469;
}
@media (min-width: 48em) {
  .modal-alert__container {
    width: 40%;
  }
}

.navbar {
  width: 100%;
  height: 60px;
  z-index: 100;
  font-family: "Montserrat";
  position: relative;
  background-color: #ffffff;
}
.navbar--nav-locked {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
}
.navbar__filler {
  height: 60px;
  width: 60px;
  display: none;
}
.navbar__filler--show {
  display: block;
}
.navbar__wrapper {
  width: 100%;
}
.navbar__logo {
  display: none;
}
.navbar__logo-mobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-40%);
  width: 140px;
}
.navbar__primary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 22px 0;
}
.navbar__links {
  display: none;
  position: fixed;
  overflow-y: auto;
  top: 50px;
  left: 0;
  z-index: 100;
  width: 100%;
  height: calc(100% - 50px);
  background-color: #ffffff;
  padding: 15px 0;
  box-shadow: 0 10px 12px 3px rgba(0, 0, 0, 0.06);
}
.navbar__links--visible {
  display: block;
}
.navbar__menu {
  visibility: hidden;
  opacity: 0;
  background-color: #ffffff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.navbar__menu--visible {
  opacity: 1;
  visibility: visible;
}
.navbar__item {
  font-family: "Montserrat-SemiBold";
  color: #333333;
  margin-left: 25px;
  margin-bottom: 10px;
  text-align: left;
}
.navbar__item--primary {
  margin-right: 25px;
  line-height: 32px;
}
.navbar__link {
  color: #333333;
  text-decoration: none !important;
  position: relative;
  z-index: 1;
  display: inline-block;
}
.navbar__link:after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background-color: #3FBB94;
  margin-top: -4px;
  transition: width 0.2s ease-in-out;
}
.navbar__link--secondary {
  letter-spacing: 0.54px;
  font-size: 14px;
}
.navbar__link--active:after, .navbar__link:hover:after {
  width: 100%;
}
.navbar__secondary-links::before {
  content: "";
  margin: 15px 25px;
  display: block;
  width: calc(100% - 50px);
  border-top: 1px solid #CBCBCB;
}
.navbar__dropdown-arrow {
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 0;
  overflow: visible;
}
.navbar__dropdown-arrow::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e917";
  display: inline-block;
  transform: rotate(90deg);
  font-size: 10px;
}
.navbar__dropdown {
  display: none;
}
.navbar__hamburger {
  font-size: 12px;
  text-transform: uppercase;
  color: #012169;
  font-family: "Montserrat";
  padding-left: 20px;
  position: relative;
}
.navbar__hamburger:after, .navbar__hamburger:before {
  content: "";
  display: block;
  width: 16px;
  height: 2px;
  background-color: #3FBB94;
  position: absolute;
  left: 0;
}
.navbar__hamburger:before {
  top: 4px;
}
.navbar__hamburger:after {
  top: 10px;
}
.navbar__hamburger--hidden {
  display: none;
}
.navbar__close {
  display: none;
  background: url("/assets/public/images/menu-close.svg") no-repeat center center;
  height: 26px;
  width: 26px;
  margin-top: -6px;
  margin-right: 8px;
}
.navbar__close--visible {
  display: block;
}
.navbar__mobile-search {
  position: absolute;
  font-size: 18px;
  color: #012169;
  left: 20px;
}
.navbar__search {
  display: none;
  color: #333333;
  margin-left: 40px;
}
.navbar__close-search {
  display: none;
  background: url("/assets/public/images/close-button--white.svg") no-repeat center 15px #012169;
  background-size: 18px;
  height: 72px;
  width: 52px;
  position: absolute;
  right: 0;
  z-index: 200;
}
.navbar__close-search--visible {
  display: block;
}
.navbar__mobile-close {
  position: absolute;
  font-size: 18px;
  left: 0;
  top: 0;
  background-position: center 20px;
}
.navbar__search-wrapper {
  min-height: 267px;
  padding: 50px 0 40px;
  background-color: #012169;
  visibility: hidden;
}
.navbar__search-wrapper--visible {
  visibility: visible;
}
.navbar__search-filters {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.navbar__search-filter-wrapper {
  width: 50%;
  position: relative;
  display: inline-block;
  padding-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.navbar__search-filter-wrapper:last-child {
  margin-right: 0;
}
.navbar__search-title {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.navbar__search-filter-button {
  display: block;
  position: absolute;
  top: 3px;
  left: 0;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  border: 2px solid #ffffff;
}
.navbar__search-filter-option:checked + .navbar__search-filter-button:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #ffffff;
}
.navbar__search-filter-label {
  vertical-align: text-top;
}
.navbar__search-input-wrapper {
  position: relative;
  margin-top: 20px;
}
.navbar__search-input {
  background-color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
.navbar__search-input-icon {
  background: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 12px;
}
.navbar__search-input-icon::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e902";
  color: #4A4A4A;
  font-size: 18px;
  line-height: 24px;
}
.navbar__mobile-hidden {
  display: none;
}
.navbar__home-h1 {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: 1;
}
@media (min-width: 40em) {
  .navbar__logo-mobile {
    width: 200px;
  }
}
@media (min-width: 56em) {
  .navbar {
    height: 100px;
  }
  .navbar__mobile-hidden {
    display: block;
  }
  .navbar__mobile-only {
    display: none;
  }
  .navbar__filler {
    height: 92px;
  }
  .navbar__logo {
    display: block;
  }
  .navbar__logo-link {
    display: block;
    width: 250px;
  }
  .navbar__logo-mobile {
    display: none;
  }
  .navbar__search {
    display: block;
  }
  .navbar__close-search {
    height: 52px;
  }
  .navbar__links {
    display: block;
    position: static;
    padding: 0;
    box-shadow: none;
    overflow-y: visible;
    width: auto;
  }
  .navbar__primary {
    padding: 42px 0 6px;
  }
  .navbar__primary-links {
    display: flex;
    flex-direction: row;
  }
  .navbar__item--primary {
    flex-shrink: 0;
    margin-left: 25px;
    margin-right: 0;
    margin-bottom: 0;
  }
  .navbar__item--secondary {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 0;
  }
  .navbar__item--has-dropdown {
    position: relative;
    cursor: pointer;
  }
  .navbar__item--has-dropdown:before {
    display: block;
    content: " ";
    position: absolute;
    left: 100%;
    width: 1em;
    height: 120%;
    top: 0;
  }
  .navbar__item--active:after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: #3FBB94;
    margin-top: -4px;
    transition: width 0.2s ease-in-out;
  }
  .navbar__item--active:after {
    width: 100%;
  }
  .navbar__item--dropdown-item {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 8px;
  }
  .navbar__item--dropdown-item:last-of-type {
    margin-bottom: 5px;
  }
  .navbar__item--dropdown-open .navbar__dropdown, .navbar__item--has-dropdown:hover .navbar__dropdown {
    display: block;
  }
  .navbar__item--dropdown-open .navbar__dropdown-arrow {
    transform: rotate(180deg);
  }
  .navbar__link {
    display: block;
  }
  .navbar__link--dropdown-item {
    display: inline-block;
  }
  .navbar__link--secondary {
    font-size: 13px;
  }
  .navbar__secondary-links {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 20px;
    top: 8px;
  }
  .navbar__secondary-links:before {
    display: none;
  }
  .navbar__dropdown {
    display: none;
    padding-top: 20px;
    position: absolute;
    cursor: auto;
    right: -40px;
    top: 100%;
  }
  .navbar__dropdown-inner {
    min-width: 260px;
    background-color: #ffffff;
    padding: 15px;
    box-shadow: 0 2px 4px 2px rgba(189, 189, 189, 0.5);
    z-index: 1000;
    position: relative;
  }
  .navbar__dropdown-inner:before {
    content: "";
    height: 0;
    width: 0;
    border-bottom: 14px solid rgba(189, 189, 189, 0.1);
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    position: absolute;
    bottom: 100%;
    right: 44px;
  }
  .navbar__dropdown-inner:after {
    content: "";
    height: 0;
    width: 0;
    border-bottom: 10px solid #ffffff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    bottom: 100%;
    right: 48px;
    margin-top: 2px;
  }
  .navbar__icons {
    display: none;
  }
  .navbar__dropdown-arrow {
    right: -14px;
    transition: transform 0.1s ease;
    transition-delay: 0.1s;
  }
  .navbar__search-filter-wrapper {
    width: auto;
    padding: 0;
    margin-right: 30px;
  }
  .navbar__search-input-wrapper {
    margin-top: 25px;
  }
}
@media (min-width: 64em) {
  .navbar__item--primary {
    margin-left: 40px;
  }
}
@media (min-width: 74em) {
  .navbar__item--primary {
    margin-left: 50px;
  }
}
@media print {
  .navbar {
    display: none;
  }
}

.news-cards {
  font-family: "Montserrat";
}
.news-cards__toggle-spacer {
  margin-top: 40px;
  position: relative;
  height: 40px;
}
.news-cards__toggles {
  float: right;
  margin-top: -20px;
  display: flex;
}
.news-cards__card-toggle {
  height: 18px;
  width: 18px;
  position: relative;
}
.news-cards__card-toggle--active .news-cards__card-icon {
  background-color: #012169;
}
.news-cards__card-icon {
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: #9B9B9B;
}
.news-cards__card-icon.topleft {
  top: 0;
  left: 0;
}
.news-cards__card-icon.topright {
  top: 0;
  right: 0;
}
.news-cards__card-icon.botleft {
  bottom: 0;
  left: 0;
}
.news-cards__card-icon.botright {
  bottom: 0;
  right: 0;
}
.news-cards__list-toggle {
  height: 18px;
  width: 18px;
  position: relative;
  margin-left: 18px;
}
.news-cards__list-toggle--active .news-cards__list-icon {
  background-color: #012169;
}
.news-cards__list-icon {
  width: 100%;
  height: 2px;
  background-color: #9B9B9B;
  display: block;
  margin-bottom: 6px;
}
.news-cards__list-icon:last-of-type {
  margin-bottom: 0;
}
.news-cards__list {
  margin-bottom: 30px;
  border-top: 1px solid #CBCBCB;
}
.news-cards__cards {
  padding-top: 10px;
}
.news-cards__card {
  background-color: #F2F2F2;
  margin-bottom: 30px;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}
.news-cards__card .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.news-cards__card .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.news-cards__card:hover {
  text-decoration: none;
}
.news-cards__card:hover .hover-tab {
  right: 0;
}
.news-cards__card:hover .news-cards__reading-time {
  opacity: 0;
}
.news-cards__container {
  padding: 18px;
}
.news-cards__icon {
  height: auto;
  max-width: 45px;
  max-height: 45px;
  margin-bottom: 5px;
}
.news-cards__image {
  width: 100%;
}
.news-cards__headline {
  font-family: "Montserrat-SemiBold";
  font-size: 18px;
  line-height: 26px;
  color: #012169;
}
.news-cards__card--no-link .news-cards__headline {
  color: #333333;
}
.news-cards__headline ~ div {
  margin-top: 15px;
}
.news-cards__subhead {
  font-family: "Montserrat-SemiBold";
  font-size: 14px;
  line-height: 20px;
  color: #012169;
}
.news-cards__card--no-link .news-cards__subhead {
  color: #333333;
}
.news-cards__meta-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  bottom: 15px;
  left: 0;
  width: 100%;
}
.news-cards__headline + .news-cards__meta-info {
  margin-top: 30px;
}
.news-cards__date {
  font-family: "Montserrat-SemiBold";
  color: #333333;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1.7px;
}
.news-cards__reading-time {
  color: #4A4A4A;
  font-size: 14px;
  padding-left: 32px;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.news-cards__reading-time:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e91c";
  color: #4A4A4A;
  display: block;
  position: absolute;
  left: 0;
  top: 11px;
  font-size: 11px;
}
.news-cards__controls {
  text-align: center;
}
@media (min-width: 40em) {
  .news-cards__cards {
    break-inside: avoid;
  }
  .news-cards__toggles {
    position: absolute;
    margin-top: 0;
    top: 9px;
    right: 0;
  }
}

.office-card {
  font-family: "Montserrat";
}
.office-card__card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 15px;
  box-shadow: 1px 1px 24px 3px rgba(0, 0, 0, 0.06);
}
.office-card__info--left {
  margin-bottom: 20px;
}
.office-card__name {
  font-family: "Montserrat-Medium";
}
.office-card__addresses {
  display: flex;
  flex-direction: column;
}
.office-card__address {
  flex: 1;
}
.office-card__address:first-child {
  margin-right: 0px;
  margin-bottom: 20px;
}
.office-card__address-link {
  line-height: 24px;
}
.office-card__phone-block {
  margin-top: 20px;
}
.office-card__phone {
  display: block;
}
.office-card__contact-block {
  margin-bottom: 20px;
}
.office-card__title {
  font-family: "Montserrat-SemiBold";
}
.office-card__professionals-link {
  margin: 0;
}
.office-card__rte {
  margin-top: 20px;
}
@media (min-width: 40em) {
  .office-card__addresses {
    display: flex;
    flex-direction: row;
  }
  .office-card__address:first-child {
    margin-right: 20px;
    margin-bottom: 0px;
  }
  .office-card__card {
    flex-direction: row;
    min-height: 150px;
    padding: 50px 20px;
  }
  .office-card__info--left {
    width: 60%;
    flex-grow: 1;
    margin-bottom: 0;
  }
  .office-card__info--right {
    width: 40%;
    padding-left: 25px;
    border-left: 1px solid #9B9B9B;
  }
}
@media (min-width: 56em) {
  .office-card__card {
    padding: 50px 100px;
  }
}

.office-columns__right {
  margin-bottom: 50px;
}
@media (min-width: 40em) {
  .office-columns {
    display: flex;
    flex-direction: row;
  }
  .office-columns__left {
    width: 100%;
    padding-left: 10%;
    position: relative;
    padding-right: 30%;
  }
}

.office-list {
  background-color: #012169;
  padding: 50px 0;
  color: #ffffff;
  font-family: "Montserrat";
}
.office-list__region-title {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 1.7px;
}
.office-list__subtitle {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 20px;
}
.office-list__office {
  color: #ffffff;
  display: block;
  line-height: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.office-list__international-section {
  margin-top: 20px;
}
@media (min-width: 40em) {
  .office-list__region-inner {
    column-count: 4;
  }
  .office-list__subtitle {
    margin-top: 40px;
  }
  .office-list__office, .office-list__subtitle {
    padding-right: 1em;
  }
  .office-list__international-section {
    margin-top: 30px;
  }
}
@media (min-width: 64em) {
  .office-list__office-grid {
    display: flex;
    justify-content: space-between;
  }
  .office-list__international-section {
    margin-top: 0;
  }
}

.page-hero {
  margin-top: 32px;
  border-top: 5px solid #012169;
  padding: 30px 0;
  background-size: cover;
  min-height: 180px;
  position: relative;
}
.page-hero:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.25);
}
.page-hero .container {
  z-index: 2;
}
.page-hero:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  height: 100%;
  z-index: 1;
}
.page-hero--no-filter:before {
  display: none;
}
.page-hero--overlap {
  padding-bottom: 80px;
}
.page-hero--small-overlap {
  padding-bottom: 40px;
}
.page-hero--tall {
  min-height: 350px;
}
.page-hero--full-width-image {
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 34vw;
  min-height: 34vw;
  padding: 0;
}
.page-hero__title {
  margin-bottom: 12px;
  font-family: "Montserrat-Bold";
}
.page-hero--has-navigation {
  display: flex;
  flex-direction: column-reverse;
}
.page-hero--has-navigation .tertiary-navigation {
  margin-bottom: 0;
}
.page-hero--has-navigation .page-hero__title {
  margin-bottom: 30px;
}
@media (min-width: 40em) {
  .page-hero {
    padding: 100px 0;
    min-height: 400px;
  }
  .page-hero--full-width-image {
    height: 34vw;
    min-height: 34vw;
    padding: 0;
  }
  .page-hero--overlap, .page-hero--tall {
    padding: 100px 0 250px;
  }
  .page-hero--small-overlap {
    padding-bottom: 160px;
  }
  .page-hero__title {
    margin-bottom: 30px;
  }
  .page-hero__subtitle {
    max-width: 70%;
  }
  .page-hero__subtitle a {
    text-decoration: underline;
  }
  .page-hero--has-navigation .page-hero__subtitle {
    max-width: none;
  }
}
@media (min-width: 56em) {
  .page-hero {
    flex-direction: row;
  }
  .page-hero__navigation-container {
    width: 33%;
    padding-top: 10px;
    margin-right: 30px;
  }
}
@media (min-width: 74em) {
  .page-hero--full-width-image {
    height: 400px;
    min-height: 400px;
  }
}

.abstract-hero {
  height: 375px;
  width: 100%;
  background-size: cover;
  background-color: #012169;
}

.page-nav {
  display: none;
  position: absolute;
  left: 20px;
  top: 0;
  max-width: 250px;
  padding: 20px 25px;
  font-family: "Montserrat";
}
.social-icons__block + .page-nav:not(.page-nav--nav-locked) {
  top: 48px;
}
.page-nav__scroll-link {
  margin-bottom: 22px;
}
.page-nav__scroll-link:last-of-type {
  margin-bottom: 0;
}
.page-nav__link {
  display: block;
  color: #000000;
  line-height: 26px;
  text-align: left;
}
.page-nav__link--active {
  font-family: "Montserrat-SemiBold";
  margin-right: -5px;
  color: #012169;
}
.page-nav__link--active:before {
  content: "";
  position: absolute;
  display: block;
  height: 35px;
  width: 3px;
  left: 13px;
  background-color: #012169;
}
.page-nav__link:hover {
  text-decoration: none;
  font-family: "Montserrat-SemiBold";
  margin-right: -5px;
}
.page-nav__bio-section {
  display: none;
}
@media (min-width: 56em) {
  .page-nav {
    display: block;
    transition: box-shadow 0.6s ease-in-out;
  }
  .page-nav__bio-section {
    display: block;
    visibility: hidden;
    max-height: 0;
    border-bottom: 1px solid #CBCBCB;
    margin-bottom: 15px;
    text-align: center;
    transition: max-height 0.6s ease-in-out, visibility 0.6s ease-in-out;
    overflow: hidden;
  }
  .page-nav__bio-image {
    margin-bottom: 15px;
  }
  .page-nav__bio-name {
    font-size: 18px;
    font-family: "Montserrat-Bold";
    color: #1C273E;
    margin-bottom: 15px;
  }
  .page-nav__bio-phone {
    color: #012169;
    font-family: "Montserrat-SemiBold";
    margin-bottom: 15px;
  }
  .page-nav--nav-locked {
    position: fixed;
    background-color: #ffffff;
    top: 110px;
    left: 20px;
  }
  .page-nav--drawer-open {
    box-shadow: 1px 1px 24px 3px rgba(0, 0, 0, 0.06);
  }
  .page-nav--drawer-open .page-nav__bio-section {
    max-height: 315px;
    visibility: visible;
  }
  .page-nav--bottom-locked {
    position: absolute;
    top: initial;
    bottom: 30px;
    left: 20px;
  }
}
@media (min-width: 56em) and (max-height: 780px) {
  .page-nav__bio-image {
    max-height: 160px;
  }
  .page-nav--drawer-open .page-nav__bio-section {
    max-height: 250px;
  }
}
@media (min-width: 56em) and (max-height: 680px) {
  .page-nav--drawer-open .page-nav__bio-section {
    max-height: 0;
    visibility: hidden;
  }
}
@media (min-width: 74em) {
  .page-nav--nav-locked {
    left: calc((100% - 1336px) / 2 + 20px);
  }
  .container--small .page-nav--nav-locked {
    left: calc((100% - 1184px) / 2 + 20px);
  }
  .page-nav--bottom-locked {
    left: 20px;
  }
}

.page-nav-mobile {
  position: absolute;
  z-index: 2;
  top: 70px;
  left: 20px;
  width: calc(100% - 40px);
}
.page-nav-mobile__dropdown-list {
  background-color: transparent;
  position: relative;
}
.page-nav-mobile__dropdown-list select {
  width: 100%;
  position: relative;
  border: none;
  border-bottom: 2px solid #012169;
  border-radius: 0;
  padding: 6px 30px 6px 0;
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  color: #012169;
  background-color: transparent;
  -webkit-appearance: none;
  z-index: 2;
}
.page-nav-mobile__dropdown-list select:hover {
  cursor: pointer;
}
.page-nav-mobile__dropdown-list::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e917";
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  font-weight: bold;
  color: #012169;
  transform: rotate(90deg);
}
.page-nav-mobile__dropdown-list option:disabled {
  background-color: transparent;
}
.page-nav-mobile--dark-bg select {
  color: #ffffff;
  border-color: #ffffff;
  font-family: "Montserrat-Medium";
}
.page-nav-mobile--dark-bg .page-nav-mobile__dropdown-list::after {
  color: #ffffff;
}
@media (min-width: 56em) {
  .page-nav-mobile {
    display: none;
  }
}

.pagination {
  float: right;
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.pagination__arrow {
  width: 14px;
  height: 22px;
  color: #3FBB94;
  font-size: 22px;
}
.pagination__arrow::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e917";
  font-weight: bold;
}
.pagination__arrow--inactive {
  color: #9B9B9B;
}
.pagination__prev {
  transform: rotate(180deg);
  margin-right: 20px;
}
.pagination__next {
  margin-left: 12px;
}
.pagination__page-list-wrapper {
  position: relative;
}
.pagination__page-list-toggle {
  color: #9B9B9B;
  transform: rotate(270deg);
  font-size: 11px;
  margin-left: 12px;
}
.pagination__page-list-toggle::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e917";
  font-weight: bold;
}
.pagination__page-count {
  color: #012169;
  font-family: "Montserrat-SemiBold";
}
.pagination__page-list {
  position: absolute;
  bottom: 30px;
  right: -54px;
  width: 146px;
  height: 160px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 2px rgba(189, 189, 189, 0.5);
  z-index: 1000;
}
.pagination__page-list:before {
  content: "";
  height: 0;
  width: 0;
  border-top: 14px solid rgba(189, 189, 189, 0.1);
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  position: absolute;
  top: 100%;
  right: 44px;
}
.pagination__page-list:after {
  content: "";
  height: 0;
  width: 0;
  border-top: 10px solid #ffffff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: 99%;
  right: 48px;
  margin-top: 2px;
}
.pagination__page-list-inner {
  height: 100%;
  width: 100%;
  padding: 15px;
  overflow-y: auto;
  overflow-x: hidden;
}
.pagination__page-link {
  width: 100%;
  border-bottom: 1px solid #CBCBCB;
  color: #012169;
  font-family: "Montserrat-SemiBold";
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.pagination__page-link--current {
  color: #333333;
  font-family: "Montserrat-Medium";
}
.pagination__page-link:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.people-card__card {
  margin-bottom: 30px;
  border: 1px solid #CBCBCB;
  border-top: 3px solid #012169;
  padding: 20px;
}
.people-card__image {
  width: 86px;
  margin-bottom: 20px;
}
.people-card__image img {
  border-radius: 50%;
}
.people-card__name {
  font-size: 1.15rem;
  line-height: 20px;
  font-family: "Montserrat-SemiBold";
  margin-bottom: 8px;
  display: block;
  letter-spacing: 0.26px;
}
.people-card__title {
  font-size: 14px;
  line-height: 18px;
  font-family: "Montserrat-SemiBold";
  margin-bottom: 14px;
}
.people-card__email {
  font-size: 14px;
  font-family: "Montserrat-SemiBold";
  word-wrap: break-word;
}
.people-card__city {
  font-size: 14px;
  font-family: "Montserrat-Medium";
}
.people-card__city:not(:first-child) {
  margin-top: 0.5rem;
}
.people-card__city-name {
  margin-right: 15px;
  display: inline-block;
}
.people-card__city-name, .people-card__office-phone {
  line-height: 20px;
  display: block;
}
@media (min-width: 40em) {
  .people-card__person-container {
    width: calc(50% - 30px);
    margin-right: 15px;
    margin-left: 15px;
  }
  .people-card__card {
    display: flex;
    flex-direction: row;
  }
  .people-card__image {
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.people-search {
  font-family: "Montserrat";
}
.people-search__card {
  background-color: #ffffff;
  padding: 15px;
  box-shadow: 1px 1px 24px 3px rgba(0, 0, 0, 0.06);
}
.people-search__label {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  text-transform: uppercase;
}
.people-search__keyword-search {
  margin-bottom: 30px;
  position: relative;
}
.people-search__keyword-search--no-margin {
  margin-bottom: 0;
}
.people-search__keyword-search input {
  width: 100%;
  box-sizing: border-box;
  padding-right: 32px;
}
.people-search__search-icon {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 12px;
}
.people-search__search-icon::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e902";
  color: #4A4A4A;
  font-size: 18px;
  line-height: 24px;
}
.people-search__filter-container {
  padding: 30px 0 0;
}
.people-search__letter-toggle {
  width: 100%;
  position: relative;
  border: none;
  border-bottom: 2px solid #012169;
  border-radius: 0;
  padding: 6px 30px 6px 0;
  font-family: "Montserrat-SemiBold";
  color: #012169;
  background-color: transparent;
  -webkit-appearance: none;
  z-index: 2;
  margin-bottom: 30px;
}
.people-search__letter-toggle:hover {
  cursor: pointer;
}
.people-search__letter-toggle::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e917";
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  font-weight: bold;
  color: #012169;
  transform: rotate(90deg);
}
.people-search__letter-wrapper {
  position: relative;
}
.people-search__letters {
  font-size: 18px;
  color: #333333;
  padding: 15px 0;
  margin-bottom: 30px;
  background-color: #ffffff;
  border: 2px solid #012169;
  border-top: none;
  position: absolute;
  width: 100%;
  opacity: 0;
  top: 20%;
  z-index: 10;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out, visibility 0.5s ease-in-out;
}
.people-search__letters--open {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.people-search__letters span {
  width: 16.667%;
  text-align: center;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  padding: 4px 3px;
}
.people-search__letters span:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  margin-top: 2px;
  background-color: #3FBB94;
  transition: width 0.2s ease-in-out;
}
.people-search__letters span:hover {
  cursor: pointer;
}
.people-search__letters span:hover:after {
  width: 100%;
}
.people-search__select-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -10px;
  margin-right: -10px;
}
.people-search__select {
  background-color: transparent;
  position: relative;
}
.people-search__select select {
  width: 100%;
  position: relative;
  border: none;
  border-bottom: 2px solid #012169;
  border-radius: 0;
  padding: 6px 30px 6px 0;
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  color: #012169;
  background-color: transparent;
  -webkit-appearance: none;
  z-index: 2;
}
.people-search__select select:hover {
  cursor: pointer;
}
.people-search__select::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e917";
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  font-weight: bold;
  color: #012169;
  transform: rotate(90deg);
}
.people-search__select option:disabled {
  background-color: transparent;
}
.people-search__advance-filter {
  width: calc(100% - 10px);
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 30px;
}
.people-search__results {
  padding: 80px 0 20px;
}
.people-search__active-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.people-search__active-filter {
  border: 2px solid #012169;
  border-radius: 16px;
  font-family: "Montserrat-SemiBold";
  color: #012169;
  padding: 6px 20px;
  margin-right: 12px;
  margin-bottom: 12px;
}
.people-search__active-filter:before {
  content: "";
  display: inline-block;
  height: 12px;
  width: 10px;
  background: url("/assets/public/images/close-button.svg") no-repeat center center;
  background-size: 10px;
  margin-right: 6px;
}
.people-search__clear-all {
  color: #9B9B9B;
  margin-top: 20px;
}
.people-search__clear-all:hover {
  text-decoration: underline;
  color: #4A4A4A;
}
.people-search__results-container {
  margin-top: 40px;
  position: relative;
}
.people-search__results-header {
  text-transform: uppercase;
  font-family: "Montserrat-Medium";
  border-bottom: 1px solid #CBCBCB;
  padding-bottom: 18px;
  letter-spacing: 2px;
}
.people-search__people-container {
  margin-top: 30px;
}
.people-search__person-container {
  margin-bottom: 30px;
  border: 1px solid #CBCBCB;
  border-top: 3px solid #012169;
  padding: 20px;
}
.people-search__image {
  width: 86px;
  margin-bottom: 20px;
}
.people-search__image img {
  border-radius: 50%;
}
.people-search__name {
  font-size: 1.15rem;
  line-height: 20px;
  font-family: "Montserrat-SemiBold";
  margin-bottom: 8px;
  display: block;
  letter-spacing: 0.26px;
}
.people-search__first-name::after, .people-search__middle-name::after {
  content: " ";
}
.people-search__title {
  font-size: 14px;
  line-height: 18px;
  font-family: "Montserrat-SemiBold";
  margin-bottom: 14px;
}
.people-search__email {
  font-size: 14px;
  font-family: "Montserrat-SemiBold";
  padding-left: 25px;
  word-wrap: break-word;
  background: url("/assets/public/images/mail-icon.svg") no-repeat left center;
}
.people-search__city {
  font-size: 14px;
  font-family: "Montserrat-Medium";
  padding-left: 25px;
  background: url("/assets/public/images/phone-icon.svg") no-repeat left center;
}
.people-search__city-name {
  margin-right: 15px;
  display: inline-block;
}
.people-search__city-name, .people-search__office-phone {
  line-height: 26px;
  display: block;
}
@media (min-width: 40em) {
  .people-search__card {
    min-height: 150px;
    padding: 50px 100px;
  }
  .people-search__keyword-search input {
    padding-right: 38px;
  }
  .people-search__search-icon {
    top: 8px;
  }
  .people-search__search-icon::before {
    font-size: 24px;
    line-height: 34px;
  }
  .people-search__letters {
    padding: 0;
    border: none;
    position: relative;
    background-color: transparent;
    opacity: 1;
    visibility: visible;
  }
  .people-search__letters span {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 5px;
    width: auto;
    text-align: left;
  }
  .people-search__letter-toggle {
    display: none;
  }
  .people-search__advance-filter {
    width: calc(50% - 20px);
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 30px;
  }
  .people-search__advance-filter--secondary {
    width: calc(33% - 17px);
  }
  .people-search__advance-filter--secondary:last-child {
    margin-right: 0;
  }
  .people-search__results-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .people-search__clear-all {
    margin-top: 0;
  }
  .people-search__people-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.filter-search__filter-title {
  margin-bottom: 15px;
  font-size: 14px;
  font-family: "Montserrat-SemiBold";
  text-transform: uppercase;
  letter-spacing: 1.75px;
}
.filter-search__button {
  display: inline-block;
  line-height: 1.1;
  text-align: center;
  color: #012169;
  font-family: "Montserrat-SemiBold";
  border: 2px solid #012169;
  padding: 10px 30px;
  transition: all 0.2s ease-in-out;
  font-size: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  border: none;
  background-color: #F2F2F2;
  color: #4A4A4A;
}
.filter-search__button:hover, .filter-search__button--loading {
  text-decoration: none;
  background-color: #3FBB94;
  border-color: #3FBB94;
  color: #ffffff;
}
.filter-search__button--loading {
  cursor: wait;
}
.filter-search__button--active {
  background-color: #2D8469;
  color: #ffffff;
}
.filter-search__button--loading {
  cursor: wait;
}
.filter-search__count {
  position: absolute;
  right: 0;
  top: 0;
}

div[is=people-landing],
div[is=insight-landing],
div[is=event-landing],
div[is=news-landing] {
  min-height: 100vh;
}

.practice-hero {
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 16px;
  padding-top: 50px;
}
.practice-hero__image-col {
  margin-top: 20px;
  display: none;
}
@media (min-width: 56em) {
  .practice-hero {
    padding-bottom: 50px;
  }
  .practice-hero__wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .practice-hero__info-col {
    padding-right: 60px;
    width: 60%;
  }
  .practice-hero__image-col {
    display: block;
  }
  .practice-hero--no-image {
    margin-bottom: 50px;
  }
  .practice-hero--no-image .practice-hero__info-col {
    width: 100%;
    padding: 0;
  }
  .practice-hero__image-col {
    width: 40%;
    margin-top: 0;
  }
  .practice-hero__title {
    font-size: 40px;
    line-height: 46px;
  }
}

.profile-focus {
  margin-bottom: 32px;
}
.profile-focus__select {
  background-color: transparent;
  position: relative;
}
.profile-focus__select select {
  width: 100%;
  position: relative;
  border: none;
  border-bottom: 2px solid #012169;
  border-radius: 0;
  padding: 6px 30px 6px 0;
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  color: #012169;
  background-color: transparent;
  -webkit-appearance: none;
  z-index: 2;
}
.profile-focus__select select:hover {
  cursor: pointer;
}
.profile-focus__select::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e917";
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  font-weight: bold;
  color: #012169;
  transform: rotate(90deg);
}
.profile-focus__select option:disabled {
  background-color: transparent;
}
.profile-focus__select select {
  border: 2px solid #012169;
  padding-left: 24px;
  padding-right: 38px;
}
.profile-focus__select::after {
  right: 16px;
  top: 12px;
}
@media (min-width: 40em) {
  .profile-focus {
    margin-bottom: 0;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
  }
  .profile-focus__select {
    max-width: 350px;
  }
}
@media print {
  .profile-focus {
    display: none;
  }
}

.practice-columns__center .section__list {
  padding-top: 38px;
  border-top: 4px solid #012169;
}
@media (min-width: 56em) {
  .practice-columns {
    display: flex;
    flex-direction: row;
  }
  .practice-columns__left {
    width: 25%;
    padding-right: 5%;
    position: relative;
  }
  .practice-columns__center {
    width: 60%;
    padding-right: 5%;
  }
  .practice-columns__right {
    width: 20%;
  }
}
.practice-columns__left {
  margin-bottom: 72px;
}
.practice-columns__left .page-nav {
  left: 0;
  max-width: 210px;
}
.practice-columns__left .page-nav--nav-locked {
  left: 20px;
}
.practice-columns__left .page-nav--bottom-locked {
  left: 0;
}
@media (min-width: 64em) {
  .practice-columns__left .page-nav {
    max-width: 230px;
  }
  .practice-columns__center {
    width: 60%;
    padding-right: 5%;
  }
}
@media (min-width: 74em) {
  .practice-columns__left .page-nav {
    max-width: 250px;
  }
}
@media (min-width: 86em) {
  .practice-columns__left .page-nav--nav-locked {
    left: calc((100% - 1376px) / 2 + 20px);
  }
  .practice-columns__left .page-nav--bottom-locked {
    left: 0;
  }
}

.practice-list {
  font-family: "Montserrat";
  margin-bottom: 30px;
}
.practice-list__toggle-container {
  text-align: center;
  margin-bottom: 40px;
}
.practice-list__toggle {
  color: #012169;
  border-right: 1px solid #333333;
  font-family: "Montserrat-SemiBold";
  font-size: 14px;
  line-height: 30px;
  padding: 0 12px;
}
.practice-list__toggle:last-of-type {
  border: none;
  padding-right: 0;
}
.practice-list__toggle:first-of-type {
  padding-left: 0;
}
.practice-list__toggle--active {
  color: #333333;
}
.practice-list__section-title {
  font-size: 14px;
  font-family: "Montserrat-SemiBold";
  text-transform: uppercase;
  letter-spacing: 1.75px;
  border-bottom: 1px solid #CBCBCB;
  padding-bottom: 12px;
}
.practice-list__category-section .practice-list__link {
  display: none;
}
.practice-list__category-section .practice-list__link--open {
  display: block;
}
.practice-list__accordion-toggle {
  position: absolute;
  right: 10px;
  top: -38px;
  font-family: "Montserrat-SemiBold";
  color: #012169;
}
.practice-list__category-accordion {
  position: relative;
}
.practice-list__link {
  display: block;
  padding-bottom: 12px;
  line-height: 24px;
}
.practice-list__link-inner {
  padding-left: 15px;
}
.practice-list__letter {
  font-family: "Garamond-Bold";
  font-size: 28px;
  margin-bottom: 25px;
}
.practice-list__letter-section {
  border-bottom: 1px solid #CBCBCB;
  padding: 20px 0;
}
@media (min-width: 40em) {
  .practice-list {
    margin-bottom: 60px;
  }
  .practice-list__toggle {
    padding: 0 16px;
    font-size: 16px;
  }
  .practice-list__category-accordion {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .practice-list__category-accordion--column {
    width: calc(50% - 20px);
    margin-right: 10px;
    margin-left: 10px;
  }
  .practice-list__alpha-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .practice-list__letter-section {
    padding: 30px 30px 30px 0;
    width: 33.3333%;
  }
}

.practice-search {
  font-family: "Montserrat";
}
.practice-search__card {
  background-color: #ffffff;
  padding: 15px;
  box-shadow: 1px 1px 24px 3px rgba(0, 0, 0, 0.06);
}
.practice-search__label {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  text-transform: uppercase;
}
.practice-search__keyword-search {
  position: relative;
}
.practice-search__keyword-search input {
  width: 100%;
  color: red;
  padding-right: 32px;
}
.practice-search__search-icon {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 12px;
}
.practice-search__search-icon::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e902";
  color: #4A4A4A;
  font-size: 18px;
  line-height: 24px;
}
.practice-search__results {
  padding: 80px 0 20px;
}
.practice-search__active-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.practice-search__active-filter {
  border: 2px solid #012169;
  border-radius: 16px;
  font-family: "Montserrat-SemiBold";
  color: #012169;
  padding: 6px 20px;
  margin-right: 12px;
  margin-bottom: 12px;
}
.practice-search__active-filter:before {
  content: "";
  display: inline-block;
  height: 12px;
  width: 10px;
  background: url("/assets/public/images/close-button.svg") no-repeat center center;
  background-size: 10px;
  margin-right: 6px;
}
.practice-search__clear-all {
  color: #9B9B9B;
  margin-top: 20px;
}
.practice-search__clear-all:hover {
  text-decoration: underline;
  color: #4A4A4A;
}
.practice-search__results-container {
  margin-top: 40px;
  position: relative;
}
.practice-search__results-header {
  text-transform: uppercase;
  font-family: "Montserrat-Medium";
  border-bottom: 1px solid #CBCBCB;
  padding-bottom: 18px;
  letter-spacing: 2px;
}
@media (min-width: 40em) {
  .practice-search__card {
    min-height: 150px;
    padding: 50px 100px;
  }
  .practice-search__keyword-search input {
    padding-right: 38px;
  }
  .practice-search__search-icon {
    top: 8px;
  }
  .practice-search__search-icon::before {
    font-size: 24px;
    line-height: 34px;
  }
  .practice-search__results-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .practice-search__clear-all {
    margin-top: 0;
  }
}

.promo {
  border-top: 5px solid #012169;
  border-bottom: 1px solid #9B9B9B;
  padding: 15px 5px 20px;
  margin: 20px 0;
  font-size: 1.15rem;
  line-height: 26px;
  font-family: "Montserrat-Medium";
  width: 100%;
}
@media print {
  .promo {
    display: none;
  }
}
.promo a {
  display: inline-block;
  line-height: 1.1;
  text-align: center;
  color: #012169;
  font-family: "Montserrat-SemiBold";
  border: 2px solid #012169;
  padding: 10px 30px;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  padding: 5px 50px;
  margin-top: 15px;
}
.promo a:hover, .promo a--loading {
  text-decoration: none;
  background-color: #3FBB94;
  border-color: #3FBB94;
  color: #ffffff;
}
.promo a--loading {
  cursor: wait;
}
@media (min-width: 40em) {
  .promo {
    float: left;
    margin: 30px 50px 30px 0;
    width: 280px;
  }
}
@media (min-width: 56em) {
  .promo {
    margin-left: -85px;
  }
}
.promo-module {
  text-align: center;
  margin: 0;
}
.promo-module span {
  display: block;
}
@media (min-width: 40em) {
  .promo-module {
    float: none;
    width: 100%;
  }
}
@media (min-width: 56em) {
  .promo-module {
    margin-left: 0;
  }
}

.blog-promo {
  display: block;
  background-color: #F2F2F2;
  text-align: center;
  padding: 20px;
  color: #012169;
}
.blog-promo__icon {
  border: 2px solid #3FBB94;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  margin: 0 auto;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.blog-promo__icon:after {
  background: url("/assets/public/images/outgoing.svg") no-repeat 50% #3FBB94;
  border-radius: 50%;
  content: "";
  display: block;
  height: 1.95rem;
  position: absolute;
  transform: translate(-1%, -1%);
  width: 1.95rem;
}
.blog-promo:hover {
  text-decoration: none;
  cursor: pointer;
}

@media (min-width: 40em) {
  .promo-violator {
    display: flex;
    margin: 40px -10px;
  }
  .promo-violator__tile {
    width: 50%;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
  }
}

.recognition-tiles {
  font-family: "Montserrat-Medium";
}
.recognition-tiles__homepage-wrapper {
  margin-top: 80px;
  margin-bottom: 80px;
}
.recognition-tiles .page__section-heading {
  text-align: center;
  margin-bottom: 60px;
}
.recognition-tiles__tile {
  display: block;
  text-align: center;
  border-top: 1px solid #CBCBCB;
  padding: 20px 30px;
  box-sizing: border-box;
  color: #333333;
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.recognition-tiles__tile .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.recognition-tiles__tile .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.recognition-tiles__tile:hover {
  text-decoration: none;
}
.recognition-tiles__tile:hover .hover-tab {
  right: 0;
}
.recognition-tiles__tile .hover-tab {
  border-right: 78px solid #2D8469;
}
.recognition-tiles__tile p,
.recognition-tiles__tile h2,
.recognition-tiles__tile h3,
.recognition-tiles__tile strong {
  margin: 8px 0;
}
.recognition-tiles__tile p {
  font-size: 14px;
  line-height: 26px;
  transition: all 0.2s ease-in-out;
}
.recognition-tiles__tile strong {
  display: block;
  text-transform: uppercase;
  font-family: "Montserrat-SemiBold";
  color: #1290CE;
  letter-spacing: 1.7px;
  transition: all 0.2s ease-in-out;
  font-size: 20px;
}
.recognition-tiles__tile h2 {
  font-size: 48px;
  line-height: 56px;
  font-family: "Montserrat-SemiBold";
  word-wrap: break-word;
}
.recognition-tiles__tile h3 {
  font-family: "Montserrat-Bold";
}
.recognition-tiles__tile:hover {
  background-color: #3FBB94;
  color: #ffffff;
}
.recognition-tiles__tile:hover strong,
.recognition-tiles__tile:hover p {
  color: #ffffff;
}
.recognition-tiles__mobile-button {
  display: inline-block;
  line-height: 1.1;
  text-align: center;
  color: #012169;
  font-family: "Montserrat-SemiBold";
  border: 2px solid #012169;
  padding: 10px 30px;
  transition: all 0.2s ease-in-out;
}
.recognition-tiles__mobile-button:hover, .recognition-tiles__mobile-button--loading {
  text-decoration: none;
  background-color: #3FBB94;
  border-color: #3FBB94;
  color: #ffffff;
}
.recognition-tiles__mobile-button--loading {
  cursor: wait;
}
@media (hover: none) {
  .recognition-tiles__mobile-button {
    display: inline-block !important;
  }
}
.recognition-tiles__tile:hover .recognition-tiles__mobile-button {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
}
@media (min-width: 40em) {
  .recognition-tiles__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .recognition-tiles__tile {
    width: 33.3333%;
    min-height: 225px;
    border-top: 0;
    text-align: center;
    padding: 20px 30px;
    display: flex;
  }
  .recognition-tiles__tile:nth-child(1), .recognition-tiles__tile:nth-child(2), .recognition-tiles__tile:nth-child(3) {
    border-bottom: 1px solid #CBCBCB;
  }
  .recognition-tiles__tile:nth-child(1), .recognition-tiles__tile:nth-child(2), .recognition-tiles__tile:nth-child(4), .recognition-tiles__tile:nth-child(5) {
    border-right: 1px solid #CBCBCB;
  }
  .recognition-tiles__inner {
    width: 100%;
    align-self: center;
  }
  .recognition-tiles__mobile-button {
    display: none;
  }
}

.resource-list__resources {
  border-top: 1px solid #CBCBCB;
}
.resource-list__resource {
  display: block;
  border-bottom: 1px solid #CBCBCB;
  padding: 25px 0 25px 80px;
  overflow: hidden;
  position: relative;
}
.resource-list__resource .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.resource-list__resource .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.resource-list__resource:hover {
  text-decoration: none;
}
.resource-list__resource:hover .hover-tab {
  right: 0;
}
.resource-list__resource--no-icon {
  padding-left: 5px;
}
.resource-list__icon-wrapper {
  position: absolute;
  width: 48px;
  top: 25px;
  bottom: 25px;
  left: 0;
  display: flex;
  justify-content: center;
}
.resource-list__icon {
  max-width: 48px;
  align-self: center;
}
.resource-list__name {
  font-family: "Montserrat-Medium";
  margin-bottom: 0;
}
.resource-list__subhead {
  font-size: 12px;
  color: #4A4A4A;
  font-family: "Montserrat-SemiBold";
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 1.8;
}

.search-landing-segments {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 20px;
  flex-wrap: wrap;
}
.search-landing-segments__button {
  margin-right: 15px;
  margin-bottom: 10px;
}
.search-landing-segments__button:last-child() {
  margin-right: 0;
}
.search-landing-segments__button--active {
  font-family: "Montserrat-SemiBold";
}

.secondary-navigation__desktop {
  display: none;
}
.secondary-navigation__mobile {
  padding-top: 10px;
}
.secondary-navigation__item {
  margin-right: 20px;
}
.secondary-navigation__link {
  text-decoration: none !important;
  font-family: "Montserrat-SemiBold";
  letter-spacing: 0.54px;
  color: #333333;
}
.secondary-navigation__link:after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background-color: #3FBB94;
  margin-top: -4px;
  transition: width 0.2s ease-in-out;
}
.secondary-navigation__link--active:after {
  width: 100%;
}
.secondary-navigation__link:hover:after {
  width: 100%;
}
.secondary-navigation__select {
  background-color: transparent;
  position: relative;
}
.secondary-navigation__select select {
  width: 100%;
  position: relative;
  border: none;
  border-bottom: 2px solid #012169;
  border-radius: 0;
  padding: 6px 30px 6px 0;
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  color: #012169;
  background-color: transparent;
  -webkit-appearance: none;
  z-index: 2;
}
.secondary-navigation__select select:hover {
  cursor: pointer;
}
.secondary-navigation__select::after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e917";
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  font-weight: bold;
  color: #012169;
  transform: rotate(90deg);
}
.secondary-navigation__select option:disabled {
  background-color: transparent;
}
@media (min-width: 56em) {
  .secondary-navigation__mobile {
    display: none;
  }
  .secondary-navigation__desktop {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.site-search-list-item {
  display: block;
  border-bottom: 1px solid #CBCBCB;
  padding: 25px 0;
  overflow: hidden;
  position: relative;
}
.site-search-list-item .hover-tab {
  position: absolute;
  right: -100px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 78px solid #3FBB94;
  border-top: 79px solid transparent;
  transition: right 0.3s ease-in-out;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.site-search-list-item .hover-tab:after {
  content: "\e916";
  color: #ffffff;
  display: block;
  position: absolute;
  top: -35px;
  left: 48px;
  font-size: 21px;
}
.site-search-list-item:hover {
  text-decoration: none;
}
.site-search-list-item:hover .hover-tab {
  right: 0;
}
.site-search-list-item__title {
  color: #333333;
}
.site-search-list-item__summary {
  font-family: "Montserrat-Medium";
  font-size: 14px;
  line-height: 1.2;
  color: #9B9B9B;
  font-family: "Montserrat-Medium";
}
.site-search-list-item__summary highlight {
  font-weight: bold;
}

.sidebar-promo {
  border-top: 5px solid #012169;
  padding: 15px 0 20px;
  width: 100%;
  margin-bottom: 30px;
}
.sidebar-promo__text {
  font-size: 1.15rem;
  line-height: 26px;
  font-family: "Montserrat-Medium";
}
.sidebar-promo__link {
  display: block;
  text-align: center;
  margin: 15px 0 0;
}

.hero-nav-padding {
  margin-top: 30px;
}
@media (min-width: 56em) {
  .hero-nav-padding {
    margin-top: 0;
  }
}

.simple-hero {
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 16px;
  padding: 50px 0;
}
.simple-hero__image-col {
  margin-top: 20px;
}
.simple-hero--no-image {
  background-color: #B1E4E3;
  margin-bottom: 50px;
}
.simple-hero--no-image .simple-hero__info-col {
  width: 100%;
  padding: 0;
}
.simple-hero--no-image .simple-hero__image-col {
  display: none;
}
@media (min-width: 40em) {
  .simple-hero__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .simple-hero__info-col {
    padding-right: 60px;
    width: 60%;
  }
  .simple-hero__image-col {
    width: 40%;
    margin-top: 0;
  }
  .simple-hero__title {
    font-size: 40px;
    line-height: 46px;
  }
  .simple-hero--no-image .article-hero__title {
    font-size: 56px;
    line-height: 1.125;
  }
}

.social-icons {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.social-icons__icon {
  color: #ffffff;
  background-color: #012169;
  border-radius: 3px;
  margin-left: 6px;
  height: 26px;
  width: 26px;
  padding: 2px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  display: block;
  position: relative;
  text-align: center;
}
.social-icons__icon--linkedin::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e904";
}
.social-icons__icon--twitter::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e91e";
}
.social-icons__icon--facebook::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e910";
}
.social-icons__icon--email::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e901";
}
.social-icons__icon--pdf::before {
  content: "PDF";
  font-family: "Roboto-Bold";
  font-size: 11px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
}
.social-icons__icon::before {
  line-height: 25px;
}
.social-icons__icon:hover {
  background-color: #3FBB94;
  text-decoration: none;
  font-family: "Roboto-Bold";
}
.social-icons__icon:first-of-type {
  margin-left: 0;
}
@media (min-width: 40em) {
  .social-icons {
    position: absolute;
    left: 0;
    flex-direction: column;
  }
  .social-icons__icon {
    margin-top: 6px;
    margin-left: 0;
  }
  .social-icons__icon:first-of-type {
    margin-top: 0;
  }
  .social-icons__block .social-icons {
    position: static;
    left: auto;
    flex-direction: row;
  }
  .social-icons__block .social-icons__icon {
    margin-top: auto;
    margin-left: 6px;
  }
}

.team-feature__industry-card {
  background-color: #F2F2F2;
  padding: 20px 30px;
}
.team-feature__header {
  font-size: 14px;
  font-family: "Montserrat-Medium";
  letter-spacing: 1.7px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
@media (min-width: 40em) {
  .team-feature__links {
    column-count: 3;
    column-gap: 30px;
    column-fill: balance;
    break-inside: avoid;
  }
  .team-feature__link {
    display: block;
  }
}

.tertiary-navigation {
  font-family: "Montserrat";
  border: 1px solid #CBCBCB;
  margin-bottom: 30px;
}
.tertiary-navigation__item {
  padding: 10px 20px;
  border-bottom: 1px solid #CBCBCB;
}
.tertiary-navigation__item--active {
  background-color: #CBCBCB;
}
.tertiary-navigation__item:last-child {
  border-bottom: none;
}
.tertiary-navigation__link {
  display: block;
  line-height: 1.5rem;
}

.testimonials {
  font-family: "Montserrat";
  margin-top: 25px;
}
.testimonials__card {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  box-sizing: border-box;
  line-height: 24px;
}
.testimonials__data {
  width: 35%;
  padding-right: 15px;
}
.testimonials__content {
  width: 65%;
}
.testimonials__image {
  margin-bottom: 10px;
}
.testimonials__name, .testimonials__city {
  display: block;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 3px;
}
.testimonials__title {
  font-size: 12px;
  text-transform: uppercase;
  font-family: "Montserrat-SemiBold";
  margin-bottom: 3px;
}
.testimonials__quote, .testimonials__quote p {
  font-size: 14px;
  color: #000000;
  font-family: "Montserrat-Medium";
}
.testimonials__quote p {
  display: inline;
}
@media (min-width: 40em) {
  .testimonials__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }
  .testimonials__card {
    width: calc(50% - 20px);
    margin-right: 10px;
    margin-left: 10px;
  }
}

.typeahead {
  position: relative;
  width: 100%;
}
.typeahead--with-margin {
  margin-top: -30px;
}
.typeahead__active .typeahead__link {
  background-color: #F2F2F2;
  text-decoration: none;
  width: inherit;
}
.typeahead__results {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 0;
  z-index: 100;
  overflow: hidden;
}
.typeahead__result {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
.typeahead__link {
  display: block;
  padding: 5px 15px;
  width: 100%;
  color: #333333;
  font-family: "Montserrat-Medium";
}
.typeahead__link--view-all {
  text-align: left;
  font-style: italic;
  line-height: 32px;
}
.typeahead__link:hover {
  text-decoration: none;
}

.case-study-columns .section__list {
  padding-top: 38px;
  border-top: 4px solid #012169;
}

@media print {
  [is=subscribe-promo] {
    display: none;
  }
}
#onetrust-consent-sdk {
  font-family: "Montserrat";
}