@import 'reset';
[v-cloak] {
    display: none !important; 
}

@import 'normalize';
@import 'variables/_manifest';
@import 'mixins/_manifest';
@import 'blocks/_manifest';
@import 'print';

#onetrust-consent-sdk {
    font-family: $font__sans-serif;
}