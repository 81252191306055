.tertiary-navigation {
    font-family: $font__sans-serif;
    border: 1px solid $color__gray02;
    margin-bottom: 30px;

    &__item {
        padding: 10px 20px;

        &--active {
            background-color: $color__gray02;
        }

        border-bottom: 1px solid $color__gray02;

        &:last-child {
            border-bottom: none;
        }
    }

    &__link {
        display: block;
        line-height: 1.5rem;
    }
}
