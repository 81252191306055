.box-list {
    // font-family: $font__sans-serif;

    background-color: $color__gray01;
    padding: 20px;


    &__title {
        text-transform: uppercase;
        font-family: $font__sans-serif--med;
        letter-spacing: 1.7px;
        margin-bottom: 20px;
    }

    &__item {
        break-inside: avoid;
    }

    @include respond-to('small') { 
        padding: 40px;

        &__title {
            margin-bottom: 30px;
        }

        &__list{
            column-count: 3;
        }

        &__item {
            margin-bottom: 0.5em;
        }
    }
}