// sass-lint:disable-all


@font-face {
  font-family: 'icomoon';
  src:  url('#{$font-path}/icomoon/icomoon.eot?lqm7v6');
  src:  url('#{$font-path}/icomoon/icomoon.eot?lqm7v6#iefix') format('embedded-opentype'),
    url('#{$font-path}/icomoon/icomoon.ttf?lqm7v6') format('truetype'),
    url('#{$font-path}/icomoon/icomoon.woff?lqm7v6') format('woff'),
    url('#{$font-path}/icomoon/icomoon.svg?lqm7v6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  @include icon;

  &:hover {
    text-decoration: none;
  }
}
.icon__vcard {
  &:before {
    content: "VCard";
    font-family: $font-family-roboto-bold;
    font-size: 19px;
  }
}
.icon__hamburger {
  &:before {
    content: $icon__hamburger;
  }
}
.icon__close {
  &:before {
    content: $icon__close;
  }
}
.icon__download {
  &:before {
    content: $icon__download;
  }
}
.icon__mail {
  &:before {
    content: $icon__mail;
  }
}
.icon__search {
  &:before {
    content: $icon__search;
  }
}
.icon__twitter {
  &:before {
    content: $icon__twitter;
    background-color: $color__blue--dark;
    color: $color__white;
    padding: 3px;
    border-radius: 2px;
  }
}
.icon__linkedin {
  &:before {
    content: $icon__linkedin;
    background-color: $color__blue--dark;
    color: $color__white;
    padding: 3px;
    border-radius: 2px;
  }
}
.icon__google {
  &:before {
    content: $icon__google;
  }
}
.icon__facebook {
  &:before {
    content: $icon__facebook;
  }
}
.icon__pdf {
  // margin-top: 2px;
  
  &:before {
    content: "PDF";
    font-family: $font-family-roboto-bold;
    font-size: 13px;
    background-color: $color__blue--dark;
    color: $color__white;
    padding: 4px;
    border-radius: 2px;
  }
}
.icon__share {
  &:before {
    content: $icon__share;
  }
}
.icon__footer--twitter {
  &:before {
    content: $icon__footer--twitter;
  }
}
.icon__footer--linkedin {
  &:before {
    content: $icon__footer--linkedin;
  }
}
.icon__footer--facebook {
  &:before {
    content: $icon__footer--facebook;
  }
}
.icon__footer--google {
  &:before {
    content: $icon__footer--google;
  }
}
.icon__footer--youtube-1 {
  &:before {
    content: $icon__footer--youtube-1;
  }
}
.icon__footer--youtube-2 {
  &:before {
    content: $icon__footer--youtube-2;
  }
}
.icon__footer--instagram {
  &:before {
    content: $icon__footer--instagram
  }
}
.icon__dbl-chevron {
    &:before {
      content: $icon__dbl-chevron;
    }
}
.icon__glasses {
  &:before {
    content: $icon__glasses;
  }
}

.icon__play {
  &:before {
    content: $icon__play; 
  }
}
.icon__pause {
  &:before {
    content: $icon__pause; 
  }
}