.feature-video {
  font-family: $font-family-montserrat;
  &__description {
    margin-bottom: 30px;
  }

  &__video {
    margin-bottom: 20px;
  }

  &__headline {
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  @include respond-to('between') {
    &__inner {
      display: flex;
      flex-direction: row;
      align-content: center;
    }

    &__video {
      margin-bottom: 0;
      width: 40%;
    }

    &__content {
      width: 60%;
      margin-left: 5%;
    }
  }
}