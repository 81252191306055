.language-selector {
  margin-bottom: 32px;

  &__desktop {
    display: none;
  }

  &__select {
    @include select;

    select {
      border: 2px solid $color__blue--dark;
      padding-left: 24px;
      padding-right: 28px;
    }

    &::after {
      right: 16px;
      top: 12px;
    }
  }

  @include respond-to('between') {
    margin-bottom: 20px;

    &__mobile {
      display: none;
    }

    &__desktop {
      display: block;

      .article-columns__right &,
      .practice-columns__right & {
        margin-left: -15px;
        font-size: $font__size--tiny;

        select {
          padding-left: 15px;
        }
      }
      .gc-columns_right &{
        margin-left: 0;
      }
    }
  }

  @media print {
    display: none;
  }
}