.bio-card {
  font-family: $font__sans-serif;
  color: $color__text;

  &__wrapper {
    display: flex;
    flex-direction: column;
    background-color: $color__white;
    padding: 30px 20px;
    box-shadow: 1px 1px 24px 3px rgba(0,0,0,0.06);
  }

  &__image {
    max-width: 220px;
    margin: 20px auto;
  }

  &__mobile-info {
    text-align: center;
  }

  &__info-wrapper {
    position: relative;
    text-align: center;
  }

  &__info {
    margin-bottom: 20px;
  }

  &__resources {
    position: relative;

    @media print {
      display: none;
    }
  }

  &__social-links {
    position: relative;
    width: 200px;
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__social-icon {
    margin-left: 20px;
    // display: block;
    display: flex; //block mysteriously worked on template site but is not aligning properly now

    &:first-of-type {
      margin-left: 0;
    }
  }

  &__title {
    color: $color__gray04;
    text-transform: uppercase;
    font-family: $font__sans-serif--med;
    letter-spacing: 1.7px;
  }

  &__subtitle {
    font-family: $font__sans-serif--med;
    text-transform: uppercase;
    margin: 15px 0;
    letter-spacing: 1.7px;
  }

  &__link--email {
    display: block;
    margin-bottom: 12px;
  }

  &__office-link {
    display: block;
  }

  &__qualifications {
    text-align: left;
  }

  &__service-link {
    display: block;
  }

  &__divider {
    display: none;
  }

  &__pronouns {
    color: #8d8d8d;
    margin-top:-1em;
    margin-bottom:1em;
   }

  @include respond-to('small') {

    &__wrapper {
      flex-direction: row;
      padding: 50px 30px;
    }

    &__image {
      width: 33.333%;
      padding-left: 20px;
      padding-right: 40px;
      max-width: none;
      margin: 0;
    }

    &__info-wrapper {
      width: 66.666%;
      text-align: left;

      &--full-width {
        width: 100%;
      }
    }

    &__title {
      font-family: $font__sans-serif--bold;
    }

    &__name {
      margin-bottom: 16px;
    }

    &__offices {
      display: flex;
      flex-direction: row;
    }
  
    &__office {
      margin-right: 60px;

      & + & .bio-card__office-link--mobile-phone:only-child {
        // Vertially align mobile phone with office phone numbers
        margin-top: 2rem; 
      }
    }

    &__qualifications {
      border-top: 1px solid $color__gray02;
      padding-top: 20px;
      margin-top: 20px;
      // display: flex;
      // flex-direction: row;
    }

    &__qual-col {
      // width: 50%;
      // padding-right: 20px;
    }

    &__industry {
      padding-top: 20px;
    }

    &__service-link {
      display: inline;
    }

    &__divider {
      display: inline-block;
      font-family:  $font__sans-serif;
    }
  }

  @include respond-to('between') {
    &__name {
      padding-right: 200px;
    }

    &__resources {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
    }

    &__social-links {
      justify-content: flex-end;
    }
  }
}