//TODO: remove unused result styles now that the people search results is using people-card and not these styles

//despite saying people, this is used for every search landing

.people-search {
  font-family: $font__sans-serif;

  &__card {
    background-color: $color__white;
    padding: 15px;
    box-shadow: 1px 1px 24px 3px rgba(0,0,0,0.06);
  }

  &__label {
    font-family: $font__sans-serif--med;
    font-size: $font__size--small;
    text-transform: uppercase;
  }

  &__keyword-search {
    margin-bottom: 30px;

    //services landing, site search 
    &--no-margin {
      margin-bottom: 0;
    }

    position: relative;

    input {
      width: 100%;
      box-sizing: border-box;
      padding-right: 32px;
    }
  }

  &__search-icon {
    -webkit-appearance: none;
    background: transparent;
    border: none;
    position: absolute;
    right: 10px;
    top: 12px;

    &::before {
      @include icon;
      content: $icon__search;
      color: $color__gray04;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__filter-toggle {
    
  }

  &__filter-container {
    padding: 30px 0 0;
  }

  &__letter-toggle {
    width: 100%;
    position: relative;
    border: none;
    border-bottom: 2px solid $color__blue--dark;
    border-radius: 0;
    padding: 6px 30px 6px 0;
    font-family: $font__sans-serif--semibold;
    color: $color__blue--dark;
    background-color: transparent;
    -webkit-appearance: none;
    z-index: 2;
    margin-bottom: 30px;
    @extend .body-copy;

    &:hover {
      cursor: pointer;
    } 

    &::after {
      @include icon;
      content: $icon__nav-arrow;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      font-weight: bold;
      color: $color__blue--dark;
      transform: rotate(90deg);
    }
  }

  &__letter-wrapper {
    position: relative;
  }

  &__letters {
    font-size: $font__size--h5;
    color: $color__text;
    padding: 15px 0;
    margin-bottom: 30px;
    background-color: $color__white;
    border: 2px solid $color__blue--dark;
    border-top: none;
    position: absolute;
    width: 100%;
    opacity: 0;
    top: 20%;
    z-index: 10;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out,
                top 0.5s ease-in-out,
                visibility 0.5s ease-in-out;
    
    &--open {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
    
    span {
      width: 16.667%;
      text-align: center;
      box-sizing: border-box;
      display: inline-block;
      position: relative;
      padding: 4px 3px;

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        margin-top: 2px;
        background-color: $color__green;
        transition: width 0.2s ease-in-out;
      }

      &:hover {
        cursor: pointer;

        &:after {
          width: 100%;
        }
      }
    }
  }

  &__select-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: -10px;
    margin-right: -10px;
  }

  &__select {
    @include select;
  }

  &__advance-filter {
    width: calc(100% - 10px);
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 30px;
  }

  &__results {
    padding: 80px 0 20px;
  }

  &__active-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__active-filter {
    border: 2px solid $color__blue--dark;
    border-radius: 16px;
    font-family: $font__sans-serif--semibold;
    color: $color__blue--dark;
    padding: 6px 20px;
    margin-right: 12px;
    margin-bottom: 12px;

    &:before {
      content: "";
      display: inline-block;
      height: 12px;
      width: 10px;
      background: url('#{$img-path}/close-button.svg') no-repeat center center;
      background-size: 10px;
      margin-right: 6px;
    }
  }

  &__clear-all {
    color: $color__gray03;
    margin-top: 20px;

    &:hover {
      text-decoration: underline;
      color: $color__gray04;
    }
  }

  &__results-container {
    margin-top: 40px;
    position: relative;
  }

  &__results-header {
    text-transform: uppercase;
    font-family: $font__sans-serif--med;
    border-bottom: 1px solid $color__gray02;
    padding-bottom: 18px;
    letter-spacing: 2px;
  }

  &__people-container {
    margin-top: 30px;
  }

  &__person-container {
    margin-bottom: 30px;

    border: 1px solid $color__gray02;
    border-top: 3px solid $color__blue--dark;
    padding: 20px;

  }

  &__image {
    width: 86px;
    margin-bottom: 20px;
    
    img {
      border-radius: 50%;
    }
  }

  &__name {
    font-size: $font__size--h3;
    line-height: $font__size--h4;
    font-family: $font__sans-serif--semibold;
    margin-bottom: 8px;
    display: block;
    letter-spacing: 0.26px;
  }

  &__first-name,
  &__middle-name {

    &::after {
      content:"\00a0"
    }
  }

  &__title {
    font-size: $font__size--small;
    line-height: $font__size--h5;
    font-family: $font__sans-serif--semibold;
    margin-bottom: 14px;
  }

  &__email {
    font-size: $font__size--small;
    font-family: $font__sans-serif--semibold;
    padding-left: 25px;
    word-wrap: break-word;
    background: url('#{$img-path}/mail-icon.svg') no-repeat left center;
  }

  &__city {
    font-size: $font__size--small;
    font-family: $font__sans-serif--med;
    padding-left: 25px;
    background: url('#{$img-path}/phone-icon.svg') no-repeat left center;
  }

  &__city-name {
    margin-right: 15px;
    display: inline-block;
  }

  &__city-name,
  &__office-phone {
    line-height: $line-height__h5;
    display: block;
  }

  @include respond-to('small') {
    &__card {
      min-height: 150px;
      padding: 50px 100px;
    }

    &__keyword-search {
  
      input {
        padding-right: 38px;
      }
    }

    &__search-icon {
      top: 8px;
  
      &::before {
        font-size: 24px;
        line-height: 34px;
      }
    }

    &__letters {
      padding: 0;
      border: none;
      position: relative;
      background-color: transparent;
      opacity: 1;
      visibility: visible;

      span {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 5px;
        width: auto;
        text-align: left;
      }
    }

    &__letter-toggle {
      display: none;
    }

    &__advance-filter {
      width: calc(50% - 20px);
      margin-right: 10px;
      margin-left: 10px;
      margin-bottom: 30px;
  
      &--secondary {
        width: calc(33% - 17px);

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__results-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__clear-all {
      margin-top: 0;
    }

    &__people-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}

.filter-search {
  &__filter-title {
    margin-bottom: 15px;
    font-size: $font__size--small;
    font-family: $font__sans-serif--semibold;
    text-transform: uppercase;
    letter-spacing: 1.75px;
  }

  &__button {
    @include button;

    font-size: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    border: none;
    background-color: $color__gray01;
    color: $color__gray04;

    &--active {
      background-color: $color__green--dark;
      color: $color__white;
    }
    
    &--loading {
      cursor: wait;
    }
  }

  &__count {
    position: absolute;
    right: 0;
    top: 0;
  }
}

div[is="people-landing"],
div[is="insight-landing"],
div[is="event-landing"],
div[is="news-landing"] {
  min-height: 100vh; // Prevent flash-of-footer
}