//copied and modified from expanding-list

.case-study-list-item {

    display: block;
    border-bottom: 1px solid $color__gray02;
    padding: 25px 0;
    
    @include hover-tab;


    &__title {
        color: $color__text;
        margin: 0;
    }

    &__summary {
        margin-top: 10px;
        font-family: $font__sans-serif--med;
        font-size: $font__size--small;
        // color: $color__black;
        line-height: 1.3;
        color: $color__gray03;
        font-family: $font__sans-serif--med;
    }
}