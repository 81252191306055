.recognition-tiles {
  font-family: $font__sans-serif--med;

  &__homepage-wrapper {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .page__section-heading {
    text-align: center;
    margin-bottom: 60px;
  }

  &__tile {
    display: block;
    text-align: center;
    border-top: 1px solid $color__gray02;
    padding: 20px 30px;
    box-sizing: border-box;
    color: $color__text;

    @include hover-tab;

    .hover-tab {
      border-right: 78px solid $color__green--dark;
    }

    p,
    h2,
    h3,
    strong {
      margin: 8px 0;
    }

    p {
      font-size: $font__size--small;
      line-height: $line-height__h5;
      transition: $link-transition;
    }

    strong {
      display: block;
      text-transform: uppercase;
      font-family: $font__sans-serif--semibold;
      color: $color__blue--light;
      letter-spacing: 1.7px;
      transition: $link-transition;
      font-size: $font__size--h4;
    }

    h2 {
      font-size: $font__size--large-header;
      line-height: $font__size--h1;
      font-family: $font__sans-serif--semibold;
      word-wrap: break-word;
    }

    h3 {
      font-family: $font__sans-serif--bold;
    }

    transition: $link-transition;

    &:hover {
      background-color: $color__green;
      color: $color__white;

      strong,
      p {
        color: $color__white;
      }
    }
  }

  &__mobile-button {
    @include button;

    @media (hover: none) {
      display: inline-block !important;
    }
  }

  &__tile:hover &__mobile-button {
    color: $color__white;
    background-color: transparent;
    border-color: $color__white;
  }

  @include respond-to('small') {
    &__wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__tile {
      width: 33.3333%;
      min-height: 225px;
      border-top: 0;
      text-align: center;
      padding: 20px 30px;
      display: flex;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        border-bottom: 1px solid $color__gray02;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5) {
        border-right: 1px solid $color__gray02;
      }
    }

    &__inner {
      width: 100%;
      align-self: center;
    }

    &__mobile-button {
      display: none;
    }
  }
}