//Brand colors

//Primary colors
$color__white:                   #ffffff;
$color__black:                   #000000;
$color__text:                    #333333;

// Grays
$color__gray01:                  #F2F2F2;
$color__gray02:                  #CBCBCB;
$color__gray03:                  #9B9B9B;
$color__gray035:                #767676; 
$color__gray04:                  #4A4A4A;
$color__gray05:                  #656565;

$color__gray-alt: #D9D9D9; //for homepage mobile indicator, from designs


// Form
$color__input-border:            #E6E6E6;
$color__input-bg:                #FCFCFC;

// Blues
$color__blue--light:             #1290CE;
$color__blue--dark:              #012169;
$color__blue--darker:            #1C273E;
$color__blue--darkest:           #001546;

$color__blue--alt: #103483;//for homepage mobile indicator, from designs

// Greens
$color__teal:                    #B1E4E3;
$color__green:                   #3FBB94;
$color__green--dark:             #2D8469;