.pagination {
  float: right;
  margin-top: 30px;
  display: flex;
  align-items: center;

  &__arrow {
    width: 14px;
    height: 22px;
    color: $color__green;
    font-size: 22px;

    &::before {
      @include icon;
      content: $icon__nav-arrow;
      font-weight: bold;
    }

    &--inactive {
      color: $color__gray03;
    }
  }

  &__prev {
    transform: rotate(180deg);
    margin-right: 20px;
  }

  &__next {
    margin-left: 12px;
  }

  &__page-list-wrapper {
    position: relative;
  }

  &__page-list-toggle {
    color: $color__gray03;
    transform: rotate(270deg);
    font-size: 11px;
    margin-left: 12px;

    &::before {
      @include icon;
      content: $icon__nav-arrow;
      font-weight: bold;
    }
  }

  &__page-count {
    color: $color__blue--dark;
    font-family: $font__sans-serif--semibold;
  }

  &__page-list {
    position: absolute;
    bottom: 30px;
    right: -54px;
    width: 146px;
    height: 160px;
    background-color: $color__white;
    box-shadow: 0 2px 4px 2px rgba(189,189,189,0.5);
    z-index: 1000;

    &:before {
      content: "";
      height: 0;
      width: 0;
      border-top: 14px solid rgba(189,189,189,0.1);
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      position: absolute;
      top: 100%;
      right: 44px;
    }

    &:after {
      content: "";
      height: 0;
      width: 0;
      border-top: 10px solid $color__white;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      top: 99%;
      right: 48px;
      margin-top: 2px;
    }
  }

  &__page-list-inner {
    height: 100%;
    width: 100%;
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__page-link {
    width: 100%;
    border-bottom: 1px solid $color__gray02;
    color: $color__blue--dark;
    font-family: $font__sans-serif--semibold;
    padding-bottom: 12px;
    margin-bottom: 12px;

    &--current {
      color: $color__text;
      font-family: $font__sans-serif--med;
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}