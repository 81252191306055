.practice-columns {

  &__center {
    .section__list {
      padding-top: 38px;
      border-top: 4px solid $color__blue--dark;
    }
  }

  @include respond-to('between') {
    display: flex;
    flex-direction: row;

    &__left {
      width: 25%;
      padding-right: 5%;
      position: relative;
    }

    &__center {
      width: 60%;
      padding-right: 5%;
    }
  
    &__right {
      width: 20%;
    }
  }

  &__left {
    margin-bottom: 72px;

    .page-nav {
      left: 0;
      max-width: 210px;

      &--nav-locked {
        left: 20px;
      }

      &--bottom-locked {
        left: 0;
      }
    }
  }

  @include respond-to('large') {

    &__left {
      .page-nav {
        max-width: 230px;
      }
    }

    &__center {
      width: 60%;
      padding-right: 5%;
    }
  }

  @include respond-to('container') {

    &__left {
      .page-nav {
        max-width: 250px;
      }
    }
  }

  @include respond-to('xxlarge') {
    &__left {
      .page-nav {
        &--nav-locked {
          left: calc( ( (100% - 1376px) / 2 ) + 20px );
        }

        &--bottom-locked {
          left: 0;
        }
      }
    }
  }

}