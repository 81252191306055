.mobile-only {
  @include respond-to('small') {
    display: none;
  }
}

.mobile-hidden {
  display: none;
  
  @include respond-to('small') {
    display: block;
  }
}