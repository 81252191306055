.industry-cards {
  font-family: $font__sans-serif;

  &__card {
    background-color: $color__gray01;
    margin-bottom: 30px;
    display: block;
    box-sizing: border-box;

    @include hover-tab;
  }

  &__icon {
    margin-bottom: 15px;
  }

  &__image {
    width: 100%;
  }

  &__container {
    padding: 25px 30px 28px;
  }

  &__headline {
    font-family: $font__sans-serif--semibold;
    font-size: $font__size--h3;
    line-height: $line-height__h4;
    color: $color__blue--dark;
  }

  &__summary {
    line-height: 22px;
    color: $color__text;
    font-family: $font__sans-serif--med;
    margin-top: 10px;

    & * { //for paragraphs, span, etc for the unfortunate paragraph default styling
      font-family: $font__sans-serif--med;
    }
  }

  @include respond-to('small') {
    &__cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
    }
  }

}