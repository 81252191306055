.featured-sections {
  &--no-border {
    .featured-sections__wrapper {
      border-top: none;
    }
  }
  &__wrapper {
    border-top: 5px solid $color__blue--dark;
  }

  &__block {
    padding: 30px 15px 60px;
    text-align: center;
  }

  &__title {
    font-family: $font__sans-serif--med;
    font-size: $font__size--h3;
    line-height: $line-height__h3;
  }

  &__summary {
    @extend .body-copy;
    margin-bottom: 45px;
  }

  &__link {
    @include button;

    background-color: $color__green--dark;
    border-color: $color__green--dark;
    color: $color__white;
  }

  @include respond-to('small') {
    &__wrapper {
      display: flex;
      flex-direction: row;
    }

    &__block {
      width: 50%;
      padding: 90px 20px 100px;
    }

    &__title {
      font-size: 36px;
    }

    &__summary {
      font-size: $font__size--h5;
      line-height: $line-height__h5;
      font-family: $font__sans-serif--med;
      max-width: 450px;
      margin: 0 auto;
      margin-bottom: 70px;
    }
  }
}