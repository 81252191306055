$desktop-outer-container: 1376px;
$desktop-inner-container: 1184px;
$desktop-body-container: 908px;

$margin-bottom-mobile: 36px;
$margin-bottom: 72px;

.pipe-divider {
  margin: 0 12px;
}
