.practice-search {
  font-family: $font__sans-serif;

  &__card {
    background-color: $color__white;
    padding: 15px;
    box-shadow: 1px 1px 24px 3px rgba(0,0,0,0.06);
  }

  &__label {
    font-family: $font__sans-serif--med;
    font-size: $font__size--small;
    text-transform: uppercase;
  }

  &__keyword-search {
    position: relative;

    input {
      width: 100%;
      color: red;
      padding-right: 32px;
    }
  }

  &__search-icon {
    -webkit-appearance: none;
    background: transparent;
    border: none;
    position: absolute;
    right: 10px;
    top: 12px;

    &::before {
      @include icon;
      content: $icon__search;
      color: $color__gray04;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__results {
    padding: 80px 0 20px;
  }

  &__active-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__active-filter {
    border: 2px solid $color__blue--dark;
    border-radius: 16px;
    font-family: $font__sans-serif--semibold;
    color: $color__blue--dark;
    padding: 6px 20px;
    margin-right: 12px;
    margin-bottom: 12px;

    &:before {
      content: "";
      display: inline-block;
      height: 12px;
      width: 10px;
      background: url('#{$img-path}/close-button.svg') no-repeat center center;
      background-size: 10px;
      margin-right: 6px;
    }
  }

  &__clear-all {
    color: $color__gray03;
    margin-top: 20px;

    &:hover {
      text-decoration: underline;
      color: $color__gray04;
    }
  }

  &__results-container {
    margin-top: 40px;
    position: relative;
  }

  &__results-header {
    text-transform: uppercase;
    font-family: $font__sans-serif--med;
    border-bottom: 1px solid $color__gray02;
    padding-bottom: 18px;
    letter-spacing: 2px;
  }

  @include respond-to('small') {
    &__card {
      min-height: 150px;
      padding: 50px 100px;
    }

    &__keyword-search {
  
      input {
        padding-right: 38px;
      }
    }

    &__search-icon {
      top: 8px;
  
      &::before {
        font-size: 24px;
        line-height: 34px;
      }
    }

    &__results-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__clear-all {
      margin-top: 0;
    }
  }
}