.highlights {
  padding: 30px 0 10px;
  border-top: 4px solid $color__blue--dark;
  border-bottom: 1px solid $color__gray02;
  margin-bottom: 5px;

  a {
    font-family: $font-family-garamond-bold;
    font-weight: bold;
  }
  li {

    font-size: $font__size--h5;
    font-family: $font-family-garamond;
  }

  p{
    font-size: $font__size--h5;
    font-family: $font-family-garamond;

  }
  
  strong {
    font-family: $font-family-garamond-bold;
  }
}