.media-slider {
  background-color: $color__gray01;
  padding: 30px 0 0;
  overflow: hidden;
  font-family: $font__sans-serif;

  @include respond-to('medium'){
    &--smallgray{
      background-color: transparent;
    }
  }
  &__inner {  
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-right: 25px;
    transition: transform 500ms ease-in-out;

    @include respond-to('medium'){
      &--smallgray-1{
        background-color: $color__gray01;
        width: calc(100% / 3);
        padding: 2rem 0;
      }
      
      &--smallgray-2{
        background-color: $color__gray01;
        width: calc(100% / 3 * 2);
        padding: 2rem 0;
      }
    }
  }

  &__card {
    width: calc(100% - 80px);
    box-sizing: border-box;
    background-color: $color__white;
    padding: 15px;
    flex: 0 0 auto;
    margin-left: 25px;
    @include hover-tab;

    @include respond-to('small'){
      width: calc(50% - 60px);
    }
    @include respond-to('large'){
      width: calc(33% - 50px);
    }
    @include respond-to('medium'){
      &--smallgray-1{
        width: calc(100% - 3.125rem);
      }
      &--smallgray-2{
        width: calc((100% / 2) - 3.125rem);
      }
    }
  }

  &__image {
    margin-bottom: 20px;
  }

  &__headline {
    @extend .body-copy;
    color: $color__blue--dark;
  }

  &__control-row {
    margin: 30px 25px 0;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-family: $font__sans-serif--med;
  }

  &__controls {
    display: flex;
    position: absolute;
    right: 25px;
    bottom: 0;
  }

  &__arrow {
    width: 14px;
    height: 50px;
    width: 50px;
    background-color: $color__white;
    color: $color__green;
    font-size: 22.5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    left: 1px;

    &::before {
      @include icon;
      content: $icon__nav-arrow;
      font-weight: bold;
    }
  }

  &__left-arrow {
    transform: scaleX(-1);
    
  }
}