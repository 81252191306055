input {
  border: 1px solid $color__input-border;
  padding: 12px;
  color: $color__gray04;
  background-color: $color__input-bg;
  -webkit-appearance: none;
  font-family: $font__sans-serif;
  font-size: $font-size-base;
  line-height: lineHeight($font__size--small);

  &::placeholder {
    color: $color__gray035;
    -webkit-appearance: none;
    //font-size: $font-size-base;
    line-height: lineHeight($font__size--small);
  }

  &[type="radio"] {
    opacity: 0;
    padding: 0;
    margin: 0;
    border: none;
    height: 20px;
    width: 17px;
    position: relative;
    z-index: 10;
  }
}

textarea {
  border: 1px solid $color__input-border;
  padding: 12px;
  background-color: $color__input-bg;
}

