.people-card {
  &__card {
    margin-bottom: 30px;

    border: 1px solid $color__gray02;
    border-top: 3px solid $color__blue--dark;
    padding: 20px;

  }

  &__image {
    width: 86px;
    margin-bottom: 20px;
    
    img {
      border-radius: 50%;
    }
  }

  &__name {
    font-size: $font__size--h3;
    line-height: $font__size--h4;
    font-family: $font__sans-serif--semibold;
    margin-bottom: 8px;
    display: block;
    letter-spacing: 0.26px;
  }

  // &__first-name,
  // &__middle-name {

  //   &::after {
  //     content:"\00a0"
  //   }
  // }

  &__title {
    font-size: $font__size--small;
    line-height: $font__size--h5;
    font-family: $font__sans-serif--semibold;
    margin-bottom: 14px;
  }

  &__email {
    font-size: $font__size--small;
    font-family: $font__sans-serif--semibold;
    word-wrap: break-word;
  }

  &__city {
    font-size: $font__size--small;
    font-family: $font__sans-serif--med;

    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  &__city-name {
    margin-right: 15px;
    display: inline-block;
  }

  &__city-name,
  &__office-phone {
    line-height: $line-height__h6;
    display: block;
  }

  @include respond-to('small') {
    &__person-container {
      width: calc(50% - 30px);
      margin-right: 15px;
      margin-left: 15px;
    }

    &__card {
      display: flex;
      flex-direction: row;
    }

    &__image {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
}