.footer {
  width: 100%;
  font-family: $font__sans-serif;
  background-color: $color__blue--dark;
  color: $color__white;

  &__top {
    background: url('#{$img-path}/footer_bg.png') no-repeat center center;
    background-size: cover;
    padding: 50px 0;
  }

  &__logo {
    width: 254px;
    margin-bottom: 24px;
  }

  &__slogan {
    max-width: 550px;
    font-size: $font__size--small;
    line-height: 22px;
  }

  &__links {
    margin-top: 50px;
  }

  &__button {
    @extend .button;
    display: block;
    color: $color__white;
    border-color: $color__white;
    text-align: center;
    padding: 5px;
  }

  &__link {
    display: block;
    color: $color__white;
  }

  &__bottom {
    background-color: $color__blue--darkest;
    padding: 15px 0;
  }

  &__legal {
    font-size: $font__size--tiny;
    line-height: $font__size--h3;
  }

  &__legal-link {
    font-size: $font__size--tiny;
    line-height: $font__size--h3;
    color: $color__white;
    display: block;
    margin-top: 15px;
  }

  &__social {
    display: flex;
    justify-content: center;

    li{
      margin-left: 20px;
      list-style: none;
      padding: 0;
    }
  }

  &__social-label {
    font-size: $font__size--tiny;
    font-family: $font__sans-serif--semibold;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    display: inline-block;
    margin-right: 14px;
    line-height: 25px;
  }

  &__icon {
    color: $color__white;
    font-size: $font__size--h3;
    margin-left: 20px;
    opacity: 0.5;
    text-decoration: none;
    transition: $link-transition;

    &--linkedin {
      &::before {
        @include icon;
        content: $icon__footer--linkedin;
      }
    }

    &--twitter {
      &::before {
        @include icon;
        content: $icon__footer--twitter;
      }
    }

    &--facebook {
      &::before {
        @include icon;
        content: $icon__footer--facebook;
      }
    }

    &--youtube-1 {
      &::before {
        @include icon;
        content: $icon__footer--youtube-1;
      }
    }

    &--youtube-2 {
      &::before {
        @include icon;
        content: $icon__footer--youtube-2;
      }
    }

    &--instagram {
      &::before {
        @include icon;
        content: $icon__footer--instagram;
      }
    }

    &--rss {
      &::before {
        @include icon;
        content: $icon__footer--rss;
      }
    }

    &:hover {
      opacity: 1;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }

  @include respond-to('small') {
    &__cols {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__links {
      text-align: right;
      margin-top: 0;
    }

    &__button {
      padding: 10px 30px;
    }

    &__legal {
      display: flex;
      flex-direction: row;
    }

    &__legal-link {
      margin-left: 50px;
      margin-top: 0;
    }
  }

  @media print {
    display: none;
  }
}