$mobile-aspect-ratio: 50%; 
//slightly shrink the tile height so it isn't so big on mobile
$desktop-aspect-ratio: 72%;


.image-links {
  &__title {
    font-family: $font__sans-serif--semibold;
    color: $color__text;
    text-transform: uppercase;
    margin-bottom: 32px;
    letter-spacing: 1.7px;
  }

  &__locations {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  
  &__location {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0 4px 10px 6px rgba(0,0,0,0.06);
    display: block;

    @include hover-tab;

    &:hover {
      text-decoration: none;

      .image-links__background {
        transform: scale(1.2);

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }
    }
  }

  &__container {
    width: 100%;
    // height: 180px;
    overflow: hidden;
  }

  &__background {
    display: block;
    background-size: cover;
    width: 100%;
    // height: 180px;
    height: 0;
    padding-top: $mobile-aspect-ratio;
    transition: $zoom-transition;
  }

  &__name {
    display: block;
    padding: 20px;

    &--has-text {
      padding-bottom: 0 !important;
    }
    
    font-family: $font__sans-serif--bold;
    color: $color__black;
    transition: $link-transition;
    margin: 0;
  }

  &__text {
    padding: 0 20px 5px 20px;

    .body-copy {
      font-size: $font__size--tiny;
    }
  }

  @include respond-to('small') {
    // &__container,
    // &__background {
    //   height: 200px;
    // }

    &__locations {
      flex-direction: row;
    //   margin-left: -10px;
    //   margin-right: -10px;
    }
    
    &__location {
      width: calc(50% - 20px);
      margin: 0 10px 20px;
    }

    &__name {
      padding: 30px 20px;
    }

    &__text {
      padding-bottom: 12px;
    }

    &__background {
      padding-top: $desktop-aspect-ratio;
    }

  }

  @include respond-to('between') {
    &__location {
      width: calc(25% - 20px);
      // margin: 0 10px 20px;
    }

  }
}