//copied and modified from expanding-list

.site-search-list-item {

    display: block;
    border-bottom: 1px solid $color__gray02;
    padding: 25px 0;
    
    @include hover-tab;


    &__title {
        color: $color__text;
    }

    &__summary {
        font-family: $font__sans-serif--med;
        font-size: $font__size--small;
        line-height: 1.2;
        color: $color__gray03;
        font-family: $font__sans-serif--med;

        highlight {
            font-weight: bold;
        }
    }
}