.office-list {
  background-color: $color__blue--dark;
  padding: 50px 0;
  color: $color__white;
  font-family: $font__sans-serif;

  &__region-title {
    font-size: $font__size--small;
    text-transform: uppercase;
    margin-bottom: 20px;
    letter-spacing: 1.7px;
  }

  &__subtitle {
    font-size: $font__size--small;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  &__office {
    color: $color__white;
    display: block;
    line-height: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &__international-section {
    margin-top: 20px;
  }

  @include respond-to('small') {
    

    &__region-inner {
      column-count: 4;
    }

    &__subtitle {
      margin-top: 40px;
    }

    &__office, &__subtitle {
      padding-right: 1em;
    }

    &__international-section {
      margin-top: 30px;
    }

  }

  @include respond-to('large') {
    &__office-grid {
      display: flex;
      justify-content: space-between;
    }

    &__international-section {
      margin-top: 0;
    }
  }
}