$breakpoints: (
  'small':  ( min-width: 40em ), // 640px
  'medium': ( min-width: 48em ), // 768px
  'between': ( min-width: 56em ), // 896px
  'large':  ( min-width: 64em ), // 1024px
  'container': ( min-width: 74em ), // 1184px
  'xlarge': ( min-width: 80em ), // 1280px
  'xxlarge': ( min-width: 86em ) // 1376px
) !default;

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
}