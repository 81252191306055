$font__sans-serif: "TradeGothicLT";
$font__serif: "Georgia";

html,
body {

  // -ms-overflow-style: -ms-autohiding-scrollbar;
  //removed due to sneaking up on header icons

  height: 100%;
  font-family: $font__serif;
}

// input,
// button,
// a,
// select {
//   &:focus {
//     outline: 1px solid #012169;
//   }
// }

a {
  text-decoration: none;
  font-family: $font__sans-serif;
  font-weight: 400;
}

b, strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

div,
section,
input,
textarea {
  box-sizing: border-box;
}

// sass-lint:disable no-vendor-prefixes
button {
  -webkit-appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  
  overflow: visible; //not set for IE but other browsers seem to assume it
}

// sass-lint:disable no-vendor-prefixes
select,
input {
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

img {
  max-width: 100%;
}

.hidden {
    display: none;
}

.hidden-content {
    padding: 8px;
    position: absolute;
    background: white;
    left: 50%;
    height: 30px;
    transform: translateY(-100%);
    transition: transform .3s;
    z-index: 1000;

    &:focus {
        transform: translateY(0%);
    }
}