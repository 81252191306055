.profile-focus {
  margin-bottom: 32px;

  &__select {
    @include select;

    select {
      border: 2px solid $color__blue--dark;
      padding-left: 24px;
      padding-right: 38px;
    }

    &::after {
      right: 16px;
      top: 12px;
    }
  }

  @include respond-to('small') {
    margin-bottom: 0;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;

    &__select {
      max-width: 350px;
    }
  }

  @media print {
    display: none;
  }
}