.image-promo {
  @include hover-tab;

  padding: 20px 15px;
  background-color: $color__gray01;
  margin-bottom: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &__image {
    margin-bottom: 15px;
    display:flex;
    margin: 0 1rem 1rem 0;
    flex: 0 1 auto;
    min-width: 25%;
    margin-right: 50px;
    img{
      max-width: 70rem;
    }
  }

  &__container{
    display: inline-flex;
    flex-direction: column;
    width: 100%;
  }

  &__title {
    display: inline;
  }

  &__summary, &__date {
    @extend .body-copy;
  }

  &__date {
    margin-top: 15px;
  }
}

@include respond-to('large') {
  .image-promo {
    flex-direction: row;
    align-items: flex-start;
    &__image {
      margin-bottom: 30px;
    }
    &__container {
      &--image{
        width: 75%;
      }
    }
  }
  
  .image-promos {
    display: flex;
    flex-direction: row;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    flex-wrap: wrap;

    .image-promo {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      flex-basis: calc(33% - 1rem);
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }
  }
}