@mixin select {
  background-color: transparent;
  position: relative;
  
  select {
    width: 100%;
    position: relative;
    border: none;
    border-bottom: 2px solid $color__blue--dark;
    border-radius: 0;
    padding: 6px 30px 6px 0;
    font-family: $font__sans-serif--semibold;

    // iOS forces page-zoom on input focus when the font-size is small.
    // Unless it's important, don't make the font-size any smaller than 16px!
    // https://stackoverflow.com/questions/6483425
    font-size: 16px;

    color: $color__blue--dark;
    background-color: transparent;
    -webkit-appearance: none;
    z-index: 2;
    @extend .body-copy;

    &:hover {
      cursor: pointer;
    } 
  }

  &::after {
    @include icon;
    content: $icon__nav-arrow;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    font-weight: bold;
    color: $color__blue--dark;
    transform: rotate(90deg);
  }

  option:disabled {
    background-color: transparent;
  }
}