.practice-list {
  font-family: $font__sans-serif;
  @extend .body-copy;
  margin-bottom: 30px;

  &__toggle-container {
    text-align: center;
    margin-bottom: 40px;
  }

  &__toggle {
    color: $color__blue--dark;
    border-right: 1px solid $color__text;
    font-family: $font__sans-serif--semibold;
    font-size: $font__size--small;
    line-height: $line-height__h4;
    padding: 0 12px;

    &:last-of-type {
      border: none;
      padding-right: 0;
    }

    &:first-of-type {
      padding-left: 0;
    }

    &--active {
      color: $color__text;
    }
  }

  &__section-title {
    font-size: $font__size--small;
    font-family: $font__sans-serif--semibold;
    text-transform: uppercase;
    letter-spacing: 1.75px;
    border-bottom: 1px solid $color__gray02;
    padding-bottom: 12px;
  }

  &__category-section {
    .practice-list__link {
      display: none;
      
      &--open {
        display:block;
      }
    }
   
  }

  &__accordion-toggle {
    position: absolute;
    right: 10px;
    top: -38px;
    font-family: $font__sans-serif--semibold;
    color: $color__blue--dark;
  }

  &__category-accordion {
    position: relative;
  }

  &__link {
    display: block;
    padding-bottom: 12px;
    line-height: $line-height-base;
  }
  &__link-inner {
    padding-left: 15px;
  }

  &__letter {
    font-family: $font-family-garamond-bold;
    font-size: $font__size--h2;
    margin-bottom: 25px;
  }

  &__letter-section {
    border-bottom: 1px solid $color__gray02;
    padding: 20px 0;
  }

  @include respond-to('small') {
    margin-bottom: 60px;

    &__toggle {
      padding: 0 16px;
      font-size: $font-size-base;
    }

    &__category-accordion {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
    }

    &__category-accordion--column {
      width: calc(50% - 20px);
      margin-right: 10px;
      margin-left: 10px;
    }

    &__alpha-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__letter-section {
      padding: 30px 30px 30px 0;
      width: 33.3333%
    }


  }
}