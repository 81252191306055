.container {
  width: 100%;
  max-width: $desktop-outer-container;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  position: relative;

  &--top {
    margin-top: 36px;
  }

  &--small {
    max-width: $desktop-inner-container;
  }

  &--inner {
    max-width: $desktop-body-container;
  }

  &--no-padding-mobile {
    padding-left: 0;
    padding-right: 0;
  }

  &--right-column {
    position: relative;
  }

  @include respond-to('small') {
    &--no-padding-mobile {
      padding-left: 20px;
      padding-right: 20px;
    }

    &--top {
      margin-top: 72px;
    }
  }

  @include respond-to('between') {
    &--right-column {
      width: 60%;
      margin-left: 294px;
      margin-right: 10%;
    }

    //for practice detail
    &--page-nav-aligned {
      padding-left: #{20px + 25px};
    }
  }

  @include respond-to('container') {
    &--right-column {
      width: 60%;
      margin-left: 30%;
      margin-right: 10%;
    }
  }
}