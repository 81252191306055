.columns {

  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__group {
    margin-bottom: 30px;
  }

  .news-cards__card {
    margin-bottom: 0;
  }


  @include respond-to('small') {
    &__three-col {
      width: calc(33% - 30px);
      margin: 0 15px;
    }

    &__four-col {
      width: calc(25% - 30px);
      margin: 0 15px;
    }

    &__title {
      margin-top: 0;
    }

    &__container {
      margin-top: 0;
      flex-direction: row;
      margin-left: -15px;
      margin-right: -15px;
    }

    &__group {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}