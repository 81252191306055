.link-box {
  font-family: $font__sans-serif;
  font-size: $font__size--small;
  border: 1px solid $color__gray02;
  border-top: 3px solid $color__blue--dark;
  padding: 20px;
  margin-bottom: 30px;

  &__category {
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__title {
    font-family: $font__sans-serif--semibold;
    line-height: 18px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: $font__size--small;
  }

  &__link {
    display: block;
    line-height: 22px;
    margin-bottom: 10px;
  }
}