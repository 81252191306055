.article-list-item {

    display: block;
    border-bottom: 1px solid $color__gray02;
    padding: 25px 0;
    
    @include hover-tab;
  
    //actually more like title
    &__description {
      color: $color__text;
      font-size: $font__size--h5;
      line-height: $line-height__h5;

      font-family: $font__sans-serif;

      .article-list-item--no-link & {
        color: $color__gray03;
      }
    }

  
    &__meta {
      color: $color__gray05;

      font-family: $font__sans-serif--med;
      font-size: $font__size--tiny;
      line-height: 18px;
      text-transform: uppercase;
      letter-spacing: 1.47px;
    }

    &__divider {
        font-size: $font__size--tiny;
        line-height: $font__size--small;
        color: $color__gray05;
        padding: 0 5px;
      }

  }