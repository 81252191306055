.page-nav-mobile {
  position: absolute;
  z-index: 2;
  top: 70px;
  left: 20px;
  width: calc(100% - 40px);

  &__dropdown-list {
    @include select;
  }

  &--dark-bg {
    select {
      color: $color__white;
      border-color: $color__white;
      font-family: $font__sans-serif--med;
    }

    .page-nav-mobile__dropdown-list {
      &::after {
        color: $color__white;
      }
    }
  }

  @include respond-to('between') {
    display: none;
  }
}