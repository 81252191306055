.load-button {
  padding: 12px 24px 12px 48px;
  position: relative;

  &::before {
    @include icon;
    position: absolute;
    content: $icon__nav-arrow;  
    display: inline-block;
    transform: rotate(90deg);
    left: 24px;
    top: 14px;
  }
}

//used on 'view more' components with links , no down carat arrow
.view-more-button {
  padding: 12px 36px 12px 24px;
  position: relative;

  &::after {
    @include icon;
    position: absolute;
    top: 14px;
    right: 18px;
    // content: $icon__dbl-chevron; //TODO; real icon, the icomoon file is wack
    content: $icon__nav-arrow;
  }
}