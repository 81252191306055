.secondary-navigation {

    
    &__desktop {
        display: none;
    }

    &__mobile {
        padding-top: 10px;
    }

    &__item {
        margin-right: 20px;
    }

    &__link {
        text-decoration: none !important;
        font-family: $font__sans-serif--semibold;
        letter-spacing: 0.54px;
        color: $color__text;
      
        &:after {
            content: "";
            display: block;
            width: 0;
            height: 2px;
            background-color: $color__green;
            margin-top: -4px;
            transition: width 0.2s ease-in-out;
        }

        &--active {
            &:after {
              width: 100%;
            }
          }

        &:hover {
            &:after {
                width: 100%;
            }
        }
    }

    &__select {
        @include select;
    }

    @include respond-to('between') {
        &__mobile {
            display: none;
        }

        &__desktop {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}