//TODO: rename to case-study-tiles

.cross-links {
  font-family: $font__sans-serif;
  // margin-top: 30px;

  &__cards {
    padding-top: 10px;
  }

  &__list {
    border-top: 1px solid $color__gray02;
    padding-bottom: 30px; //match masonry gutter that creates bottom margin when it is cards
  }

  &__card {
    display: block;
    background-color: $color__gray01;
    margin-bottom: 30px;
    padding: 18px;
    box-sizing: border-box;
    line-height: 24px;

    @include hover-tab;
  }

  &__primary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__info {
    width: 55%;
    padding-right: 10px;
  }

  &__image {
    width: 45%;
    max-width: 140px;
  }

  &__type {
    font-size: $font__size--tiny;
    color: $color__gray03;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  &__title {
    font-family: $font__sans-serif--semibold;
  }

  &__summary {
    font-size: $font__size--small;
    color: $color__black;
    font-family: $font__sans-serif--med;
    margin-top: 12px;
  }

  @include respond-to('small') {
    &__cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
    }

    &__card {
      width: calc(50% - 20px);
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}