.home-hero {
  // margin-top: -60px;
  @extend .body-copy;

  &__pause {
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 2;
      opacity: 1;
      font-size: 16px;
      padding: 10px;
      color: $color__blue--dark;
      background: rgba($color__white, .9);
      border: 1px solod $color__blue--dark;
      display: none;
  }

  &__wrapper {
    padding-top: 30px;
    padding-bottom: 40px;

    min-height: 70vh; //for initial load flopping 

    // height: 825px;
    // height: 490px;
    // max-height: 100vh;
    // max-height: calc(100vh - 90px);
    position: relative;
  }

  &__top-padding {
    padding-top: 90px;
  }

  &__slide-inner {
    display: none;
  }

  &__title {
    font-family: $font__sans-serif--bold;
    color: $color__blue--dark;
  }

  &__slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    height: 100%;
    background-size: cover;
    transition: opacity 500ms ease-in-out;
    visibility: hidden;

    &--active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__slide-gradient {
    position: absolute;
    left:0;
    right: 0;
    top:0;
    bottom:0;

    background-color: $color__gray01; //hide background image in mobile
  }

  &__link {
    margin-top: 40px;
  }

  &__navigation {
    position: relative;
    width:100%;
    // position: absolute;
    // // bottom: 0;
    // top: 30px;
    // left: 0;
    // right: 0;
    overflow-x: hidden;
  }

  &__nav-inner {
    display: flex;
  //  min-width: 500vw;
    padding: 0 5px;
    transition: left 500ms ease-in-out;
  }

  &__tab {
    position: relative;
    margin: 0 6px 6px;
    width: calc(100vw - 52px);
    //min-height: calc(100vh - 180px);
    background-color: $color__white;
    cursor: pointer;
    overflow: hidden; //stop image blur from sneaking over

    display: flex;
    flex-direction: column;

    .apply-transitions & {
      transition: $link-transition, transform 0.3s ease-in-out;
    }



    &--inactive {
      //490 / 370 = .75
      transform: scaleY(0.9);
      // width: calc(90vw-52px);
      // height: 90%;
      // transform: translateY(5%);

      .home-hero__tab-image {
        filter: blur(2px);
      }
    }
  }

  &__tab-inner {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__tab-image {
      width: 100%;
      height: auto;
      .apply-transitions & {
        transition: $link-transition;
      }
  }

  &__tab-title {
    color: $color__blue--dark;
    font-size: $font__size--h4;
    line-height: $line-height__h4;
    margin-bottom: 16px;
    font-family: $font__sans-serif--semibold;
    text-align: left;
  }

  // &__tab-summary {

  // }

  &__tab-link {
    margin-bottom: 0;
  }

  &__indicator-container {
    width: calc(100% - 12px);
    height: 5px;
    position: absolute;
    left: 6px;
    bottom: 6px;
    display: none;
  }

  &__indicator {
    background-color: $color__gray02;
    width: 100%;
    height: 5px;
    position: absolute;
  }

  &__mobile-indicators {
    position: absolute;
    bottom: 5px;
    width: 100%;
    display: flex;
  }

  &__mobile-indicator {
    margin: 0 2px;
    flex-grow: 1;
    height: 7px;
    background-color: $color__gray-alt;

    .apply-transitions & {
      transition: $link-transition;
    }
    
    &--active {
      background-color: $color__blue--alt;
    }
  }

  &__indicator-progress {
    background-color: $color__blue--dark;
    width: 0;
    height: 5px;
    position: absolute;
    visibility: hidden;

    &--active {
      visibility: visible;
      // animation-duration set with inline style from Vue component
      animation-timing-function: linear;
      animation-name: indicator;
      width: 100%;
    }

    &--stopped {
      animation-duration: 0.2s !important; // override inline style
    }
  }

  @media(min-width: 400px){
    &__tab-title {
      font-size: $font__size--h3;
      line-height: $line-height__h3;
    }
  }

  @include respond-to('small') {

    &__tab-link {
      display: block;
      text-align: center;
    }
  }

  @include respond-to('between') {

    &__pause {
      display: block;
    }

    &__wrapper {
      height: 825px;
      min-height: 0;
      max-height: calc(100vh - 130px);
      padding: 0;
    }

    &__slide {
      
    }

    &__slide-gradient {
      background-color: transparent;
      background: linear-gradient(90deg, rgba(255,255,255, 0.90) 0%, rgba(255,255,255,.9) 40%,rgba(255,255,255,0) 100%);

    }

    &__slide-inner {
      display: block;
      max-width: 600px;
    }

    &__title {
      font-size: $font__size--h2;
      line-height: $line-height__h2;
    }

    &__summary {
      color: $color__text;
      font-size: 18px;
      line-height: 32px;
      max-width: 560px;
      margin-bottom: 0;
      // padding-right: 40px; //600 - 40px = 560 max width
    }

    &__navigation {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 10px;
      // top: auto;
      padding-top: 32px;
    }

    &__nav-inner {
      min-width: 0;
    }

    &__tab {
      width: calc( 20% - 12px);
      min-height: 0;
      // transition: $link-transition; //TODO
      position: relative;
  
      &:hover {
        margin-top: -16px;
      }
  
      &--active {
        margin-top: -32px;
  
        &:hover {
          margin-top: -32px;
        }
  
        .home-hero__tab-title {
          font-size: $font-size-base;
          font-family: $font__sans-serif--bold;
          color: $color__blue--dark;
        }
      }
    }

    &__tab-inner {
      padding: 20px 12px;
    }

    &__tab-image {
      display: none;
    }

    &__tab-title {
      font-family: $font__sans-serif--semibold;
      color: $color__gray035;
      font-size: $font__size--small;
      line-height: $font__size--h4;
      margin-bottom: 0;
    }

    &__tab-summary {
      display: none;
    }

    &__tab-link {
      display: none;
    }

    &__indicator-container {
      display: block;
    }

    &__mobile-indicators {
      display: none;
    }
  }

  @include respond-to('between') {
    // margin-top: -92px;

    &__tab {
      &--active {
        font-size: 19px;
      }
    }

    &__tab-title {
      font-size: $font-size-base;
      line-height: $line-height-base;
    }
  }

  @include respond-to('large') {
    &__title {
      font-size: 36px;
      line-height: 46px;
    }

    // &__summary {
      // font-size: $font__size--h3;
      // line-height: $line-height__h3;
    // }
  }
}

@keyframes indicator {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}