.body-copy {
  font-size: $font-size-base;
  font-family: $font__sans-serif;
  line-height: $line-height-base;
  color: $color__text;

  a {
    line-height: inherit;
  }
}

.rte {
  font-family: $font__sans-serif;
  a {
    text-decoration: underline;
  }
}

h1 {
  font-size: $font__size--h2;
  line-height: $line-height__h2;
  font-family: $font__sans-serif--med;
  margin-bottom: 20px;
}

h2 {
  font-size: $font__size--h3;
  line-height: $line-height__h3;
  font-family: $font__sans-serif--med;
  margin-bottom: 26px;
}

h3 {
  font-size: $font__size--h4;
  line-height: $line-height__h4;
  font-family: $font__sans-serif--med;
  margin-bottom: 12px;
}

h4 {
  font-size: $font__size--h5;
  line-height: $line-height__h5;
  font-family: $font__sans-serif;
  margin-bottom: 12px;
}

h5 {
  font-size: $font__size--small;
  line-height: $font__size--h4;
  font-family: $font__sans-serif;
  margin-bottom: 12px;
}

img,
video {
  max-width: 100%;
}

a {
  font-family: $font__sans-serif--semibold;
  color: $color__blue--dark;
  line-height: 32px; // I hate this default but its too late to go back now 
  
  &:hover {
    text-decoration: underline;
  }
}

p {
  @extend .body-copy;

  margin-top: 22px;

  &:first-of-type {
    margin-top: 0;
  }
}

sub {
  @extend .body-copy;
  font-size: $font__size--tiny;
  line-height: 18px;
}

.rte__pull-quote--with-quotes {
  &:before {
    content: '“ ';
    margin-left: -18px;
  }

  &:after {
    content: ' ”';
  }
}

.rte__call-out {
  display: inline;
  font-size: $font__size--small;
  line-height: 16px;
  text-transform: uppercase;
  margin-right: 6px;
}

blockquote, .rte__pull-quote {
  margin: 50px 0;
  font-family: $font__blockquote;
  font-style: italic;
  font-size: $font__size--h3;
  line-height: 42px;
  padding-left: 8px;

  span {
    background-color: $color__teal;
  }
}

ul,
ol {
  padding-left: 18px;
}

li {
  @extend .body-copy;

  padding-bottom: 18px;
}

ul li {
  list-style: disc;
}

ol li {
  list-style: decimal;
}

hr {
  margin: 30px 0;
  border: none;
  border-top: 1px solid $color__gray02;
}

.rte {
  h2 {
    margin: 24px 0;
    color: $color__black;
  }
  h2.jump-link {
    margin: -144px 0;
    padding: 144px 0;
  }

  h3 {
    margin: 18px 0;
    color: $color__black;
    font-weight: bold;
  }

  h4,
  h5 {
    margin: 12px 0;
    color: $color__black;
  }

  h5 {
    text-transform: uppercase;
    font-size: $font__size--small;
    line-height: 16px;
  }

  ul {
    margin-top: 20px;
	li ul {
		margin-top:10px;
	}
  }
  
  ol {
    margin-top: 20px;
  }
}

@include respond-to('small') {
  h1 {
    font-size: $font__size--h1;
    line-height: $line-height__h1;
    font-family: $font__sans-serif--med;
  }
  
  h2 {
    font-size: $font__size--h2;
    line-height: $line-height__h2;
    font-family: $font__sans-serif--med;
  }
  
  h3 {
    font-size: $font__size--h3;
    line-height: $line-height__h3;
    font-family: $font__sans-serif--med;
  }
  
  h4 {
    font-size: $font__size--h4;
    line-height: $line-height__h4;
    font-family: $font__sans-serif;
  }

  h5 {
    font-size: $font__size--h5;
    line-height: $line-height__h5;
    font-family: $font__sans-serif;
  }

  blockquote, .rte__pull-quote {
    margin: 50px 0;
    // font-size: $font__size--blockquote;
    // line-height: 68px;
  }
}