.search-landing-segments {
    display: flex;
    width: 100%;
    justify-content: space-between;

    padding-bottom: 20px;

    flex-wrap: wrap;

    &__button {
        margin-right: 15px;
        margin-bottom: 10px;

        &:last-child(){
            margin-right: 0;
        }

        &--active {
            font-family: $font__sans-serif--semibold;
        }
    }

}