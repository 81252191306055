//this contains the styling for the article tile wrapper / load more component and the card itself 
//would be nice to clean it up and separate it at some point ... 

.news-cards {
  font-family: $font__sans-serif;

  //for when the need a position relative element to grab onto
  &__toggle-spacer {
    margin-top: 40px;
    position: relative;
    height: 40px;
  }
  
  &__toggles {
    float: right;
    margin-top: -20px;
    display: flex;
  }

  &__card-toggle {
    height: 18px;
    width: 18px;
    position: relative;

    &--active {
      .news-cards__card-icon {
        background-color: $color__blue--dark;
      }
    }
  }

  &__card-icon {
    width: 7px;
    height: 7px;
    position: absolute;
    background-color: $color__gray03;

    &.topleft {top: 0; left: 0;}
    &.topright {top: 0; right: 0;}
    &.botleft {bottom: 0; left: 0;}
    &.botright {bottom: 0; right: 0;}
  }

  &__list-toggle {
    height: 18px;
    width: 18px;
    position: relative;
    margin-left: 18px;

    &--active {
      .news-cards__list-icon {
        background-color: $color__blue--dark;
      }
    }
  }

  &__list-icon {
    width: 100%;
    height: 2px;
    background-color: $color__gray03;
    display: block;
    margin-bottom: 6px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__list {
    margin-bottom: 30px;
    border-top: 1px solid $color__gray02;
  }

  &__cards {
    padding-top: 10px;
  }

  &__card {
    background-color: $color__gray01;
    margin-bottom: 30px;
    display: block;
    box-sizing: border-box;

    @include hover-tab;

    &:hover {
      .news-cards__reading-time {
        opacity: 0;
      }
    }
  }

  &__container {
    padding: 18px;
  }

  //some icons (mainly the original ones to the template site)
  // have a much tighter crop than the others
  &__icon {
    // width: 45px;
    height: auto;
    max-width: 45px;
    max-height: 45px;
    margin-bottom: 5px;
  }

  &__image {
    width: 100%;
  }

  &__headline {
    font-family: $font__sans-serif--semibold;
    font-size: $font__size--h5;
    line-height: $line-height__h5;
    color: $color__blue--dark;

    .news-cards__card--no-link & {
      color: $color__text;
    }

    & ~ div {
      margin-top: 15px;
    }
  }

  &__subhead {
    font-family: $font__sans-serif--semibold;
    font-size: $font__size--h6;
    line-height: $line-height__h6;
    color: $color__blue--dark;

    .news-cards__card--no-link & {
      color: $color__text;
    }
  }

  &__meta-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    bottom: 15px;
    left: 0;
    width: 100%;

    .news-cards__headline + & {
      margin-top: 30px;
    }
  }

  &__date {
    font-family: $font__sans-serif--semibold;
    color: $color__text;
    text-transform: uppercase;
    font-size: $font__size--small;
    letter-spacing: 1.7px;
  }

  &__reading-time {
    color: $color__gray04;
    font-size: $font__size--small;
    padding-left: 32px;
    position: relative;
    transition: $link-transition;

    &:before {
      @include icon;
      content: $icon__glasses;
      color: $color__gray04;
      display: block;
      position: absolute;
      left: 0;
      top: 11px;
      font-size: 11px;
    }
  }

  &__controls {
    text-align: center;
  }

  @include respond-to('small') {

    &__cards {
      break-inside: avoid;

      //now uses vue masonry
      // &--three-column {
      //   column-count: 3;
      //   column-gap: 30px;
      // }

      // &--two-column {
      //   column-count: 2;
      //   column-gap: 20px; //TODO: ??
      // }
    }

    &__toggles {
      position: absolute;
      margin-top: 0;
      top: 9px;
      right: 0;
    }

  }

}