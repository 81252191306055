@mixin hover-tab {
  overflow: hidden;
  position: relative;

  .hover-tab {
    position: absolute;
    right: -100px;
    bottom: 0;
    width: 0;
    height: 0;
    border-right: 78px solid $color__green;
    border-top: 79px solid transparent;
    transition: right 0.3s ease-in-out;

    @include icon;

    &:after {
      content: $icon__dbl-chevron;
      color: $color__white;
      display: block;
      position: absolute;
      top: -35px;
      left: 48px;
      font-size: 21px;
    }
  }

  &:hover {
    text-decoration: none;
    
    .hover-tab {
      right: 0;
    }
  }
}