.bio-carousel {
  font-family: $font__sans-serif;
  background-color: $color__teal;
  padding: 30px 16px;

  &__slide {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__left-panel {
    background-color: $color__gray01;
    border-top: 4px solid $color__green;
    
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
  }

  &__right-panel {
    background-color: $color__teal;

    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
  }

  &__panel-inner {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3D(-100%, 0, 0);
    min-width: 100%;
    transition: 0.5s;

    &--left {
      padding: 56px 20px 26px;
    }

    &--right {
      padding-top: 45px;
    }

    &--active {
      visibility: visible;
      position: static;
      transform: translate3D(0, 0, 0);
    }

    &--next-slide {
      transform: translate3D(100%, 0, 0);
    }
  }

  &__intro {
    position: absolute;
    top: 25px;
    left: 20px;
    font-family: $font-family-montserrat-semibold;
    letter-spacing: 1.03px;
    text-transform: uppercase;
  }

  &__bio-details {
    text-align: center;
    color: $color__text;
    display: block;
    box-sizing: border-box;
  }

  &__image {
    max-width: 166px;
    margin-bottom: 20px;
  }

  &__name {
    font-size: $font__size--h3;
    font-family: $font__sans-serif--med;
    margin-bottom: 12px;
  }

  &__title {
    font-family: $font__sans-serif--med;
    text-transform: uppercase;
    color: $color__gray03;

  }

  &__summary-title {
    margin-bottom: 15px;
    line-height: 36px;
  }

  &__summary {
    @extend .body-copy;

    color: $color__black;
    margin-bottom: 16px;
  }

  &__link {
    font-family: $font__sans-serif--semibold;
    display: inline-block;
    line-height: 26px;

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background-color: $color__green;
      margin-top: -4px;
      transition: width 0.2s ease-in-out;
    }

    &:hover {
      text-decoration: none;

      &:after {
        width: 100%;
      }
    }
  }

  &__controls {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 54px;
    right: 32px;
  }

  &__arrow {
    width: 14px;
    height: 22px;
    color: $color__green;
    font-size: 22px;

    &::before {
      @include icon;
      content: $icon__nav-arrow;
      font-weight: bold;
    }
  }

  &__left-arrow {
    transform: rotate(180deg);
  }

  &__count {
    padding: 3px 10px;
  }

  @include respond-to('small') {
    background-color: transparent;
    padding: 0;

    &__slide {
      flex-direction: row;
    }

    &__left-panel {
      width: 30%;
      border-top: 4px solid $color__gray02;
    }

    &__right-panel {
      width: 70%;
      border-top: 4px solid $color__green;
    }

    &__panel-inner {

      &--left {
        padding: 85px 30px 35px;
      }

      &--right {
        padding: 80px;
      }
    }

    &__intro {
      top: 35px;
      left: 30px;
    }

    &__bio-details {
      text-align: left;
    }

    &__image {
      max-width: 204px;
    }

    &__controls {
      right: 40px;
      bottom: 40px;
      top: initial;
    }
  }

}