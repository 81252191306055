$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon__download: "\e900";
$icon__mail: "\e901";
$icon__search: "\e902";
//$icon__twitter: "\e903";
$icon__twitter: "\e91e";
$icon__linkedin: "\e904";
$icon__pdf: "\e905";
$icon__share: "\e906";
//$icon__footer--twitter: "\e907";
$icon__footer--twitter: "\e91e";
$icon__footer--linkedin: "\e908";
$icon__footer--facebook: "\e909";
$icon__footer--google: "\e90a";
$icon__footer--youtube-1: "\e90b";
$icon__footer--youtube-2: "\ea9d";
$icon__footer--instagram: "\ea92";
$icon__close: "\e90c";
$icon__hamburger: "\e90d";
$icon__vcard: "\e90e";
$icon__google: "\e90f";
$icon__facebook: "\e910";
$icon__rss: "\e911";
$icon__external-link1: "\e913";
$icon__dbl_chevron: "\e916";
$icon__world1: "\e91a";
$icon__footer--rss: "\e91b";
$icon__glasses: "\e91c";
$icon__arrow_forward_ios1: "\e91d";
$icon__play: "\e918";
$icon__pause: "\e919";
$icon__world: "\e914";
$icon__globe: "\e914";
$icon__internet: "\e914";
$icon__network: "\e914";
$icon__nav-arrow: "\e917";
$icon__rss-square: "\e915";
$icon__external-link: "\e912";
