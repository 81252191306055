.section {
  margin-bottom: $margin-bottom-mobile;
  position: relative;

  &--small-overlap {
    margin-top: -70px;
    z-index: 2;
  }

  &--overlap {
    margin-top: -30px;
    z-index: 2;
  }

  &--tall-overlap {
    margin-top: -300px;
    z-index: 2;
  }

  &--full-width-background-overlap {
    margin-top: -50px;
    z-index: 2;
  }

  &--mobile-overlap {
    margin-top: -300px;
    z-index: 2;
  }

  &--reduced-section {
    margin-bottom: 1.5rem !important;
  }

  @include respond-to('small') {
    margin-bottom: 4.5rem;

    &--small-overlap {
      margin-top: -80px;
    }

    &--overlap,
    &--mobile-overlap {
      margin-top: -200px;
    }
  }

  @include respond-to('between') {
    &--tall-overlap {
      margin-top: -300px;
    }
  }


  @include respond-to('container') {
    &--full-width-background-overlap {
      margin-top: -100px;
    }
  }
}

// .page {
//   &__section-heading {
//     font-size: $font__size--h5;
//   }
// }