.featured-news {
  // background: url('#{$img-path}/newsBG.png') no-repeat center center $color__blue--dark;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $color__blue--dark; //fallback for no inline background from razor
  background-size: cover;
  color: $color__white;
  padding: 30px 0;
  @extend .body-copy;

  &__featured-article {
    margin-bottom: 30px;
  }

  &__featured-summary {
    margin-bottom: 35px;
  }

  &__featured-button {
    color: $color__white;
    border-color: $color__white;
  }

  &__articles {
    border-top: 1px solid $color__white;
  }

  &__article {
    display: block;
    border-bottom: 1px solid $color__white;
    padding: 25px 15px;
    
    @include hover-tab;
  }

  &__description {
    color: $color__white;
    line-height: $line-height-base;
    margin-bottom: 8px;
  }

  &__meta {
    color: $color__white;
    font-family: $font__sans-serif--med;
    font-size: $font__size--tiny;
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: 1.47px;
  }

  @include respond-to('small') {
    padding: 80px 0;

    &__wrapper {
      display: flex;
      flex-direction: row;
    }

    &__featured-article {
      width: 40%;
      margin-bottom: 0;
    }

    &__featured-title {
      font-size: 36px;
      line-height: 44px;
      margin-top: 12px;
    }

    &__articles {
      margin-left: 10%;
      width: 50%;
    }
  }
}