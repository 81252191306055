.expanding-list {
  &__articles {
    border-top: 1px solid $color__gray02;
  }

  //just a wrapper for article-list-item and tile
  //moved the list item styling to their own blocks


  &__controls {
    position: relative;

    @include clearfix;
  }

  //list component doesn't have the masonry gutter padding on the bottom
  &__view-more-padding {
    padding-top: 30px;
  }

  &__view-more-controls {
    position: relative;
    text-align: center;
    padding: 10px 0; //something weird going on with inline button being too high

  }

  &__count {
    font-family: $font__sans-serif;
    line-height: 26px;
    margin-top: 20px;
    float: right;
  }
}