.promo {
  border-top: 5px solid $color__blue--dark;
  border-bottom: 1px solid $color__gray03;
  padding: 15px 5px 20px;
  margin: 20px 0;
  font-size: $font__size--h3;
  line-height: $line-height__h3;
  font-family: $font__sans-serif--med;
  width: 100%;

  @media print {
    display: none;
  }

  a {
    @include button;

    display: inline-block;
    font-size: $font__size--small;
    line-height: 22px;
    padding: 5px 50px;
    margin-top: 15px;
  }

  @include respond-to('small') {
    float: left;
    margin: 30px 50px 30px 0;
    width: 280px;
  }

  @include respond-to('between') {
    margin-left: -85px;
  }

  &-module {
    text-align: center;
    margin: 0;

    span {
      display: block;
    }
    @include respond-to('small') {
      float: none;
      width: 100%;
    }

    @include respond-to('between') {
      margin-left: 0;
    }
  }
}

.blog-promo {
  display: block;
  background-color: $color__gray01;
  text-align: center;
  padding: 20px;
  color: $color__blue--dark;

  &__icon {
    border: 2px solid $color__green;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    margin: 0 auto;

    @include icon;

    &:after {
      background: url('#{$img-path}/outgoing.svg') no-repeat 50% $color__green;
      border-radius: 50%;
      content: "";
      display: block;
      height: 1.95rem;
      position: absolute;
      transform: translate(-1%, -1%);
      width: 1.95rem;
    }
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.promo-violator {
  @include respond-to('small') {
    display: flex;
    margin: 40px -10px;

    &__tile {
      width: 50%;
      margin-left: 10px;
      margin-right: 10px;
      display: flex;
    }
  }
}