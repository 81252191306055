.testimonials {
  font-family: $font__sans-serif;
  margin-top: 25px;

  &__card {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    box-sizing: border-box;
    line-height: 24px;
  }

  &__data {
    width: 35%;
    padding-right: 15px;
  }

  &__content {
    width: 65%;
  }

  &__image {
    margin-bottom: 10px;
  }

  &__name,
  &__city {
    display: block;
    font-size: $font__size--small;
    line-height: 1.5;
    margin-bottom: 3px;
  }

  &__title {
    font-size: $font__size--tiny;
    text-transform: uppercase;
    font-family: $font__sans-serif--semibold;
    margin-bottom: 3px;
  }

  &__quote, &__quote p {
    font-size: $font__size--small;
    color: $color__black;
    font-family: $font__sans-serif--med;
  }

  &__quote p {
    display: inline;
  }

  @include respond-to('small') {
    &__cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
    }

    &__card {
      width: calc(50% - 20px);
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}