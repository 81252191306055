.accordion {
  margin-top: -0.3 * $margin-bottom-mobile;

  .page__section-heading + .section__content & {
    margin-top: auto;
  }

  &__section {
    border-top: 1px solid $color__gray02;
  }

  &__before-content + &__section {
    margin-top: 30px;
  }

  &__node {
    border-bottom: 1px solid $color__gray02;
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    line-height: 26px;
    padding: 20px 30px 20px 0;
    text-align: left;
    position: relative;

    &--open {
      .accordion__label {
        color: $color__text;

        &:after {
          display: none;
        }
      }
    }

    .expand-toggle__icon {
      right: 0;
    }

    &:hover {
      .accordion__label {
        &:after {
          width: 100%;
        }
      }
    }

    img {
      display: none;
    }

    @include respond-to('small') {
     img {
       display:block;
       width: 25%;
       padding-right: 2rem;
       max-width: fit-content;
     }
    }
  }

  &__label {
    font-family: $font__sans-serif--semibold;
    color: $color__blue--dark;
    position: relative;
    display: inline-block;
    
    &:after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background-color: $color__green;
      margin-top: -4px;
      transition: width 0.2s ease-in-out;
    }
  }

  &__inner {
    padding-bottom: 30px;
  }

  &__description {
    font-family: $font__sans-serif;
    line-height: 24px;
    padding-right: 30px;
  }

  @include respond-to('small') {
    margin-top: -0.5 * $margin-bottom;
  }
}