.typeahead {
  position: relative;
  width: 100%;

  &--with-margin {
    margin-top: -30px;
  }

  &__active{
    .typeahead__link{
        background-color: $color__gray01;
        text-decoration: none;
        width: inherit;
    }
  }
  &__results {
    position: absolute;
    background-color: $color__white;
    box-shadow: 0 0 20px 3px rgba(0,0,0,0.05);
    width: 100%;
    padding: 0;
    z-index: 100;
    overflow: hidden;
  }

  &__result {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  &__link {
    display: block;
    padding: 5px 15px;
    width: 100%;
    color: $color__text;
    font-family: $font__sans-serif--med;

    &--view-all {
      text-align: left;
      font-style: italic;
      line-height: 32px;
    }

    &:hover {
      text-decoration: none;
    }
  }
}
