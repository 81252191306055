.modal-alert {
  &__mask {
    display: table;
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    transition: opacity .3s ease;
  }

  &__wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &__container {
    width: 75%;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: $color__white;
    box-shadow: 0 2px 8px rgba(0,0,0,.33);
    transition: all .3s ease;
  }

  &__header {
    font-family: $font__sans-serif--semibold;
    font-size: $font__size--h3;
    margin-bottom: 18px;
  }

  &__body {
    @extend .body-copy;
  }

  &__buttons {
    margin-top: 24px;
    text-align: right;
  }

  &__button {
    @extend .button;
    margin-left: 10px;
  }

  &__decline{
    background-color: $color__green--dark;
    color: $color__white;
    border-color: $color__green--dark;
  }

  @include respond-to('medium') {
    &__container {
      width: 40%;
    }
  }
}