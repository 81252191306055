.article-columns {

  &__right {
    margin-bottom: 50px;
  }

  @include respond-to('small') {
    display: flex;
    flex-direction: row;

    &__left {
      width: 70%;
      padding-left: 10%;
      position: relative;

      &--full {
          padding-left: 0;
      }
    }
  
    &__right {
      width: 30%;
      padding-left: 8%;
      margin-bottom: 0;
      .language-selector__desktop{
        margin-left:0;
      }
    }
  }
}