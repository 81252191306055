.category-list {
  font-family: $font__sans-serif;
  line-height: 24px;

  &__category {
    border-top: 1px solid $color__gray02;
    padding: 22px 0;
  }

  &__title {
    font-size: $font__size--h5;
    font-family: $font__sans-serif--semibold;
    text-transform: uppercase;
    margin-bottom: 18px;
    color: $color__blue--light;
    letter-spacing: 1.7px;
  }

  &__content {
    li {
      padding-bottom: .6em;

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }

  &__additional {
    padding-top: 15px;
  }
}