.sidebar-promo {
  border-top: 5px solid $color__blue--dark;
  padding: 15px 0 20px;
  width: 100%;
  margin-bottom: 30px;

  &__text {
    font-size: $font__size--h3;
    line-height: $line-height__h3;
    font-family: $font__sans-serif--med;
  }

  &__link {
    display: block;
    // padding: 5px 10px;
    text-align: center;
    margin: 15px 0 0;
  }
}