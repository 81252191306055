.social-icons {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  &__icon {
    color: $color__white;
    background-color: $color__blue--dark;
    border-radius: 3px;
    margin-left: 6px;
    height: 26px;
    width: 26px;
    padding: 2px;
    text-decoration: none;
    transition: $link-transition;
    display: block;
    position: relative;
    text-align: center;

    &--linkedin {
      &::before {
        @include icon;
        content: $icon__linkedin;
      }
    }

    &--twitter {
      &::before {
        @include icon;
        content: $icon__twitter;
      }
    }

    &--facebook {
      &::before {
        @include icon;
        content: $icon__facebook;
      }
    }

    &--email {
      &::before {
        @include icon;
        content: $icon__mail;
      }
    }

    &--pdf {
      &::before {
        content: "PDF";
        font-family: $font-family-roboto-bold;
        font-size: 11px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    &::before {
      line-height: 25px;
    }

    &:hover {
      background-color: $color__green;
      text-decoration: none;
      font-family: $font-family-roboto-bold;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }

  @include respond-to('small') {
    position: absolute;
    left: 0;
    flex-direction: column;

    &__icon {
      margin-top: 6px;
      margin-left: 0;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__block & {
      position: static;
      left: auto;
      flex-direction: row;
    }

    &__block &__icon {
      margin-top: auto;
      margin-left: 6px;
    }
  }
}