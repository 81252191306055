.team-feature {
  &__industry-card {
    background-color: $color__gray01;
    padding: 20px 30px;
  }

  &__header {
    font-size: $font__size--small;
    font-family: $font__sans-serif--med;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  @include respond-to('small') {
    &__links {
      column-count: 3;
      column-gap: 30px;
      column-fill: balance;
      break-inside: avoid;
    }

    &__link {
      display: block;
    }
  }
}