.expand-toggle {

  &__toggle {
    position: relative;
    display: block;
    padding-left: 36px;
    font-family: $font__sans-serif--semibold;
    color: $color__text;
    line-height: 26px;
    margin-top: 20px;
    cursor: pointer;

    &--loading {
      cursor: wait;
      color: $color__gray03;
    }

    .expand-toggle__icon {
      left: 0;
      top: 0;
    }

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background-color: $color__green;
      margin-top: -4px;
      transition: width 0.2s ease-in-out;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }

  &__icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font__sans-serif;
    font-size: 24px;
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    line-height: 1rem;
    color: $color__blue--dark;
    border: 1px solid $color__blue--dark;
    border-radius: 50%;
    transition: $zoom-transition;

    &--open {
      color: $color__gray03;
      border: 1px solid $color__gray03;
      border-radius: 50%;
      transform: rotate(225deg);
    }

    &--close-icon {
      transform: rotate(225deg);
      color: #9B9B9B;
      border: 0.0625rem solid #9B9B9B;
    }
  }
}