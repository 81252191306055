.featured-video-embed-or-image {

    &__image {
        width: 100%;
        height: auto;
    }

    &__video {
        width: 100%;
        height: auto;
    }
}
