.css-columns {

  &__item{
    margin-bottom: 30px;
    break-inside: avoid;
  }

  @include respond-to('small') {

    &--three {
      column-count: 3;
      column-gap: 30px;
    }
    

    &__title {
      margin-top: 0;
    }

  }
}