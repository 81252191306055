.industry-hero {
  font-family: $font__sans-serif;
  @extend .body-copy;

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $color__white;
    padding: 15px;
    box-shadow: 1px 1px 24px 3px rgba(0,0,0,0.06);

    min-height: 300px; //match with page-hero--tall for headers with zero content
  }

  //for pdf, not jumplinks
  &__social-links {

  }

  &__title {
    font-family: $font__sans-serif--bold;
  }

  &__links {
    margin: 20px 0;
  }

  &__links-title {
    font-size: $font__size--small;
    font-family: $font__sans-serif--semibold;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  &__link {
    display: block;
    cursor: pointer;
    font-size: $font__size--h5;
    margin-bottom: 12px;
  }

  &__news-card {
    background-color: $color__gray01;
    margin-bottom: 30px;
    display: block;
    box-sizing: border-box;

    @include hover-tab;
  }

  &__news-card-image {
    background-repeat: no-repeat;
    background-position-y: top;
    background-position-x: center;
    background-size: cover;

    height: 0;
    padding-top: 72%;
  }

  &__news-card-container {
    padding: 18px;
  }

  &__news-card-category {
    font-family: $font__sans-serif--semibold;
    font-size: $font__size--small;
    text-transform: uppercase;
    letter-spacing: 1.7px;
    color: $color__text; 
  }

  &__news-card-headline {
    font-family: $font__sans-serif--semibold;
    font-size: $font__size--h5;
    line-height: $line-height__h5;
    color: $color__blue--dark;
    margin-bottom: 18px;
  }

  &__news-card-summary {
    @extend .body-copy;
  }

  @include respond-to('small') {
    &__card {
      // min-height: 270px;
      padding: 40px 80px;
    }

    &__social-links {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    &__links {
      margin: 30px 0;
    }

    &__row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -10px;
      margin-right: -10px;
    }

    &__news-card {
      width: calc(33.3334% - 20px);
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}