.article-hero {
  font-family: $font__sans-serif;
  font-size: $font__size--small;
  line-height: 16px;
  padding: 50px 0;
  // min-height: 400px; //maybe? 

  &__date {
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  &__title-section {
    margin-bottom: 60px;
  }

  &__source {
    margin-bottom: 6px;
  }

  &__emphasized-source {
    margin-top: 25px;
    font-style: italic;
    font-size: 16px;
    line-height: 20px;
  }

  &__authors {
    margin-bottom: 6px;
  }

  // Only on News Detail
  &__news-types {
    font-family: $font__sans-serif--semibold;
    line-height: 18px;
    letter-spacing: 1.7px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__abstract {
    font-size: $font-size-base;
    line-height: $line-height-base;

    a {
      line-height: inherit;
    }
  }

  &--no-image {
    // background-color: $color__teal; //done inline per-entity type
    margin-bottom: 50px;

    .article-hero {
      &__info-col {
        width: 100%;
        padding: 0;
      }

      &__image-col {
        display: none;
      }
    }
  }

  @include respond-to('small') {
    &__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &--case-study {
        padding-left: 10%;
        align-items: flex-start;
      }
    }

    &__info-col {
      padding-right: 60px;
      width: 60%;
    }
  
    &__image-col {
      width: 40%;
    }

    &__title {
      font-size: 40px;
      line-height: 46px;
    }

    &--no-image {
      .article-hero {
        &__title {
          font-size: $font__size--h1;
          line-height: 1.125;
        }
      }
    }
  }
}