.event-block {
  padding: 25px 0 20px;
  border-top: 4px solid $color__blue--dark;
  border-bottom: 1px solid $color__gray02;
  font-family: $font__sans-serif;

  &__time-block {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    font-size: $font__size--small;
  }

  &__date {
    font-family: $font__sans-serif--semibold;
  }

  &__divider {
    padding: 0 6px;
  }

  &__location,
  &__address {
    line-height: 1.5;
  }

  &__register {
    margin-top: 18px;
  }

  @include respond-to('medium') {
    display: flex;
    flex-direction: row;

    &__data {
      width: 80%;
    }

    &__register {
      margin-top: 0;
    }
  }
}