.hero-nav-padding {
  margin-top: 30px;

  @include respond-to('between') {
    margin-top: 0;
  }
}

.simple-hero {
  font-family: $font__sans-serif;
  font-size: $font__size--small;
  line-height: 16px;
  padding: 50px 0;

  &__image-col {
    margin-top: 20px;
  }

  &--no-image {
    background-color: $color__teal;
    margin-bottom: 50px;

    .simple-hero {
      &__info-col {
        width: 100%;
        padding: 0;
      }

      &__image-col {
        display: none;
      }
    }
  }

  @include respond-to('small') {
    &__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__info-col {
      padding-right: 60px;
      width: 60%;
    }
  
    &__image-col {
      width: 40%;
      margin-top: 0;
    }

    &__title {
      font-size: 40px;
      line-height: 46px;
    }

    &--no-image {
      .article-hero {
        &__title {
          font-size: $font__size--h1;
          line-height: 1.125;
        }
      }
    }
  }
}